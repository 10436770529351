import React from "react";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { MainAdminURL } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext";
import { UserDataContext } from "../../../context/AuthContext";

// core components

import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import Step1 from "./AddModelWizardSteps/Step1.js";
//import Step2 from "./AddModelWizardSteps/Step2.js";
import Step2 from "./AddModelWizardSteps/Step2.js";
import Step3 from "./AddModelWizardSteps/Step3.js";

import Step4 from "./AddModelWizardSteps/Step4.js";
import Step5 from "./AddModelWizardSteps/Step5.js";
import Step6 from "./AddModelWizardSteps/Step6.js";
import Step7 from "./AddModelWizardSteps/Step7.js";

export default function AddModel() {
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  const { userData } = React.useContext(UserDataContext);
  //const classes = useStyles();

  const [nextButtonStatus, setNextButtonStatus] = React.useState(false);
  // Initial data load
  const [brandsData, setBrandsData] = React.useState();
  const [categoryData, setCategoryData] = React.useState();
  // Session Started and Initialy model is created
  const [isModelCreated, setIsModelCreated] = React.useState(false);
  const [newmodelID, setNewModelID] = React.useState(null);
  const [isSKUCreated, setIsSKUCreated] = React.useState(false);
  const [newSKUID, setNewSKUID] = React.useState(null);
  const [newSKUSlug, setNewSKUSlug] = React.useState(null);
  // Data from Step 1
  const [modelName, setModelName] = React.useState();
  const [modelBrand, setModelBrand] = React.useState();
  const [modelCategory, setModelCategory] = React.useState();
  const [skuName, setSKUName] = React.useState();
  const [isMaterialChangeable, setIsMaterialChangeable] = React.useState();
  // Data from Step 2
  // Data from Step 3
  const [glbFile, setGlbFile] = React.useState();
  const [usdzFile, setUsdzFile] = React.useState();
  const [glbPreviewUrl, setGlbPreviewUrl] = React.useState();
  const [skuMaterials, setSkuMaterials] = React.useState();
  // Data from Step 4
  // Data from Step 5
  const [cameraOrbitSettings, setCameraOrbitSettings] = React.useState();
  const [minCameraOrbitSettings, setMinCameraOrbitSettings] = React.useState();
  const [maxCameraOrbitSettings, setMaxCameraOrbitSettings] = React.useState();
  const [exposureSettings, setExposureSettings] = React.useState();
  const [shadowIntensitySettings, setShadowIntensitySettings] = React.useState();
  const [shadowSoftnessSettings, setShadowSoftnessSettings] = React.useState();
  // Data from Step 4
  const [posterPreviewURL, setPosterPreviewURL] = React.useState();
  // Data from Step 5
  const [modelSizesID, setModelSizesID] = React.useState();
  const [sizeX, setSizeX] = React.useState();
  const [sizeY, setSizeY] = React.useState();
  const [sizeZ, setSizeZ] = React.useState();
  const [modelSizes, setModelSizes] = React.useState();
  const [modelCenter, setModelCenter] = React.useState();
  const [displaySizes, setDisplaySizes] = React.useState(false);
  // Data from step 6
  const [annotations, setAnnotations] = React.useState();
  const [displayAnnotations, setDisplayAnnotations] = React.useState(false);

  // General Error Message
  const [generalErrorMessage, setGeneralErrorMessage] = React.useState("");
  const [showGeneralErrorMessage, setShowGeneralErrorMessage] = React.useState(false);

  // Get Existing Brands
  React.useEffect(() => {
    //console.log(userToken);
    axios
      .get(`${MainAdminURL}/brands/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setBrandsData(response.data);
        },
        (error) => {
          setGeneralErrorMessage("trying to retrieve the Brand Data");
          setShowGeneralErrorMessage(true);
          //console.log("Error", error.message);
        }
      );
    axios
      .get(`${MainAdminURL}/categories/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setCategoryData(response.data);
        },
        (error) => {
          setGeneralErrorMessage("trying to retrieve the Categories Data");
          setShowGeneralErrorMessage(true);
          //console.log("Error", error.message);
        }
      );
  }, []);

  const handleAddModel = () => {
    // Events URL
    const EventsURL = `${MainAdminURL}/events/`;
    // Events Form Data
    let EventFormData = new FormData();
    EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
    EventFormData.append("user_email", `${userData.email}`);
    EventFormData.append("event", "ADD MODEL FINISH");
    EventFormData.append("target", `MODEL: ${modelName}`);
    // // Events Header
    const headersConfig = {
      headers: {
        Authorization: "Token " + userToken,
      },
    };
    // Constructing the data for the post request

    axios.post(EventsURL, EventFormData, headersConfig).then(
      (response) => {
        console.log(response.data);
      },
      (error) => {
        console.log("Error", error.response.data);
      }
    );
    history.push("/admin/activemodels");
  };
  const handleNextButtonClick = (e) => {
    setNextButtonStatus(true);
    // Step 1 - Data from Step 1
    if (e.name) {
      if (e.name.modelName && e.name.modelBrand && e.name.modelCategory && e.name.skuName) {
        setModelName(e.name.modelName);
        setModelBrand(e.name.modelBrand);
        setModelCategory(e.name.modelCategory);
        setSKUName(e.name.skuName);
        setIsMaterialChangeable(e.name.isMaterialChangeable);
        if (!isModelCreated) {
          // Constructing the data for the post request
          const ModelURL = `${MainAdminURL}/models/`;
          const SKUURL = `${MainAdminURL}/skus/`;
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          // Model Data
          let ModelformData = new FormData();
          ModelformData.append("name", e.name.modelName);
          ModelformData.append("brand", e.name.modelBrand);
          ModelformData.append("category", e.name.modelCategory);
          ModelformData.append("is_active", true);
          // SKU Data

          axios.post(ModelURL, ModelformData, headersConfig).then(
            (response) => {
              //console.log(response.data);
              setIsModelCreated(true);
              setNewModelID(response.data.id);
              //setNextButtonStatus(false);
              // If the model is Successfully created creat an SKU as wel!
              if (response.data) {
                let SKUformData = new FormData();
                SKUformData.append("name", e.name.skuName);
                SKUformData.append("model", response.data.id);
                SKUformData.append("is_material_changable", e.name.isMaterialChangeable);
                SKUformData.append("is_active", true);

                // Constructing the data for the post request

                axios.post(SKUURL, SKUformData, headersConfig).then(
                  (response) => {
                    //console.log(response.data);
                    setIsSKUCreated(true);
                    setNewSKUID(response.data.id);
                    setNewSKUSlug(response.data.slug);
                    setNextButtonStatus(false);
                  },
                  (error) => {
                    //console.log("Error", error.message);
                    setGeneralErrorMessage("trying to create the initial SKU for the model");
                    setShowGeneralErrorMessage(true);
                  }
                );
              }
            },
            (error) => {
              setGeneralErrorMessage("trying to create the new model");
              setShowGeneralErrorMessage(true);
            }
          );
        } else {
          const ModelURL = `${MainAdminURL}/models/${newmodelID}/`;
          const SKUURL = `${MainAdminURL}/skus/${newSKUSlug}/`;
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          let ModelformData = new FormData();
          ModelformData.append("name", e.name.modelName);
          ModelformData.append("brand", e.name.modelBrand);
          ModelformData.append("category", e.name.modelCategory);
          ModelformData.append("is_active", true);
          let SKUformData = new FormData();
          SKUformData.append("name", e.name.skuName);
          SKUformData.append("model", newmodelID);
          SKUformData.append("is_material_changable", e.name.isMaterialChangeable);
          SKUformData.append("is_active", true);
          axios.patch(ModelURL, ModelformData, headersConfig).then(
            (response) => {
              //console.log("Model Patched" + response.data);
              setIsModelCreated(true);
              setNewModelID(response.data.id);
              //setNextButtonStatus(false);
            },
            (error) => {
              setGeneralErrorMessage("trying to update the new model");
              setShowGeneralErrorMessage(true);
              //console.log("Error", error.message);
            }
          );
          axios.patch(SKUURL, SKUformData, headersConfig).then(
            (response) => {
              //console.log("SKU Patched" + response.data);
              setIsSKUCreated(true);
              setNewSKUID(response.data.id);
              setNewSKUSlug(response.data.slug);
              setNextButtonStatus(false);
            },
            (error) => {
              setGeneralErrorMessage("trying to update the new SKU");
              setShowGeneralErrorMessage(true);
              //console.log("Error", error.message);
            }
          );
        }
      }
    }

    // Data from Step 3
    if (e.assets) {
      setGlbFile(e.assets.glbFile);
      setUsdzFile(e.assets.usdzFile);
      setGlbPreviewUrl(e.assets.previewURL);
      setSkuMaterials(e.assets.skuMaterils);
    }
    // Data from Step 4
    if (e.scene) {
      setCameraOrbitSettings(e.scene.cameraOrbitSettings.settings);
      setMinCameraOrbitSettings(e.scene.minCameraOrbitSettings.settings);
      setMaxCameraOrbitSettings(e.scene.maxCameraOrbitSettings.settings);
      setExposureSettings(e.scene.exposureSettings.exposure);
      setShadowIntensitySettings(e.scene.shadowSettings.shadow_intensity);
      setShadowSoftnessSettings(e.scene.shadowSettings.shadow_softness);
    }

    // Data from Step 5 Dimensions
    if (e.dimensions) {
      console.log(e.dimensions);
      setModelCenter(e.dimensions.modelCenter);
      setModelSizes(e.dimensions.modelDimentions);
      // Model Dimensions Data
      let ModelSizesFormData = new FormData();
      ModelSizesFormData.append("model", newmodelID);
      ModelSizesFormData.append("length", e.dimensions.sizeX);
      ModelSizesFormData.append("height", e.dimensions.sizeZ);
      ModelSizesFormData.append("width", e.dimensions.sizeY);
      ModelSizesFormData.append("is_visible", e.dimensions.displaySizes);
      if (!modelSizesID && newmodelID) {
        // Constructing the data for the post request
        const ModelSizesURL = `${MainAdminURL}/modelsizes/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + userToken,
          },
        };

        axios.post(ModelSizesURL, ModelSizesFormData, headersConfig).then(
          (response) => {
            console.log(response.data);
            setModelSizesID(response.data.id);
            setSizeX(response.data.length);
            setSizeY(response.data.width);
            setSizeZ(response.data.height);
            setDisplaySizes(response.data.is_visible);
          },
          (error) => {
            setGeneralErrorMessage("trying to create the model Dimensions");
            setShowGeneralErrorMessage(true);
          }
        );
      } else {
        // Constructing the data for the post request
        const ModelSizesURL = `${MainAdminURL}/modelsizes/${modelSizesID}/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + userToken,
          },
        };
        // Model Dimensions Data
        axios.patch(ModelSizesURL, ModelSizesFormData, headersConfig).then(
          (response) => {
            console.log(response.data);
            setModelSizesID(response.data.id);
            setSizeX(response.data.length);
            setSizeY(response.data.width);
            setSizeZ(response.data.height);
            setDisplaySizes(response.data.is_visible);
          },
          (error) => {
            setGeneralErrorMessage("trying to update the model Dimensions");
            setShowGeneralErrorMessage(true);
          }
        );
      }
      setNextButtonStatus(false);
    }
    // Data from Annotation Step 6
    if (e.annotations) {
      setAnnotations(e.annotations.annotations);
      setDisplayAnnotations(e.annotations.displayAnnotations);
      console.log(e.annotations.annotations);
    }

    // Data from Poster Step
    if (e.poster) {
      setPosterPreviewURL(e.poster.generatedPosterURL);
    } else {
      return;
    }
  };
  const setNextButtonStatusProps = (value) => {
    if (value !== nextButtonStatus) {
      //setNextButtonStatus(value);
      //console.log(value);
    }
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{
                  position: "relative",
                  float: "left",
                  margin: "10px",
                }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Go Back
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      {showGeneralErrorMessage ? (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <GridItem style={{ padding: "20px", margin: "20px" }} xs={12} sm={12} md={12}>
                <span style={{ color: "red" }}>
                  Something went wrong while {generalErrorMessage}!
                </span>
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          {brandsData && categoryData ? (
            <Wizard
              inputData={{
                modelBrand: modelBrand,
                setGeneralErrorMessage: setGeneralErrorMessage,
                setShowGeneralErrorMessage: setShowGeneralErrorMessage,
                nextButtonStatus: nextButtonStatus,
                setNextButtonStatusProps: setNextButtonStatusProps,
                brandsData: brandsData,
                categoryData: categoryData,
                newModelID: newmodelID,
                newSKUID: newSKUID,
                newSKUSlug: newSKUSlug,
                isMaterialChangeable: isMaterialChangeable,
                skuMaterials: skuMaterials,
                glbPreviewUrl: glbPreviewUrl,
                cameraOrbitSettings: cameraOrbitSettings,
                minCameraOrbitSettings: minCameraOrbitSettings,
                maxCameraOrbitSettings: maxCameraOrbitSettings,
                exposureSettings: exposureSettings,
                shadowIntensitySettings: shadowIntensitySettings,
                shadowSoftnessSettings: shadowSoftnessSettings,
                posterPreviewURL: posterPreviewURL,
                displaySizes: displaySizes,
                modelCenter: modelCenter,
                modelSizes: modelSizes,
                sizeX: sizeX,
                sizeY: sizeY,
                sizeZ: sizeZ,
                annotations: annotations,
                displayAnnotations: displayAnnotations,
              }}
              validate
              steps={[
                {
                  stepName: "Model & Main SKU",
                  stepComponent: Step1,
                  stepId: "name",
                },
                {
                  stepName: "Main SKU assests",
                  stepComponent: Step2,
                  stepId: "assets",
                },
                {
                  stepName: "Scene",
                  stepComponent: Step3,
                  stepId: "scene",
                },
                {
                  stepName: "Poster",
                  stepComponent: Step4,
                  stepId: "poster",
                },
                {
                  stepName: "Dimensions",
                  stepComponent: Step5,
                  stepId: "dimensions",
                },
                {
                  stepName: "Annotations",
                  stepComponent: Step6,
                  stepId: "annotations",
                },
                {
                  stepName: "preview",
                  stepComponent: Step7,
                  stepId: "preview",
                },
              ]}
              title="Add New Model"
              subtitle=""
              finishButtonClick={handleAddModel}
              nextButtonClick={handleNextButtonClick}
              finishButtonText="Save"
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
