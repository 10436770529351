import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { MainAdminURL, BackgroundColor } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext"; //"../../../context/AuthContext";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      error: false,
      textures: [],
      clearFileValue: "false",
      brandLogo: "",
      brandLogoState: "",
    };
  }
  static contextType = UserTokenContext;
  sendState = (file) => {
    return this.state;
  };
  componentDidMount() {
    //console.log(this.props.inputDataProp);
    //console.log(this.context.userToken);
    //console.log(this.props.inputDataProp.skuMaterials);
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log(this.props.inputDataProp);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      if (!this.props.inputDataProp.isMaterialChangeable) {
        this.setState({ brandLogoState: "success" });
      } else {
        this.setState({ brandLogoState: "" });
      }
      this.setState({
        initialData: this.props.inputDataProp,
      });
    }

    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      if (this.state.textures) {
        this.setState({
          //clearFileValue: true,
        });
      }
      this.setState({ initialData: this.props.inputDataProp, textures: [] });
      //console.log("component Step 4 updates");
      //console.log(this.props.inputDataProp.skuMaterials);
    }
    if (this.state.texures !== prevState.textures) {
      if (this.state.textures.length !== 0) {
        //this.props.inputDataProp.setNextButtonStatusProps(true);
        //console.log("textures are changing");
        //console.log(this.state.textures);
        // Axios add textures to the materials
        var m = 0;
        var len = this.state.textures.length;
        for (; m < len; m++) {
          const URL = `${MainAdminURL}/skumaterials/${this.state.textures[m].keyValue}/`;
          const headersConfig = {
            headers: {
              Authorization: "Token " + this.context.userToken,
              "Content-Type": "multipart/form-data",
            },
          };
          let formData = new FormData();
          formData.append("texture", this.state.textures[m].file);

          //const options = {
          //onUploadProgress: (ProgressEvent) => {
          //const { loaded, total } = ProgressEvent;
          //let percent = Math.floor((loaded * 100) / total);

          //this.setState({ usdzFileLoader: percent, usdzFileLoaderVisible: true });
          //},
          //};

          axios.patch(URL, formData, headersConfig).then(
            (response) => {
              //console.log(response.data);
              //this.props.inputDataProp.setNextButtonStatusProps(false);
            },
            (error) => {
              this.props.inputDataProp.setShowGeneralErrorMessage(true);
              this.props.inputDataProp.setGeneralErrorMessage(
                "while trying to update the textures for the SKU"
              );
            }
          );
        }
      }
    }
  }
  setClearFileValue = () => {
    this.setState({ clearFileValue: false });
  };
  setBrandLogoState = (file, keyValue) => {
    //console.log(keyValue);
    if (file && keyValue) {
      const CurrentState = this.state.textures;
      const ValueExist = CurrentState.find((o) => o.keyValue === keyValue);
      if (ValueExist) {
        const newObjectToAppend = [{ keyValue: keyValue, file: file }];
        const NewState = CurrentState.map(
          (obj) => newObjectToAppend.find((o) => o.keyValue === obj.keyValue) || obj
        );
        this.setState({
          textures: NewState,
          error: false,
          brandLogo: file,
          brandLogoState: "success",
        });
      } else {
        this.setState({
          textures: [...this.state.textures, { keyValue: keyValue, file: file }],
          error: false,
          brandLogo: file,
          brandLogoState: "success",
        });
      }
    } else {
      this.setState({
        error: true,
        brandLogo: null,
        brandLogoState: "error",
      });
    }
  };

  isValidated() {
    if (this.state.brandLogoState === "success") {
      return true;
    } else {
      if (this.state.brandLogoState !== "success") {
        this.setState({ brandLogoState: "error", error: true });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridContainer justify="center">
          {!this.state.initialData.isMaterialChangeable ? (
            <span>There are no texures needed for this sku</span>
          ) : (
            <>
              {this.state.initialData.skuMaterials
                ? this.state.initialData.skuMaterials.map((texture, index) => {
                    return (
                      <React.Fragment key={index}>
                        {texture.textureRequired ? (
                          <GridItem xs={12} sm={3} key={index} style={{ textAlign: "center" }}>
                            <span>
                              Please upload texture for <strong>{texture.name}</strong> material
                            </span>
                            <ImageUpload
                              clearFileValue={this.state.clearFileValue}
                              setFileProps={this.setBrandLogoState}
                              setClearFileValueProps={this.setClearFileValue}
                              keyProps={texture.id}
                              addButtonProps={{
                                color: "rose",
                                round: false,
                              }}
                              changeButtonProps={{
                                color: "rose",
                                round: false,
                              }}
                              removeButtonProps={{
                                color: "danger",
                                round: false,
                              }}
                            />
                          </GridItem>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  })
                : ""}
            </>
          )}
        </GridContainer>
        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>All of the textures are required!</Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step4.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};
Step4.contextType = UserTokenContext;
export default withStyles(style)(Step4);
