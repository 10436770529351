import React from "react";
import axios from "axios";

import { MainAdminURL } from "../../variables/GlobalVariables";

import { UserTokenContext } from "../../context/AuthContext";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { useHistory } from "react-router-dom";
import { UserDataContext } from "../../context/AuthContext";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import SweetAlertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const useStyles = makeStyles(styles);
const useSweetAlertStyles = makeStyles(SweetAlertstyles);

export default function Brands() {
  const classes = useStyles();
  const sweetAlertClasses = useSweetAlertStyles();
  const { userData } = React.useContext(UserDataContext);
  const { userToken } = React.useContext(UserTokenContext);
  const [brandsData, setBrandsData] = React.useState();
  const [branddeleted, setBrandDeleted] = React.useState();
  const [alert, setAlert] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    console.log(userData);
  });

  React.useEffect(() => {
    //console.log(userToken);
    axios
      .get(`${MainAdminURL}/brands/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          setBrandsData(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
  }, [branddeleted]);

  const handleBrandDelete = (brandID) => {
    setAlert(null);
  };

  const warningWithConfirmMessage = (brandID, brandName) => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Permission denied!"
        onConfirm={() => hideAlert(brandName)}
        onCancel={() => hideAlert(brandName)}
        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
      >
        If you want to delete this brand, all of the models and skus conected with it, please
        contact Todor Todorov at todor.todorov@harman.com!
      </SweetAlert>
    );
  };

  const hideAlert = (brandName) => {
    setAlert(null);
    // Events URL
    const EventsURL = `${MainAdminURL}/events/`;
    // Events Form Data
    let EventFormData = new FormData();
    EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
    EventFormData.append("user_email", `${userData.email}`);
    EventFormData.append("event", "TRY DELETE BRAND");
    EventFormData.append("target", `TRY DELETE BRAND: ${brandName}`);
    // // Events Header
    const headersConfig = {
      headers: {
        Authorization: "Token " + userToken,
      },
    };
    // Constructing the data for the post request

    axios.post(EventsURL, EventFormData, headersConfig).then(
      (response) => {
        console.log(response.data);
      },
      (error) => {
        console.log("Error", error.response.data);
      }
    );
  };

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{ position: "relative", float: "right", margin: "10px" }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  // Events URL
                  const EventsURL = `${MainAdminURL}/events/`;
                  // Events Form Data
                  let EventFormData = new FormData();
                  EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
                  EventFormData.append("user_email", `${userData.email}`);
                  EventFormData.append("event", "ADD NEW BRAND START");
                  EventFormData.append("target", `EDIT BRAND: UNKNOWN`);
                  // // Events Header
                  const headersConfig = {
                    headers: {
                      Authorization: "Token " + userToken,
                    },
                  };
                  // Constructing the data for the post request

                  axios.post(EventsURL, EventFormData, headersConfig).then(
                    (response) => {
                      console.log(response.data);
                    },
                    (error) => {
                      console.log("Error", error.response.data);
                    }
                  );
                  history.push("/admin/add-brand/");
                }}
              >
                + Add Brand
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      <h3>Manage Brands</h3>
      <br />
      <GridContainer>
        {brandsData
          ? brandsData.map((brand, index) => {
              return (
                <GridItem xs={12} sm={6} md={3} key={brand.id}>
                  <Card product className={classes.cardHover}>
                    <CardHeader image className={classes.cardHeaderHover}>
                      <a
                        href={`/admin/brand/${brand.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/admin/brand/${brand.id}`);
                        }}
                      >
                        <div
                          style={{
                            width: "100wv",
                            height: "100hv",
                            padding: "40px",
                            background: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: `2px solid ${brand.primary_color}`,
                            borderRadius: "6px",
                            boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                          }}
                        >
                          <img
                            style={{
                              width: "64px",
                              height: "64px",
                              margin: "auto",
                            }}
                            src={brand.logo}
                            alt={brand.name}
                          />
                        </div>
                      </a>
                    </CardHeader>
                    <CardBody>
                      <div className={classes.cardHoverUnder}>
                        <Tooltip
                          id="tooltip-top"
                          title="Edit"
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="success"
                            simple
                            justIcon
                            href={`/admin/brand/${brand.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              // Events URL
                              const EventsURL = `${MainAdminURL}/events/`;
                              // Events Form Data
                              let EventFormData = new FormData();
                              EventFormData.append(
                                "user",
                                `${userData.first_name} ${userData.last_name}`
                              );
                              EventFormData.append("user_email", `${userData.email}`);
                              EventFormData.append("event", "EDIT BRAND START");
                              EventFormData.append("target", `EDIT BRAND: ${brand.name}`);
                              // // Events Header
                              const headersConfig = {
                                headers: {
                                  Authorization: "Token " + userToken,
                                },
                              };
                              // Constructing the data for the post request

                              axios.post(EventsURL, EventFormData, headersConfig).then(
                                (response) => {
                                  console.log(response.data);
                                },
                                (error) => {
                                  console.log("Error", error.response.data);
                                }
                              );
                              history.push(`/admin/brand/${brand.id}`);
                            }}
                          >
                            <Edit className={classes.underChartIcons} />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-top"
                          title="Delete"
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            color="danger"
                            simple
                            justIcon
                            onClick={(e) => {
                              e.preventDefault();
                              warningWithConfirmMessage(brand.id, brand.name);
                              //handleBrandDelete(brand.id);
                            }}
                          >
                            <Delete className={classes.underChartIcons} />
                          </Button>
                        </Tooltip>
                      </div>
                      <h4 className={classes.cardProductTitle}>
                        <a
                          href={`/admin/brand/${brand.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/admin/brand/${brand.id}`);
                          }}
                        >
                          {brand.name}
                        </a>
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            })
          : ""}
      </GridContainer>
    </div>
  );
}
