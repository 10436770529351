import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TestGlb from "./TestGlb.glb";
import { MainAdminURL, BackgroundColor } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext"; //"../../../context/AuthContext";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Togglestyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...Togglestyles,
};

class Step7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      checked: this.props.inputDataProp.modelData
        ? this.props.inputDataProp.modelData.is_active
        : false,
    };
  }
  sendState() {
    return this.state;
  }
  static contextType = UserTokenContext;
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    //console.log(this.props.inputDataProp);
    //console.log(this.state.posterURLData);
    //console.log(this.state.newSkuData);
    //console.log(this.state.newModelData);
  }
  isValidated() {
    return true;
  }
  handleToggle = (value) => {
    const currentSatus = this.state.checked;
    //console.log(currentSatus);

    if (currentSatus) {
      this.props.inputDataProp.setNextButtonStatusProps(true);
      this.setState({ checked: false });
      // Unpublish Model
      const URL = `${MainAdminURL}/skus/${this.state.initialData.newSKUSlug}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("is_active", false);
      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.props.inputDataProp.setNextButtonStatusProps(false);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to set the SKU as unactive"
          );
        }
      );
    } else {
      this.setState({ checked: true });
      // Publish Model
      const URL = `${MainAdminURL}/skus/${this.state.initialData.newSKUSlug}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("is_active", true);
      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage("while trying to set the SKU as active");
        }
      );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} lg={6}>
          <huemen-ar-player
            style={{
              width: "500px",
              height: "500px",
              margin: "auto",
              backgroundColor: BackgroundColor,
              background: BackgroundColor,
            }}
            // Events
            // SKU Data
            src={this.state.initialData.glbPreviewUrl}
            //ios-src={usdzLink}
            //alt={initialSKU.sku.name}
            poster={this.state.initialData.posterPreviewURL}
            // Model Data
            camera-orbit={this.state.initialData.cameraOrbitSettings}
            max-camera-orbit={this.state.initialData.maxCameraOrbitSettings}
            min-camera-orbit={this.state.initialData.minCameraOrbitSettings}
            exposure={this.state.initialData.exposureSettings}
            shadow-intensity={this.state.initialData.shadowIntensitySettings}
            shadow-softness={this.state.initialData.shadowSoftnessSettings}
            //General Options

            id="webarplayerstep7"
            reveal="interaction"
            //ar
            //ar-modes="webxr scene-viewer quick-look"
            //ar-scale="fixed"
            data-js-focus-visible
            camera-controls
            interaction-prompt="none"
          ></huemen-ar-player>
        </GridItem>
        <GridItem xs={12} sm={12} lg={6} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() => this.handleToggle()}
                checked={this.state.checked ? true : false}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="Do you want to publish this SKU?"
          />
        </GridItem>

        {this.state.error ? (
          <GridContainer justify="center" style={{ marginTop: "30px" }}>
            <GridItem xs={12} sm={8}>
              <br />
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>Poster is not generated!</Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step7.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};
Step7.contextType = UserTokenContext;
export default withStyles(style)(Step7);
