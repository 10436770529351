import React from "react";
import axios from "axios";
import { MainUserURL } from "./variables/GlobalVariables";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { UserTokenContext } from "./context/AuthContext";
import { UserDataContext } from "./context/AuthContext";

const hist = createBrowserHistory();

function App(props) {
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);
  const [userToken, setUserToken] = React.useState();
  const [userData, setUserData] = React.useState();
  const userTokenProviderValue = React.useMemo(() => ({ userToken, setUserToken }), [
    userToken,
    setUserToken,
  ]);
  const userDataProviderValue = React.useMemo(() => ({ userData, setUserData }), [
    userData,
    setUserData,
  ]);

  React.useEffect(() => {
    const userTokenLocalStorage = window.localStorage.getItem("user_token");
    if (userTokenLocalStorage) {
      //console.log("There is Token stored");
      //console.log(userTokenLocalStorage);
      setUserToken(userTokenLocalStorage);
      axios
        .get(`${MainUserURL}/user/`, {
          headers: {
            Authorization: "Token " + userTokenLocalStorage,
          },
        })
        .then(
          (response) => {
            //console.log(response.data);
            setUserData(response.data);
            setUserLoggedIn(true);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    } else {
      //console.log("There is no Token stored");
      //console.log(userTokenLocalStorage);
    }
    //console.log(userToken);
  }, [userToken]);

  return (
    <Router history={hist}>
      <Switch>
        <UserTokenContext.Provider value={userTokenProviderValue}>
          <UserDataContext.Provider value={userDataProviderValue}>
            <Route path="/auth" component={AuthLayout} />
            {userLoggedIn ? (
              <Route path="/admin" component={AdminLayout} />
            ) : (
              <Redirect from="/admin/activemodels" to="auth/login-page" />
            )}
            {userLoggedIn ? (
              <Redirect from="/auth" to="/admin/activemodels" />
            ) : (
              <Redirect from="/admin" to="auth/login-page" />
            )}
          </UserDataContext.Provider>
        </UserTokenContext.Provider>
      </Switch>
    </Router>
  );
}

export default App;
