import React from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MainAdminURL } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext";

// core components

import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import Step1 from "./EditDraftModelWizardSteps/Step1.js";
//import Step2 from "./EditDraftModelWizardSteps/Step2.js";
import Step3 from "./EditDraftModelWizardSteps/Step3.js";
//import Step4 from "./EditDraftModelWizardSteps/Step4.js";
import Step5 from "./EditDraftModelWizardSteps/Step5.js";
import Step6 from "./EditDraftModelWizardSteps/Step6.js";
import Step7 from "./EditDraftModelWizardSteps/Step7.js";

export default function EditModel() {
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  //const classes = useStyles();
  const { modelid } = useParams();
  const [nextButtonStatus, setNextButtonStatus] = React.useState(false);
  // Initial data load
  const [brandsData, setBrandsData] = React.useState();
  const [modelData, setModelData] = React.useState();
  const [categoryData, setCategoryData] = React.useState();
  // Session Started and Initialy model is created
  const [isModelCreated, setIsModelCreated] = React.useState(false);
  const [newmodelID, setNewModelID] = React.useState(null);
  const [isSKUCreated, setIsSKUCreated] = React.useState(false);
  const [newSKUID, setNewSKUID] = React.useState(null);
  const [newSKUSlug, setNewSKUSlug] = React.useState(null);
  // Data from Step 1
  const [modelName, setModelName] = React.useState();
  const [modelBrand, setModelBrand] = React.useState();
  const [modelCategory, setModelCategory] = React.useState();
  // Data from Step 2
  const [isMaterialChangeable, setIsMaterialChangeable] = React.useState();
  const [skuName, setSKUName] = React.useState();
  // Data from Step 3
  const [glbFileChanged, setGLBFileChanged] = React.useState(false);
  const [glbFile, setGlbFile] = React.useState();
  const [usdzFile, setUsdzFile] = React.useState();
  const [glbPreviewUrl, setGlbPreviewUrl] = React.useState();
  const [skuMaterials, setSkuMaterials] = React.useState();
  // Data from Step 4
  // Data from Step 5
  const [cameraOrbitSettings, setCameraOrbitSettings] = React.useState();
  const [minCameraOrbitSettings, setMinCameraOrbitSettings] = React.useState();
  const [maxCameraOrbitSettings, setMaxCameraOrbitSettings] = React.useState();
  const [exposureSettings, setExposureSettings] = React.useState();
  const [shadowIntensitySettings, setShadowIntensitySettings] = React.useState();
  const [shadowSoftnessSettings, setShadowSoftnessSettings] = React.useState();
  // Data from Step 5
  const [posterPreviewURL, setPosterPreviewURL] = React.useState();

  // Get Existing Brands
  React.useEffect(() => {
    //console.log(modelid);
    if (modelid !== "auth") {
      axios
        .get(`${MainAdminURL}/models/${modelid}/`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            console.log(response.data);
            //setModelName(response.data.name);
            setModelData(response.data);
            setNewModelID(modelid);
            setIsModelCreated(true);
            if (response.data.sku_models.length > 0) {
              setIsSKUCreated(true);
              setNewSKUID(response.data.sku_models[0].id);
              setNewSKUSlug(response.data.sku_models[0].slug);
              setSKUName(response.data.sku_models[0].name);
              setGlbPreviewUrl(response.data.sku_models[0].glb_file_3D);
              setPosterPreviewURL(response.data.sku_models[0].poster);
              setIsMaterialChangeable(response.data.sku_models[0].is_material_changable);
              console.log(response.data.sku_models[0].sku_materials);
              //setSkuMaterials(response.data.sku_models[0].sku_materials);
            }
          },
          (error) => {
            //console.log("Error", error.message);
          }
        );
    }

    //console.log(userToken);
    axios
      .get(`${MainAdminURL}/brands/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setBrandsData(response.data);
        },
        (error) => {
          //console.log("Error", error.message);
        }
      );
    axios
      .get(`${MainAdminURL}/categories/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setCategoryData(response.data);
        },
        (error) => {
          //console.log("Error", error.message);
        }
      );
  }, []);

  React.useEffect(() => {
    /*
    console.log("Step1, value for model name is setted to:");
    console.log(modelName);
    console.log("Step1, value for model brand is setted to:");
    console.log(modelBrand);
    console.log("Step1, value for model category is setted to:");
    console.log(modelCategory);
    console.log("Step2, value for sku name is setted to:");
    console.log(skuName);
    console.log("Step2, value for model brand is setted to:");
    console.log(isMaterialChangeable);
    console.log("Step3, value for .glb file is setted to:");
    console.log(glbFile);
    console.log("Step3, value for .usdz file is setted to:");
    console.log(usdzFile);
    console.log("Step3, value for glb preview URL is setted to:");
    console.log(glbPreviewUrl);
    console.log("Step3, value for sku materials is setted to:");
    console.log(skuMaterials);
    console.log("Ste 6 the poster URL is");
    console.log(posterPreviewURL);
    */
  }, [
    modelName,
    modelBrand,
    modelCategory,
    skuName,
    isMaterialChangeable,
    newmodelID,
    glbFile,
    usdzFile,
    glbPreviewUrl,
    skuMaterials,
    posterPreviewURL,
  ]);
  const handleAddModel = () => {
    history.push("/admin/activemodels");
  };
  const handleNextButtonClick = (e) => {
    setNextButtonStatus(true);
    // Step 1 - Data from Step 1
    if (e.name) {
      if (e.name.modelName && e.name.modelBrand) {
        setModelName(e.name.modelName);
        setModelBrand(e.name.modelBrand);
        setModelCategory(e.name.modelCategory);
        if (!isModelCreated) {
          // Constructing the data for the post request
          const URL = `${MainAdminURL}/models/`;
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          let formData = new FormData();
          formData.append("name", e.name.modelName);
          formData.append("brand", e.name.modelBrand);
          formData.append("category", e.name.modelCategory);
          formData.append("is_active", true);
          axios.post(URL, formData, headersConfig).then(
            (response) => {
              //console.log(response.data);
              setIsModelCreated(true);
              setNewModelID(response.data.id);
              setNextButtonStatus(false);
            },
            (error) => {
              console.log("Error", error.message);
            }
          );
        } else {
          const URL = `${MainAdminURL}/models/${newmodelID}/`;
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          let formData = new FormData();
          formData.append("name", e.name.modelName);
          formData.append("brand", e.name.modelBrand);
          formData.append("category", e.name.modelCategory);
          formData.append("is_active", true);
          axios.patch(URL, formData, headersConfig).then(
            (response) => {
              //console.log(response.data);
              setIsModelCreated(true);
              setNewModelID(response.data.id);
              setNextButtonStatus(false);
            },
            (error) => {
              //console.log("Error", error.message);
            }
          );
        }
      }
    }
    // Data from Step 2
    if (e.type) {
      setIsMaterialChangeable(e.type.isMaterialChangeable);
      setSKUName(e.type.skuName);
      if (!isSKUCreated) {
        // Constructing the data for the post request
        const URL = `${MainAdminURL}/skus/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + userToken,
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();
        formData.append("name", e.type.skuName);
        formData.append("model", newmodelID);
        formData.append("is_material_changable", e.type.isMaterialChangeable);
        formData.append("is_active", true);
        axios.post(URL, formData, headersConfig).then(
          (response) => {
            //console.log(response.data);
            setIsSKUCreated(true);
            setNewSKUID(response.data.id);
            setNewSKUSlug(response.data.slug);
            setNextButtonStatus(false);
          },
          (error) => {
            //console.log("Error", error.message);
          }
        );
      } else {
        const URL = `${MainAdminURL}/skus/${newSKUSlug}/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + userToken,
            "Content-Type": "multipart/form-data",
          },
        };
        let formData = new FormData();
        formData.append("name", e.type.skuName);
        formData.append("model", newmodelID);
        formData.append("is_material_changable", e.type.isMaterialChangeable);
        formData.append("is_active", true);
        axios.patch(URL, formData, headersConfig).then(
          (response) => {
            //console.log(response.data);
            setIsSKUCreated(true);
            setNewSKUID(response.data.id);
            setNewSKUSlug(response.data.slug);
            setNextButtonStatus(false);
          },
          (error) => {
            //console.log("Error", error.message);
          }
        );
      }
    }
    // Data from Step 3
    if (e.assets) {
      setGlbFile(e.assets.glbFile);
      setUsdzFile(e.assets.usdzFile);
      setGlbPreviewUrl(e.assets.previewURL);
      setSkuMaterials(e.assets.skuMaterils);
      setGLBFileChanged(e.assets.fileChanged);
    }
    // Data from Step 4
    if (e.textures) {
      //console.log("Step4 completed");
    }
    // Data from Step 5
    if (e.scene) {
      console.log(e.scene.cameraOrbitSettings.settings);
      setCameraOrbitSettings(e.scene.cameraOrbitSettings.settings);
      setMinCameraOrbitSettings(e.scene.minCameraOrbitSettings.settings);
      setMaxCameraOrbitSettings(e.scene.maxCameraOrbitSettings.settings);
      setExposureSettings(e.scene.exposureSettings.exposure);
      setShadowIntensitySettings(e.scene.shadowSettings.shadow_intensity);
      setShadowSoftnessSettings(e.scene.shadowSettings.shadow_softness);
    }
    if (e.poster) {
      setPosterPreviewURL(e.poster.generatedPosterURL);
    } else {
      return;
    }
  };
  const setNextButtonStatusProps = (value) => {
    if (value !== nextButtonStatus) {
      setNextButtonStatus(value);
      //console.log(value);
    }
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{
                  position: "relative",
                  float: "left",
                  margin: "10px",
                }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Go Back
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          {brandsData && categoryData ? (
            <Wizard
              inputData={{
                nextButtonStatus: nextButtonStatus,
                setNextButtonStatusProps: setNextButtonStatusProps,
                brandsData: brandsData,
                modelData: modelData,
                categoryData: categoryData,
                newModelID: newmodelID,
                newSKUID: newSKUID,
                newSKUSlug: newSKUSlug,
                isMaterialChangeable: isMaterialChangeable,
                skuMaterials: skuMaterials,
                glbFileChanged: glbFileChanged,
                glbPreviewUrl: glbPreviewUrl,
                cameraOrbitSettings: cameraOrbitSettings,
                minCameraOrbitSettings: minCameraOrbitSettings,
                maxCameraOrbitSettings: maxCameraOrbitSettings,
                exposureSettings: exposureSettings,
                shadowIntensitySettings: shadowIntensitySettings,
                shadowSoftnessSettings: shadowSoftnessSettings,
                posterPreviewURL: posterPreviewURL,
              }}
              validate
              steps={[
                //{
                //  stepName: "test",
                //  stepComponent: Test,
                //  stepId: "test",
                //},
                {
                  stepName: "model",
                  stepComponent: Step1,
                  stepId: "name",
                },

                {
                  stepName: "SKU assests",
                  stepComponent: Step3,
                  stepId: "assets",
                },

                {
                  stepName: "Scene",
                  stepComponent: Step5,
                  stepId: "scene",
                },

                {
                  stepName: "poster",
                  stepComponent: Step6,
                  stepId: "poster",
                },

                {
                  stepName: "preview",
                  stepComponent: Step7,
                  stepId: "preview",
                },
              ]}
              title="Edit Model"
              subtitle=""
              finishButtonClick={handleAddModel}
              nextButtonClick={handleNextButtonClick}
              finishButtonText="Save"
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
