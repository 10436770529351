import React from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MainAdminURL, BackgroundColor } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Togglestyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import AnnotationPreview from "components/Annotations/AnnotationPreview";

import { makeStyles } from "@material-ui/core/styles";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...Togglestyles,
};
const useStyles = makeStyles(style);
export default function QuickPreviewModel() {
  const { modelid } = useParams();
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  const classes = useStyles();

  const [modelData, setModelData] = React.useState();
  const [brandID, setBrandID] = React.useState();
  const [brandData, steBrandData] = React.useState();
  const [annotationID, setAnnotationID] = React.useState();
  const [englishID, setEnglishID] = React.useState();
  const [fileLoaded, setFileLoaded] = React.useState(false);
  const [modelCenter, setModelCenter] = React.useState();

  React.useEffect(() => {
    if (!englishID) {
      axios
        .get(`${MainAdminURL}/languages/`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            let english = response.data.find((l) => l.language_code === "en");
            setEnglishID(english.id);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
    //console.log(modelid);
    if (modelid && modelid !== "auth" && !brandID) {
      axios
        .get(`${MainAdminURL}/models/${modelid}`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            console.log(response.data);
            setModelData(response.data);
            setBrandID(response.data.brand);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
    if (brandID) {
      axios
        .get(`${MainAdminURL}/brands/${brandID}`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            console.log(response.data);
            steBrandData(response.data);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
  }, [modelid, brandID]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFileLoaded(true);
      const modelViewer = document.getElementById("webar");

      setModelCenter(modelViewer.getCameraTarget());
    }, 1500);
    return () => {
      clearTimeout(timer);
      setFileLoaded(false);
    };
  }, [brandData]);

  const openAnnotationFunction = (id) => {
    setAnnotationID(id);
  };
  const handleMouseDown = () => {
    setAnnotationID(Math.random());
  };

  return (
    <div>
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <GridItem xs={12} sm={12} md={3}>
                    <Button
                      style={{
                        position: "relative",
                        float: "left",
                        marginTop: "14px",
                        marginBottom: "14px",
                      }}
                      color="rose"
                      onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                      }}
                    >
                      Go Back
                    </Button>
                  </GridItem>
                  {modelData ? (
                    <GridItem xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
                      <h3>
                        Model: <strong>{modelData.name}</strong>
                      </h3>
                    </GridItem>
                  ) : (
                    ""
                  )}
                </GridContainer>
                {modelData && modelData.sku_models.length > 0 && brandData ? (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} lg={12}>
                      <huemen-ar-player
                        style={{
                          width: "500px",
                          height: "500px",
                          margin: "auto",
                          backgroundColor: BackgroundColor,
                          background: BackgroundColor,
                        }}
                        // Events
                        onMouseDown={handleMouseDown}
                        // SKU Data
                        src={modelData.sku_models[0].glb_file_3D}
                        //ios-src={usdzLink}
                        //alt={initialSKU.sku.name}
                        poster={modelData.sku_models[0].poster}
                        // Model Data
                        camera-orbit={modelData.model_camera_orbit.settings}
                        max-camera-orbit={modelData.model_max_camera_orbit.settings}
                        min-camera-orbit={modelData.model_min_camera_orbit.settings}
                        exposure={modelData.model_exposure.exposure}
                        shadow-intensity={modelData.model_shadow.shadow_intensity}
                        shadow-softness={modelData.model_shadow.shadow_softness}
                        //General Options
                        auto-rotate
                        auto-rotate-delay="600"
                        rotation-per-second={"10deg"}
                        id="webar"
                        //reveal="interaction"
                        //ar
                        //ar-modes="webxr scene-viewer quick-look"
                        //ar-scale="fixed"
                        data-js-focus-visible
                        camera-controls
                        interaction-prompt="none"
                      >
                        {/*  Annotations       */}
                        {fileLoaded &&
                        modelData.preview_annotations &&
                        modelData.model_annotations &&
                        modelData.model_annotations.length > 0
                          ? modelData.model_annotations.map((annotation) => (
                              <AnnotationPreview
                                key={annotation.id}
                                id={annotation.id}
                                annotationVisible={false}
                                normal={annotation.normal}
                                position={`${annotation.x_coo} ${annotation.y_coo} ${annotation.z_coo}`}
                                annotationContent={annotation.annotation_translations}
                                language={englishID}
                                color={brandData.primary_color}
                                closeAnnotation={annotationID}
                                openAnnotationFunction={openAnnotationFunction}
                              />
                            ))
                          : ""}
                        {modelCenter && modelData.preview_dimensions && modelData.model_sizes ? (
                          <>
                            <button
                              style={{
                                display: "block",
                                width: "8px",
                                height: "8px",
                                borderRadius: "4px",
                                border: "none",
                                backgroundColor: "#969696",
                                borderStyle: "none",
                                boxSizing: "border-box",
                                textAlign: "left",
                                padding: "0px",
                              }}
                              data-position="0 0.15 0"
                              slot="hotspot-rigthback"
                              data-position={`${
                                modelCenter.x + modelData.model_sizes.length / 1000 / 2
                              } ${modelCenter.y - modelData.model_sizes.width / 1000 / 2} ${
                                modelCenter.z - modelData.model_sizes.height / 1000 / 2
                              }`}
                              data-normal="1 0 0"
                            ></button>
                            <button
                              style={{
                                display: "block",
                                borderRadius: "10px",
                                border: "none",
                                backgroundColor: "#969696",
                                borderStyle: "none",
                                boxSizing: "border-box",
                                textAlign: "left",
                                width: "8px ",
                                height: "8px",
                                padding: "0px",
                              }}
                              slot="hotspot-rightup"
                              data-position={`${
                                modelCenter.x + modelData.model_sizes.length / 1000 / 2
                              } ${modelCenter.y + modelData.model_sizes.width / 1000 / 2} ${
                                modelCenter.z - modelData.model_sizes.height / 1000 / 2
                              }`}
                              data-normal="0 1 0"
                            ></button>
                            <button
                              style={{
                                display: "block",
                                borderRadius: "10px",
                                border: "none",
                                backgroundColor: "#969696",
                                borderStyle: "none",
                                boxSizing: "border-box",
                                textAlign: "left",
                                width: "8px ",
                                height: "8px",
                                padding: "0px",
                              }}
                              slot="hotspot-rightfront"
                              data-position={`${
                                modelCenter.x + modelData.model_sizes.length / 1000 / 2
                              } ${modelCenter.y - modelData.model_sizes.width / 1000 / 2} ${
                                modelCenter.z + modelData.model_sizes.height / 1000 / 2
                              }`}
                              data-normal="1 0 0"
                            ></button>
                            <button
                              style={{
                                display: "block",
                                borderRadius: "10px",
                                border: "none",
                                backgroundColor: "#969696",
                                borderStyle: "none",
                                boxSizing: "border-box",
                                textAlign: "left",
                                width: "8px ",
                                height: "8px",
                                padding: "0px",
                              }}
                              slot="hotspot-leftup"
                              data-position={`${
                                modelCenter.x - modelData.model_sizes.length / 1000 / 2
                              } ${modelCenter.y + modelData.model_sizes.width / 1000 / 2} ${
                                modelCenter.z - modelData.model_sizes.height / 1000 / 2
                              }`}
                              data-normal="0 1 0"
                            ></button>
                            <button
                              style={{
                                display: "block",
                                width: "70px",
                                height: "30px",
                                borderRadius: "32px",
                                border: "1px",
                                borderStyle: "solid",
                                borderColor: "#CFD0D0",
                                backgroundColor: "#FFF",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "5px",
                                transform: "translate(50%, 0px)",
                              }}
                              slot="hotspot-sizeX"
                              data-position={`${modelCenter.x} ${
                                modelCenter.y + modelData.model_sizes.width / 1000 / 2
                              } ${modelCenter.z - modelData.model_sizes.height / 1000 / 2}`}
                              data-normal="0 1 0"
                            >
                              {modelData.model_sizes.length + " mm"}
                            </button>
                            <button
                              style={{
                                display: "block",
                                width: "70px",
                                height: "30px",
                                borderRadius: "32px",
                                border: "1px",
                                borderStyle: "solid",
                                borderColor: "#CFD0D0",
                                backgroundColor: "#FFF",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "5px",
                                transform: "translate(50%, 0px)",
                              }}
                              slot="hotspot-sizeY"
                              data-position={`${
                                modelCenter.x + modelData.model_sizes.length / 1000 / 2
                              } ${modelCenter.y} ${
                                modelCenter.z - modelData.model_sizes.height / 1000 / 2
                              }`}
                              data-normal="1 0 0"
                            >
                              {modelData.model_sizes.width + " mm"}
                            </button>
                            <button
                              style={{
                                display: "block",
                                width: "70px",
                                height: "30px",
                                borderRadius: "32px",
                                border: "1px",
                                borderStyle: "solid",
                                borderColor: "#CFD0D0",
                                backgroundColor: "#FFF",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "5px",
                                transform: "translate(50%, 0px)",
                              }}
                              slot="hotspot-sizeZ"
                              data-position={`${
                                modelCenter.x + modelData.model_sizes.length / 1000 / 2
                              } ${modelCenter.y - modelData.model_sizes.width / 1000 / 2} ${
                                modelCenter.z
                              }`}
                              data-normal="1 0 0"
                            >
                              {modelData.model_sizes.height + " mm"}
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </huemen-ar-player>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
                      {modelData ? (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
                            <h5>List of skus</h5>
                          </GridItem>
                          {modelData.sku_models.map((sku, index) => {
                            return (
                              <GridItem
                                xs={3}
                                style={{
                                  textAlign: "center",
                                }}
                                key={index}
                              >
                                <img
                                  src={sku.poster}
                                  alt="Girl in a jacket"
                                  style={{
                                    margin: "10px",
                                    maxWidth: "75px",
                                    maxHeight: "75px",
                                    borderRadius: "6px",
                                    boxShadow:
                                      "0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
                                  }}
                                ></img>
                              </GridItem>
                            );
                          })}
                        </GridContainer>
                      ) : (
                        ""
                      )}
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
                      <h5>There are no SKUs available for this model.</h5>
                    </GridItem>
                  </GridContainer>
                )}
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    </div>
  );
}
