import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TestGlb from "./TestGlb.glb";
import { MainAdminURL, BackgroundColor } from "../../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../../context/AuthContext"; //"../../../context/AuthContext";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Togglestyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...Togglestyles,
};

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      displaySizes: this.props.inputDataProp.displaySizes,
      //
      modelDimentions: this.props.inputDataProp.modelSizes,
      modelCenter: null,
      sizeX: this.props.inputDataProp.sizeX,
      sizeY: this.props.inputDataProp.sizeY,
      sizeZ: this.props.inputDataProp.sizeZ,
    };
  }
  sendState() {
    return this.state;
  }
  static contextType = UserTokenContext;
  componentDidUpdate(prevProps, prevState) {
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
  }
  componentDidMount() {
    console.log(this.props.inputDataProp);
    const modelViewer = document.getElementById("webarplayerstep5");
    if (modelViewer) {
      modelViewer.addEventListener("load", this.fileLoaded);
    }
  }

  componentWillUnmount() {
    const modelViewer = document.getElementById("webarplayerstep5");
    modelViewer.removeEventListener("load", this.fileLoaded);
  }

  fileLoaded = (e) => {
    const modelViewer = document.getElementById("webarplayerstep5");
    console.log(modelViewer.getDimensions());
    let modelViewerSizes = modelViewer.getDimensions();
    this.setState({
      modelDimentions: modelViewerSizes,
      modelCenter: modelViewer.getCameraTarget(),
    });
    if (this.state.sizeX === 0) {
      this.setState({ sizeX: (modelViewerSizes.x * 1000).toFixed(0) });
    }
    if (this.state.sizeY === 0) {
      this.setState({ sizeY: (modelViewerSizes.y * 1000).toFixed(0) });
    }
    if (this.state.sizeZ === 0) {
      this.setState({ sizeZ: (modelViewerSizes.z * 1000).toFixed(0) });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    //console.log(this.props.inputDataProp);
    //console.log(this.state.posterURLData);
    //console.log(this.state.newSkuData);
    //console.log(this.state.newModelData);
  }
  isValidated() {
    return true;
  }

  changeSizeX(event, stateName) {
    if ((stateName = "sizeX")) {
      //console.log(event.target.value);
      this.setState({ sizeX: event.target.value });
      //console.log(event.target);
    }
  }
  changeSizeY(event, stateName) {
    if ((stateName = "sizeY")) {
      //console.log(event.target.value);
      this.setState({ sizeY: event.target.value });
      //console.log("Changing Y Size");
    }
  }
  changeSizeZ(event, stateName) {
    if ((stateName = "sizeZ")) {
      //console.log(event.target.value);
      this.setState({ sizeZ: event.target.value });
      //console.log("Changing Z Size");
    }
  }

  handleToggle = (value) => {
    const currentSatus = this.state.displaySizes;
    //console.log(currentSatus);

    if (currentSatus) {
      this.setState({ displaySizes: false });
      console.log("You need to patch the model and display or not Dimesions");
      //
      const ModelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("preview_dimensions", false);

      axios.patch(ModelURL, formData, headersConfig).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to hide the preview of the dimensions for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
    } else {
      this.setState({ displaySizes: true });
      //console.log("You need to patch the model and display or not Dimensions");
      //
      const ModelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("preview_dimensions", true);

      axios.patch(ModelURL, formData, headersConfig).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to show the preview of the dimensions for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} lg={6}>
          <huemen-ar-player
            style={{
              width: "500px",
              height: "500px",
              margin: "auto",
              backgroundColor: BackgroundColor,
              background: BackgroundColor,
            }}
            // Events
            // SKU Data
            src={this.state.initialData.glbPreviewUrl}
            //src={TestGlb}
            //ios-src={usdzLink}
            //alt={initialSKU.sku.name}
            poster={this.state.initialData.posterPreviewURL}
            // Model Data
            camera-orbit={this.state.initialData.cameraOrbitSettings}
            max-camera-orbit={this.state.initialData.maxCameraOrbitSettings}
            min-camera-orbit={this.state.initialData.minCameraOrbitSettings}
            exposure={this.state.initialData.exposureSettings}
            shadow-intensity={this.state.initialData.shadowIntensitySettings}
            shadow-softness={this.state.initialData.shadowSoftnessSettings}
            //General Options
            auto-rotate
            auto-rotate-delay="600"
            rotation-per-second={"10deg"}
            id="webarplayerstep5"
            //reveal="interaction"
            //ar
            //ar-modes="webxr scene-viewer quick-look"
            //ar-scale="fixed"
            data-js-focus-visible
            camera-controls
            interaction-prompt="none"
          >
            {this.state.modelDimentions && this.state.modelCenter && this.state.displaySizes ? (
              <>
                <button
                  style={{
                    display: "block",
                    width: "8px",
                    height: "8px",
                    borderRadius: "4px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    padding: "0px",
                  }}
                  data-position="0 0.15 0"
                  slot="hotspot-rigthback"
                  data-position={`${this.state.modelCenter.x + this.state.modelDimentions.x / 2} ${
                    this.state.modelCenter.y - this.state.modelDimentions.y / 2
                  } ${this.state.modelCenter.z - this.state.modelDimentions.z / 2}`}
                  data-normal="1 0 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    width: "8px ",
                    height: "8px",
                    padding: "0px",
                  }}
                  slot="hotspot-rightup"
                  data-position={`${this.state.modelCenter.x + this.state.modelDimentions.x / 2} ${
                    this.state.modelCenter.y + this.state.modelDimentions.y / 2
                  } ${this.state.modelCenter.z - this.state.modelDimentions.z / 2}`}
                  data-normal="0 1 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    width: "8px ",
                    height: "8px",
                    padding: "0px",
                  }}
                  slot="hotspot-rightfront"
                  data-position={`${this.state.modelCenter.x + this.state.modelDimentions.x / 2} ${
                    this.state.modelCenter.y - this.state.modelDimentions.y / 2
                  } ${this.state.modelCenter.z + this.state.modelDimentions.z / 2}`}
                  data-normal="1 0 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    width: "8px ",
                    height: "8px",
                    padding: "0px",
                  }}
                  slot="hotspot-leftup"
                  data-position={`${this.state.modelCenter.x - this.state.modelDimentions.x / 2} ${
                    this.state.modelCenter.y + this.state.modelDimentions.y / 2
                  } ${this.state.modelCenter.z - this.state.modelDimentions.z / 2}`}
                  data-normal="0 1 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    width: "70px",
                    height: "30px",
                    borderRadius: "32px",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CFD0D0",
                    backgroundColor: "#FFF",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                    transform: "translate(50%, 0px)",
                  }}
                  slot="hotspot-sizeX"
                  data-position={`${this.state.modelCenter.x} ${
                    this.state.modelCenter.y + this.state.modelDimentions.y / 2
                  } ${this.state.modelCenter.z - this.state.modelDimentions.z / 2}`}
                  data-normal="0 1 0"
                >
                  {this.state.sizeX + " mm"}
                </button>
                <button
                  style={{
                    display: "block",
                    width: "70px",
                    height: "30px",
                    borderRadius: "32px",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CFD0D0",
                    backgroundColor: "#FFF",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                    transform: "translate(50%, 0px)",
                  }}
                  slot="hotspot-sizeY"
                  data-position={`${this.state.modelCenter.x + this.state.modelDimentions.x / 2} ${
                    this.state.modelCenter.y
                  } ${this.state.modelCenter.z - this.state.modelDimentions.z / 2}`}
                  data-normal="1 0 0"
                >
                  {this.state.sizeY + " mm"}
                </button>
                <button
                  style={{
                    display: "block",
                    width: "70px",
                    height: "30px",
                    borderRadius: "32px",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CFD0D0",
                    backgroundColor: "#FFF",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                    transform: "translate(50%, 0px)",
                  }}
                  slot="hotspot-sizeZ"
                  data-position={`${this.state.modelCenter.x + this.state.modelDimentions.x / 2} ${
                    this.state.modelCenter.y - this.state.modelDimentions.y / 2
                  } ${this.state.modelCenter.z}`}
                  data-normal="1 0 0"
                >
                  {this.state.sizeZ + " mm"}
                </button>
              </>
            ) : (
              ""
            )}
          </huemen-ar-player>
        </GridItem>
        <GridItem xs={12} sm={12} lg={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => this.handleToggle()}
                    checked={this.state.displaySizes ? true : false}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="Do you want to preview the dimensions of this model?"
              />
            </GridItem>
          </GridContainer>
          {this.state.modelDimentions && this.state.modelCenter && this.state.displaySizes ? (
            <GridContainer style={{ marginTop: "30px" }}>
              <GridItem xs={3} sm={3} lg={3}>
                <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                  <strong>Model Length</strong>
                </legend>
                <CustomInput
                  success={true}
                  error={false}
                  labelText={<span>Model Length in mm</span>}
                  id="modelsizex"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.sizeX, //
                    onChange: (event) => this.changeSizeX(event, "sizeX"),
                  }}
                />
              </GridItem>
              <GridItem xs={3} sm={3} lg={3}>
                <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                  <strong>Model Height</strong>
                </legend>
                <CustomInput
                  success={true}
                  error={false}
                  labelText={<span>Model Height in mm</span>}
                  id="modelsizey"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.sizeY, //
                    onChange: (event) => this.changeSizeY(event, "sizeY"),
                  }}
                />
              </GridItem>
              <GridItem xs={3} sm={3} lg={3}>
                <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                  <strong>Model Width</strong>
                </legend>
                <CustomInput
                  success={true}
                  error={false}
                  labelText={<span>Model Width in mm</span>}
                  id="modelsizez"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.sizeZ, //
                    onChange: (event) => this.changeSizeZ(event, "sizeZ"),
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

Step5.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};
Step5.contextType = UserTokenContext;
export default withStyles(style)(Step5);
