import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { MainAdminURL, BackgroundColor } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext"; //"../../../context/AuthContext";

import { SketchPicker } from "react-color";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      newSkuData: "",
      newModelData: "",
      error: false,
      posterBlop: "",
      posterURLData: "",
      generatedPosterURL: "",
      posterState: "",
    };
  }
  sendState() {
    return this.state;
  }
  static contextType = UserTokenContext;
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    //(this.state.initialData.cameraOrbitSettings);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    //console.log(this.props.inputDataProp);
    //console.log(this.state.posterURLData);
    //console.log(this.state.newSkuData);
    //console.log(this.state.newModelData);
  }

  handleChangeComplete = (color, event) => {
    this.setState({
      error: false,
      brandColor: color.hex,
      brandColorState: "success",
    });
    //console.log(color.hex);
  };

  isValidated() {
    if (this.state.posterState === "success") {
      return true;
    } else {
      if (this.state.posterState !== "success") {
        this.setState({ posterState: "error", error: true });
      }
    }
    return false;
  }

  async HandleGeneratePoster() {
    this.props.inputDataProp.setNextButtonStatusProps(true);
    const modelViewer = document.getElementById("webarplayerstep6");
    let posterUrl = "";
    //console.log("I need to generate Poster");
    //const orbit = viewer.getCameraOrbit();
    //orbitString.textContent = `${orbit.theta}rad ${orbit.phi}rad auto`;
    //viewer.fieldOfView = 'auto';
    //viewer.jumpCameraToGoal();
    await new Promise((resolve) => requestAnimationFrame(() => resolve()));
    URL.revokeObjectURL(posterUrl);
    const blob = await modelViewer.toBlob({
      mimeType: "image/png",
      idealAspect: true,
      qualityArgument: 1,
    });
    posterUrl = URL.createObjectURL(blob);
    //console.log(blob);
    //console.log(posterUrl);
    this.setState({ posterURLData: posterUrl, posterBlop: blob });
    // Upload the Poster
    const SkuURL = `${MainAdminURL}/skus/${this.state.initialData.newSKUSlug}/`;
    //console.log(this.context.userToken);
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    formData.append("poster", blob, "poster.png");

    axios.patch(SkuURL, formData, headersConfig).then(
      (response) => {
        //console.log(response.data);
        this.setState({ posterState: "success", generatedPosterURL: response.data.poster });
        this.props.inputDataProp.setNextButtonStatusProps(false);
      },
      (error) => {
        this.props.inputDataProp.setShowGeneralErrorMessage(true);
        this.props.inputDataProp.setGeneralErrorMessage(
          "while trying to generate the poster for the SKU"
        );
      }
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} lg={6}>
          <huemen-ar-player
            style={{
              width: "850px",
              height: "850px",
              margin: "auto",
              backgroundColor: BackgroundColor,
              background: BackgroundColor,
            }}
            // Events
            // SKU Data
            src={this.state.initialData.glbPreviewUrl}
            //ios-src={usdzLink}
            //alt={initialSKU.sku.name}
            //poster={initialSKU.sku.poster}
            // Model Data
            camera-orbit={this.state.initialData.cameraOrbitSettings}
            max-camera-orbit={this.state.initialData.maxCameraOrbitSettings}
            min-camera-orbit={this.state.initialData.minCameraOrbitSettings}
            exposure={this.state.initialData.exposureSettings}
            shadow-intensity={this.state.initialData.shadowIntensitySettings}
            shadow-softness={this.state.initialData.shadowSoftnessSettings}
            //General Options

            id="webarplayerstep6"
            //reveal="eager"
            //ar
            //ar-modes="webxr scene-viewer quick-look"
            //ar-scale="fixed"
            data-js-focus-visible
            //camera-controls
            interaction-prompt="none"
          ></huemen-ar-player>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          lg={6}
          style={{ width: "850px", height: "850px", margin: "auto" }}
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            src={this.state.posterURLData}
          ></img>
        </GridItem>
        <GridItem xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
          <Button
            style={{ marginBottom: "-40px" }}
            color="rose"
            onClick={(this.HandleGeneratePoster = this.HandleGeneratePoster.bind(this))}
          >
            Generate Poster
          </Button>
        </GridItem>
        {this.state.error ? (
          <GridContainer justify="center" style={{ marginTop: "30px" }}>
            <GridItem xs={12} sm={8}>
              <br />
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>Poster is not generated!</Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step6.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};
Step6.contextType = UserTokenContext;
export default withStyles(style)(Step6);
