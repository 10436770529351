import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { MainAdminURL } from "../../variables/GlobalVariables";

import { UserTokenContext } from "../../context/AuthContext";
import { UserDataContext } from "../../context/AuthContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";

import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertstyles);

export default function Languages() {
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  const { userData } = React.useContext(UserDataContext);
  const [refresh, setRefresh] = React.useState();
  const [languages, setLanguages] = React.useState();

  const [showAddAlert, setshowAddAlert] = React.useState(false);
  const [languageID, setLanguageID] = React.useState(null);
  const [messagesID, setMessagesID] = React.useState(null);

  const [inputLanguageCode, setInputLanguageCode] = React.useState("");
  const [inputLanguage, setInputLanguage] = React.useState("");
  const [createdLanguageID, setCreatedLanguageID] = React.useState();
  const [qrMsg, setQrMsg] = React.useState("Scan me to view in your space");
  const [alertMsgTitle, setAlertMsgTitle] = React.useState("This phone does not support AR");
  const [alertMsgContent, setAlertMsgContent] = React.useState(
    "To experience AR mode, iPhone needs to be on iOS 12 or above, Android Phone need to be on Android 8.0 with ARCore 1.9 or above"
  );

  React.useEffect(() => {
    //console.log(qrMsg);
    //console.log(createdLanguageID);
    if (createdLanguageID) {
      const URL = `${MainAdminURL}/uimessages/`;
      const headersConfig = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("qr_code_button", qrMsg);
      formData.append("ar_error_massage_title", alertMsgTitle);
      formData.append("ar_error_massage_content", alertMsgContent);
      formData.append("language", createdLanguageID);
      axios.post(URL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          setCreatedLanguageID(null);
          setRefresh(response.data);
          setInputLanguage("");
          setInputLanguageCode("");
          setQrMsg("Scan me to view in your space");
          setAlertMsgTitle("This phone does not support AR");
          setAlertMsgContent(
            "To experience AR mode, iPhone needs to be on iOS 12 or above, Android Phone need to be on Android 8.0 with ARCore 1.9 or above"
          );
          hideAddAlert();
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    }
  }, [createdLanguageID, qrMsg]);

  React.useEffect(() => {
    //console.log("Need to fetch the languages");

    axios
      .get(`${MainAdminURL}/languages/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          response.data.sort((a, b) => (a.language > b.language ? 1 : -1));
          console.log(response.data);

          setLanguages(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
  }, [refresh]);

  const saveLanguage = () => {
    if (!languageID && !messagesID) {
      //console.log(inputLanguage);
      const URL = `${MainAdminURL}/languages/`;
      const headersConfig = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("language_code", inputLanguageCode);
      formData.append("language", inputLanguage);
      axios.post(URL, formData, headersConfig).then(
        (response) => {
          // Events URL
          const EventsURL = `${MainAdminURL}/events/`;
          // Events Form Data
          let EventFormData = new FormData();
          EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
          EventFormData.append("user_email", `${userData.email}`);
          EventFormData.append("event", "ADD NEW LANGUAGE");
          EventFormData.append(
            "target",
            `LANGUAGE: ${response.data.language}, LANGUAGE_CODE: ${response.data.language_code}`
          );
          // // Events Header
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          // Constructing the data for the post request

          axios.post(EventsURL, EventFormData, headersConfig).then(
            (response) => {
              console.log(response.data);
            },
            (error) => {
              console.log("Error", error.response.data);
            }
          );
          //console.log(response.data);
          setCreatedLanguageID(response.data.id);
          //setRefresh(response.data);
          //setInputLanguage("");
          //setInputLanguageCode("");
          //hideAddAlert();
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    } else {
      //console.log(inputLanguage);
      const URL = `${MainAdminURL}/languages/${languageID}/`;
      const headersConfig = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("language_code", inputLanguageCode);
      formData.append("language", inputLanguage);
      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          // Events URL
          const EventsURL = `${MainAdminURL}/events/`;
          // Events Form Data
          let EventFormData = new FormData();
          EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
          EventFormData.append("user_email", `${userData.email}`);
          EventFormData.append("event", "EDIT LANGUAGE");
          EventFormData.append(
            "target",
            `LANGUAGE: ${response.data.language}, LANGUAGE_CODE: ${response.data.language_code}`
          );
          // // Events Header
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          // Constructing the data for the post request

          axios.post(EventsURL, EventFormData, headersConfig).then(
            (response) => {
              console.log(response.data);
            },
            (error) => {
              console.log("Error", error.response.data);
            }
          );
          //console.log(response.data);
          setRefresh(response.data);
          setInputLanguage("");
          setInputLanguageCode("");
          setLanguageID(null);
          hideAddAlert();
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
      //console.log(messagesID);
      const URLMessages = `${MainAdminURL}/uimessages/${messagesID}/`;
      const headersConfigMsg = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formDataUIMessages = new FormData();
      formDataUIMessages.append("qr_code_button", qrMsg);
      formDataUIMessages.append("ar_error_massage_title", alertMsgTitle);
      formDataUIMessages.append("ar_error_massage_content", alertMsgContent);
      formDataUIMessages.append("language", languageID);
      axios.patch(URLMessages, formDataUIMessages, headersConfigMsg).then(
        (response) => {
          //console.log(response.data);
          setRefresh(response.data);
          setInputLanguage("");
          setCreatedLanguageID(null);
          setLanguageID(null);
          setMessagesID(null);
          setRefresh(response.data);
          setInputLanguage("");
          setInputLanguageCode("");
          setQrMsg("Scan me to view in your space");
          setAlertMsgTitle("This phone does not support AR");
          setAlertMsgContent(
            "To experience AR mode, iPhone needs to be on iOS 12 or above, Android Phone need to be on Android 8.0 with ARCore 1.9 or above"
          );
          hideAddAlert();
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    }
  };

  const handleEditButtonClick = (languageIndex) => {
    axios
      .get(`${MainAdminURL}/languages/${languageIndex}`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setLanguageID(response.data.id);
          setMessagesID(response.data.ui_language["0"].id);
          setInputLanguage(response.data.language);
          setInputLanguageCode(response.data.language_code);
          setQrMsg(response.data.ui_language["0"].qr_code_button);
          setAlertMsgTitle(response.data.ui_language["0"].ar_error_massage_title);
          setAlertMsgContent(response.data.ui_language["0"].ar_error_massage_content);
          //setLanguages(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    setshowAddAlert(true);
  };

  const handleDeleteButtonClick = (languageIndex, languageCode, languageName) => {
    axios
      .delete(`${MainAdminURL}/languages/${languageIndex}`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          setRefresh(languageIndex);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    // Events URL
    const EventsURL = `${MainAdminURL}/events/`;
    // Events Form Data
    let EventFormData = new FormData();
    EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
    EventFormData.append("user_email", `${userData.email}`);
    EventFormData.append("event", "EDIT LANGUAGE");
    EventFormData.append("target", `LANGUAGE: ${languageName}, LANGUAGE_CODE: ${languageCode}`);
    // // Events Header
    const headersConfig = {
      headers: {
        Authorization: "Token " + userToken,
      },
    };
    // Constructing the data for the post request

    axios.post(EventsURL, EventFormData, headersConfig).then(
      (response) => {
        console.log(response.data);
      },
      (error) => {
        console.log("Error", error.response.data);
      }
    );
  };
  const hideAddAlert = () => {
    setshowAddAlert(false);
    //console.log(response.data);
    setCreatedLanguageID(null);
    setLanguageID(null);
    setMessagesID(null);
    setInputLanguage("");
    setInputLanguageCode("");
    setQrMsg("Scan me to view in your space");
    setAlertMsgTitle("This phone does not support AR");
    setAlertMsgContent(
      "To experience AR mode, iPhone needs to be on iOS 12 or above, Android Phone need to be on Android 8.0 with ARCore 1.9 or above"
    );
  };

  const classes = useStyles();
  const alertclasess = useAlertStyles();

  return (
    <>
      {showAddAlert ? (
        <SweetAlert
          showConfirm={false}
          onConfirm={() => hideAddAlert()} //Just because is required
          style={{ display: "block", marginTop: "-100px" }}
          title="Language"
        >
          <GridContainer justify="center">
            <GridItem xs={10} sm={10}>
              <CustomInput
                labelText={<span>Language Code</span>}
                id="languageCode"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: inputLanguageCode,
                  onChange: (event) => {
                    setInputLanguageCode(event.target.value);
                    //console.log(event.target.value);
                  },
                }}
              />
              <CustomInput
                //success={this.state.brandNameState === "success"}
                //error={this.state.brandNameState === "error"}
                labelText={<span>Language</span>}
                id="language"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: inputLanguage,
                  onChange: (event) => {
                    setInputLanguage(event.target.value);
                    //console.log(event.target.value);
                  },
                }}
              />

              <label style={{ display: "block", textAlign: "left", marginTop: "10px" }}>
                QR Code Button Message
              </label>
              <TextareaAutosize
                id="qrcodebutton"
                aria-label="maximum height"
                //defaultValue="Scan me to view in your space"
                value={qrMsg}
                onChange={(e) => setQrMsg(e.target.value)}
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  minHeight: "60px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "350",
                  fontSize: "14px",
                  lineHeight: "1.5em",
                  color: "#495057 !important",
                  borderColor: "#AAAAAA",
                  opacity: "1",
                }}
              />

              <label style={{ display: "block", textAlign: "left", marginTop: "10px" }}>
                Error Alert Title
              </label>

              <TextareaAutosize
                id="arerrormassagetitle"
                aria-label="maximum height"
                value={alertMsgTitle}
                onChange={(e) => setAlertMsgTitle(e.target.value)}
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  minHeight: "60px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "350",
                  fontSize: "14px",
                  lineHeight: "1.5em",
                  color: "#495057 !important",
                  borderColor: "#AAAAAA",
                  opacity: "1",
                }}
              />
              <label style={{ display: "block", textAlign: "left", marginTop: "10px" }}>
                Error Alert Message
              </label>
              <TextareaAutosize
                id="arerrormassagecontent"
                aria-label="maximum height"
                value={alertMsgContent}
                onChange={(e) => setAlertMsgContent(e.target.value)}
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  minHeight: "110px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "350",
                  fontSize: "14px",
                  lineHeight: "1.5em",
                  color: "#495057 !important",
                  borderColor: "#AAAAAA",
                  opacity: "1",
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                color="success"
                onClick={() => {
                  saveLanguage();
                }}
              >
                Save
              </Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  setInputLanguage("");
                  setInputLanguageCode("");
                  setLanguageID(null);
                  hideAddAlert();
                }}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </SweetAlert>
      ) : (
        ""
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{ position: "relative", float: "right", margin: "10px" }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  console.log(inputLanguage);
                  setshowAddAlert(true);
                }}
              >
                + Add Language
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ paddingTop: "20px" }}>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Languages and User Interface Messages</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "id",
                  "Language",
                  "Language Code",
                  "QR Code",
                  "Alert Tittle",
                  "Alert Message",
                  "Actions",
                ]}
                tableData={
                  languages
                    ? languages.map((language, key) => [
                        key + 1,
                        language.language,
                        language.language_code,
                        language.ui_language["0"] ? language.ui_language["0"].qr_code_button : "",
                        language.ui_language["0"]
                          ? language.ui_language["0"].ar_error_massage_title
                          : "",
                        language.ui_language["0"]
                          ? language.ui_language["0"].ar_error_massage_content
                          : "",
                        <div style={{ display: "flex" }}>
                          <Button
                            color="success"
                            className={classes.actionButton}
                            onClick={() => handleEditButtonClick(language.id)}
                          >
                            <Edit className={classes.icon} />
                          </Button>
                          <Button
                            color="rose"
                            className={classes.actionButton}
                            onClick={() =>
                              handleDeleteButtonClick(
                                language.id,
                                language.language_code,
                                language.language
                              )
                            }
                          >
                            <Close className={classes.icon} />
                          </Button>
                        </div>,
                      ])
                    : []
                }
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
