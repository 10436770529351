import React from "react";
import axios from "axios";

import { MainAdminURL, BackgroundColor } from "../../variables/GlobalVariables";
import { UserTokenContext } from "../../context/AuthContext";

// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

export default function FileUpload(props) {
  const { userToken } = React.useContext(UserTokenContext);
  const [fileChanged, setFileChanged] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState();

  // File Loader
  const [fileLoader, setFileLoader] = React.useState(0);
  const [fileLoaderIsVisible, setFileLoaderIsVisible] = React.useState(false);

  React.useEffect(() => {
    //console.log(props.skuSlug);
  }, [props.skuSlug]);

  React.useEffect(() => {
    if (props.initialDataProps) {
      if (!fileChanged) {
        //console.log(props.initialDataProps);
        setFile(props.initialDataProps);
        setFileUrl(props.initialDataProps);
      }
    }
  }, [props.initialDataProps]);
  let ExtensionToCheck = props.checkExtension;
  React.useEffect(() => {
    //console.log(file);
    if (props.skuSlug && file) {
      const URL = `${MainAdminURL}/skus/${props.skuSlug}/`;

      let formData = new FormData();
      if (ExtensionToCheck === "glb") {
        formData.append("glb_file_3D", file);
      }
      if (ExtensionToCheck === "usdz") {
        formData.append("usdz_file", file);
      }

      const options = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setFileLoader(percent);
          setFileLoaderIsVisible(true);
          props.ButtonStatusSet(true);
        },
      };
      axios.patch(URL, formData, options).then(
        (response) => {
          //console.log(response.data);
          setFileLoader(0);
          setFileLoaderIsVisible(false);
          props.ButtonStatusSet(false);
          if (response.data.glb_file_3D) {
            props.setPreviewURL(response.data.glb_file_3D);
          }
        },
        (error) => {
          console.log("Error", error.message);
          props.setShowGeneralErrorMessage(true);
          props.setGeneralErrorMessage("while trying to upload the 3D file for this SKU");
        }
      );
    }
  }, [file]);

  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      //console.log(file.name);
      const CurrentLodadeFile = file.name;
      const CurrentFileExtension = CurrentLodadeFile.split(".").pop();
      //console.log(CurrentFileExtension);
      if (ExtensionToCheck === CurrentFileExtension) {
        setError(false);
        setFile(file);
        setFileUrl(file.name);
        setFileChanged(true);
        props.setFileProps(file);
      } else {
        setError(true);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  let { avatar, addButtonProps, changeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div
        style={{
          padding: "10px",
          minWidth: "180px",
          minHeight: "50px",
          boxShadow: "none",
        }}
        className={"thumbnail" + (avatar ? " img-circle" : "")}
      >
        {fileLoaderIsVisible ? (
          <CustomLinearProgress variant="determinate" color="info" value={fileLoader} />
        ) : (
          ""
        )}
        {fileUrl ? (
          <span style={{ padding: "10px", minWidth: "100px", minHeight: "100px" }}>
            {String(fileUrl)}
          </span>
        ) : (
          <span style={{ padding: "10px", minWidth: "100px", minHeight: "150px" }}>
            Upload .{ExtensionToCheck} file
          </span>
        )}
      </div>
      {error ? (
        <Danger style={{ textAlign: "center" }}>This is not .{ExtensionToCheck} file!</Danger>
      ) : (
        ""
      )}
      <div>
        {file === null ? (
          <Button
            size="sm"
            disabled={props.buttonDisabledState}
            {...addButtonProps}
            onClick={() => handleClick()}
          >
            {avatar ? "Add" : `Select .${ExtensionToCheck} file`}
          </Button>
        ) : (
          <Button
            size="sm"
            disabled={props.buttonDisabledState}
            {...changeButtonProps}
            onClick={() => handleClick()}
          >
            Change
          </Button>
        )}
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
