import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import { MainAdminURL, BackgroundColor } from "../../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../../context/AuthContext"; //"../../../context/AuthContext";

import HuemenARPlayer from "../../../../components/HuemenArPlayer/HuemenARPlayer.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// plugin that creates slider
import Slider from "nouislider";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      dataAsigned: false,
      // Scene Setup
      initialYaw: this.props.inputDataProp.modelData.model_camera_orbit
        ? this.props.inputDataProp.modelData.model_camera_orbit.settings
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0)[0]
            .split("deg")
            .join("")
        : "45",
      initialYawState: "success",
      initialPitch: this.props.inputDataProp.modelData.model_camera_orbit
        ? this.props.inputDataProp.modelData.model_camera_orbit.settings
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0)[1]
            .split("deg")
            .join("")
        : "75",
      initialPitchState: "success",
      minZoom: this.props.inputDataProp.modelData.model_min_camera_orbit
        ? this.props.inputDataProp.modelData.model_min_camera_orbit.settings
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0)[2]
            .split("m")
            .join("")
        : "auto",
      minZoomState: "success",
      maxZoom: this.props.inputDataProp.modelData.model_max_camera_orbit
        ? this.props.inputDataProp.modelData.model_max_camera_orbit.settings
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0)[2]
            .split("m")
            .join("")
        : "auto",
      maxZoomState: "success",
      error: false,
      // Exposure Slider
      initialExposure: this.props.inputDataProp.modelData.model_exposure
        ? this.props.inputDataProp.modelData.model_exposure.exposure
        : 1,
      // Shadow Intensity Slider
      initialShadowIntensity: this.props.inputDataProp.modelData.model_shadow
        ? this.props.inputDataProp.modelData.model_shadow.shadow_intensity
        : 2,
      // Shadow Softness Slider
      initialShadowSoftness: this.props.inputDataProp.modelData.model_shadow
        ? this.props.inputDataProp.modelData.model_shadow.shadow_softness
        : 1,
      saveButtonDisable: false,
      nextButtonStatus: true,
      // Result from Axios
      cameraOrbitId: this.props.inputDataProp.modelData.model_camera_orbit
        ? this.props.inputDataProp.modelData.model_camera_orbit.id
        : "",
      cameraOrbitSettings: this.props.inputDataProp.modelData.model_camera_orbit
        ? this.props.inputDataProp.modelData.model_camera_orbit
        : "",
      minCameraOrbitId: this.props.inputDataProp.modelData.model_min_camera_orbit
        ? this.props.inputDataProp.modelData.model_min_camera_orbit.id
        : "",
      minCameraOrbitSettings: this.props.inputDataProp.modelData.model_min_camera_orbit
        ? this.props.inputDataProp.modelData.model_min_camera_orbit
        : "",
      maxCameraOrbitId: this.props.inputDataProp.modelData.model_max_camera_orbit
        ? this.props.inputDataProp.modelData.model_max_camera_orbit.id
        : "",
      maxCameraOrbitSettings: this.props.inputDataProp.modelData.model_max_camera_orbit
        ? this.props.inputDataProp.modelData.model_max_camera_orbit
        : "",
      exposureId: this.props.inputDataProp.modelData.model_exposure
        ? this.props.inputDataProp.modelData.model_exposure.id
        : "",
      exposureSettings: this.props.inputDataProp.modelData.model_exposure
        ? this.props.inputDataProp.modelData.model_exposure
        : "",
      shadowId: this.props.inputDataProp.modelData.model_shadow
        ? this.props.inputDataProp.modelData.model_shadow.id
        : "",
      shadowSettings: this.props.inputDataProp.modelData.model_shadow
        ? this.props.inputDataProp.modelData.model_shadow
        : "",
    };
  }
  sendState = (file) => {
    return this.state;
  };
  static contextType = UserTokenContext;

  componentDidMount() {
    const ExposureSlider = document.getElementById("exposure");
    const ShadowIntensitySlider = document.getElementById("intensity");
    const ShadowSoftnessSlider = document.getElementById("softness");
    if (!document.getElementById("exposure").classList.contains("noUi-target")) {
      Slider.create(ExposureSlider, {
        start: [this.state.initialExposure],
        connect: [true, false],
        step: 0.01,
        range: { min: 0, max: 2 },
      });
      ExposureSlider.noUiSlider.on("update", (values, handle) => {
        let currentValue = values[handle];
        this.setState({ initialExposure: currentValue });
      });
    }
    if (!document.getElementById("intensity").classList.contains("noUi-target")) {
      Slider.create(ShadowIntensitySlider, {
        start: [this.state.initialShadowIntensity],
        connect: [true, false],
        step: 0.01,
        range: { min: 0, max: 2 },
      });
      ShadowIntensitySlider.noUiSlider.on("update", (values, handle) => {
        let currentValue = values[handle];
        this.setState({ initialShadowIntensity: currentValue });
      });
    }
    if (!document.getElementById("softness").classList.contains("noUi-target")) {
      Slider.create(ShadowSoftnessSlider, {
        start: [this.state.initialShadowSoftness],
        connect: [true, false],
        step: 0.01,
        range: { min: 0, max: 2 },
      });
      ShadowSoftnessSlider.noUiSlider.on("update", (values, handle) => {
        let currentValue = values[handle];
        this.setState({ initialShadowSoftness: currentValue });
      });
    }
    return function cleanup() {};
  }
  componentDidUpdate(prevProps, prevState) {
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }

    const modelViewer = document.getElementById("webarplayerstep3");
    modelViewer.cameraOrbit = `${this.state.initialYaw}deg  ${this.state.initialPitch}deg auto`;

    modelViewer.maxCameraOrbit = `auto auto  ${this.state.maxZoom}m`;
    modelViewer.minCameraOrbit = `auto auto ${this.state.minZoom}m`;
  }
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    if (
      this.state.initialYaw !== null &&
      this.state.initialPitch !== null &&
      this.state.minZoom !== null &&
      this.state.maxZoom !== null
    ) {
      this.setState({
        saveButtonDisable: false,
      });
    }
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success", error: false });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    if (this.state.initialYawState === "success") {
      return true;
    } else {
      if (this.state.brandLogoState !== "success") {
        this.setState({ brandLogoState: "error", error: true });
      }
    }
    return false;
  }
  handleSaveSettings = () => {
    this.props.nextButtonStatusSetInWizard(true);
    this.setState({ saveButtonDisable: true });
    let ModelID = this.state.initialData.newModelID;
    let ModelCameraOrbit = `${this.state.initialYaw}deg  ${this.state.initialPitch}deg auto`;
    let ModelMinCameraOrbit = `auto auto ${this.state.minZoom}m`;
    let ModelMaxCameraOrbit = `auto auto  ${this.state.maxZoom}m`;
    let ModelExposure = this.state.initialExposure;
    let ModelSHadowIntensity = this.state.initialShadowIntensity;
    let ModelShadowSoftness = this.state.initialShadowSoftness;
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    // Camera Orbit Post
    let formCameraOrbitData = new FormData();
    formCameraOrbitData.append("settings", ModelCameraOrbit);
    formCameraOrbitData.append("model", ModelID);
    if (!this.state.cameraOrbitId) {
      const CameraOrbitURL = `${MainAdminURL}/modelcameraorbit/`;
      axios.post(CameraOrbitURL, formCameraOrbitData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ cameraOrbitId: response.data.id, cameraOrbitSettings: response.data });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to save the Camera Orbit Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    } else {
      const CameraOrbitURL = `${MainAdminURL}/modelcameraorbit/${this.state.cameraOrbitId}/`;
      axios.patch(CameraOrbitURL, formCameraOrbitData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ cameraOrbitId: response.data.id, cameraOrbitSettings: response.data });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the Camera Orbit Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
    // Min Camera Orbit Post
    let formMinCameraOrbitData = new FormData();
    formMinCameraOrbitData.append("settings", ModelMinCameraOrbit);
    formMinCameraOrbitData.append("model", ModelID);
    if (!this.state.minCameraOrbitId) {
      const MinCameraOrbitURL = `${MainAdminURL}/modelmincameraorbit/`;
      axios.post(MinCameraOrbitURL, formMinCameraOrbitData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({
            minCameraOrbitId: response.data.id,
            minCameraOrbitSettings: response.data,
          });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to save the Camera Min Zoom Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    } else {
      const MinCameraOrbitURL = `${MainAdminURL}/modelmincameraorbit/${this.state.minCameraOrbitId}/`;
      axios.patch(MinCameraOrbitURL, formMinCameraOrbitData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({
            minCameraOrbitId: response.data.id,
            minCameraOrbitSettings: response.data,
          });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the Camera Min Zoom Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
    // Max Camera Orbit Post
    let formMaxCameraOrbitData = new FormData();
    formMaxCameraOrbitData.append("settings", ModelMaxCameraOrbit);
    formMaxCameraOrbitData.append("model", ModelID);
    if (!this.state.minCameraOrbitId) {
      const MaxCameraOrbitURL = `${MainAdminURL}/modelmaxcameraorbit/`;
      axios.post(MaxCameraOrbitURL, formMaxCameraOrbitData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({
            maxCameraOrbitId: response.data.id,
            maxCameraOrbitSettings: response.data,
          });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to save the Camera Max Zoom Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    } else {
      const MaxCameraOrbitURL = `${MainAdminURL}/modelmaxcameraorbit/${this.state.maxCameraOrbitId}/`;
      axios.patch(MaxCameraOrbitURL, formMaxCameraOrbitData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({
            maxCameraOrbitId: response.data.id,
            maxCameraOrbitSettings: response.data,
          });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the Camera Max Zoom Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
    // Camera Exposure
    let formExposureData = new FormData();
    formExposureData.append("exposure", ModelExposure);
    formExposureData.append("model", ModelID);
    if (!this.state.exposureId) {
      const ExposureURL = `${MainAdminURL}/modelsexposure/`;
      axios.post(ExposureURL, formExposureData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ exposureId: response.data.id, exposureSettings: response.data });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to save the Exposure Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    } else {
      const ExposureURL = `${MainAdminURL}/modelsexposure/${this.state.exposureId}/`;
      axios.patch(ExposureURL, formExposureData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ exposureId: response.data.id, exposureSettings: response.data });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the Exposure Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
    // Camera Shadow
    let formShadowData = new FormData();
    formShadowData.append("shadow_intensity", ModelSHadowIntensity);
    formShadowData.append("shadow_softness", ModelShadowSoftness);
    formShadowData.append("model", ModelID);
    if (!this.state.shadowId) {
      const ShadowURL = `${MainAdminURL}/modelshadow/`;
      axios.post(ShadowURL, formShadowData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ shadowId: response.data.id, shadowSettings: response.data });
          this.props.nextButtonStatusSetInWizard(false);
          this.setState({ saveButtonDisable: false });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to save the SHadow Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    } else {
      const ShadowURL = `${MainAdminURL}/modelshadow/${this.state.shadowId}/`;
      axios.patch(ShadowURL, formShadowData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ shadowId: response.data.id, shadowSettings: response.data });
          this.props.nextButtonStatusSetInWizard(false);
          this.setState({ saveButtonDisable: false });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the Exposure Settings for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} lg={6}>
          <huemen-ar-player
            style={{
              width: "500px",
              height: "500px",
              margin: "auto",
              backgroundColor: BackgroundColor,
              background: BackgroundColor,
            }}
            // Events
            // SKU Data
            src={this.props.inputDataProp.glbPreviewUrl}
            //ios-src={usdzLink}
            //alt={initialSKU.sku.name}
            //poster={initialSKU.sku.poster}
            // Model Data
            //camera-orbit="-45deg 65deg auto"
            //max-camera-orbit="auto auto 200%"
            //min-camera-orbit="auto auto 100%"
            exposure={this.state.initialExposure}
            shadow-intensity={this.state.initialShadowIntensity}
            shadow-softness={this.state.initialShadowSoftness}
            //General Options

            id="webarplayerstep3"
            //reveal="eager"
            //ar
            //ar-modes="webxr scene-viewer quick-look"
            //ar-scale="fixed"
            data-js-focus-visible
            camera-controls
            interaction-prompt="none"
          ></huemen-ar-player>
        </GridItem>
        <GridItem xs={12} sm={12} lg={6}>
          <GridContainer justify="center">
            <GridItem xs={3} sm={3} lg={3}>
              <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                <strong>Min Zoom</strong>
              </legend>
              <CustomInput
                success={this.state.minZoomState === "success"}
                error={this.state.minZoomState === "error"}
                labelText={<span>Distance</span>}
                id="minZoom"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.minZoom,
                  onChange: (event) => this.change(event, "minZoom", "length", 1),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={3} sm={3} lg={3}>
              <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                <strong>Max Zoom</strong>
              </legend>
              <CustomInput
                success={this.state.maxZoomState === "success"}
                error={this.state.maxZoomState === "error"}
                labelText={<span>Distance</span>}
                id="maxZoom"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.maxZoom,
                  onChange: (event) => this.change(event, "maxZoom", "length", 1),
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridContainer justify="center">
              <GridItem xs={6} sm={6} lg={6} style={{ marginBottom: "-36px", textAlign: "center" }}>
                <legend style={{ fontSize: "14px" }}>
                  <strong>Initial Camera Settings</strong>
                </legend>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={3} sm={3} lg={3}>
                <CustomInput
                  success={this.state.initialYawState === "success"}
                  error={this.state.initialYawState === "error"}
                  labelText={<span>X-Rotation</span>}
                  id="initialYaw"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.initialYaw,
                    onChange: (event) => this.change(event, "initialYaw", "length", 1),
                    autoComplete: "off",
                  }}
                />
              </GridItem>
              <GridItem xs={3} sm={3} lg={3}>
                <CustomInput
                  success={this.state.initialPitchState === "success"}
                  error={this.state.initialPitchState === "error"}
                  labelText={<span>Y-Rotation</span>}
                  id="initialPitch"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.initialPitch,
                    onChange: (event) => this.change(event, "initialPitch", "length", 1),
                    autoComplete: "off",
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
          <GridContainer justify="space-between"></GridContainer>

          <GridContainer justify="center">
            <GridItem xs={3} sm={3} lg={3} style={{ marginTop: "40px" }}>
              <legend style={{ fontSize: "14px" }}>Exposure</legend>
            </GridItem>

            <GridItem xs={6} sm={6} lg={6} style={{ textAlign: "center", marginTop: "58px" }}>
              <div className="slider-info" id="exposure" />
            </GridItem>
            <GridItem xs={3} sm={3} lg={3} style={{ textAlign: "center" }}>
              <CustomInput
                success={this.state.initialExposureState === "success"}
                error={this.state.initialExposureState === "error"}
                labelText=""
                id="initialExposure"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  value: this.state.initialExposure,
                  onChange: (event) => this.change(event, "initialExposure", "length", 1),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={3} sm={3} lg={3} style={{ marginTop: "40px" }}>
              <legend style={{ fontSize: "14px" }}>Shadow Intensity</legend>
            </GridItem>

            <GridItem xs={6} sm={6} lg={6} style={{ textAlign: "center", marginTop: "58px" }}>
              <div className="slider-info" id="intensity" />
            </GridItem>
            <GridItem xs={3} sm={3} lg={3} style={{ textAlign: "center" }}>
              <CustomInput
                success={this.state.initialShadowIntensityState === "success"}
                error={this.state.initialShadowIntensityState === "error"}
                labelText=""
                id="initialShadowIntensity"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  value: this.state.initialShadowIntensity,
                  onChange: (event) => this.change(event, "modelName", "length", 1),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={3} sm={3} lg={3} style={{ marginTop: "40px" }}>
              <legend style={{ fontSize: "14px" }}>Shadow Softness</legend>
            </GridItem>

            <GridItem xs={6} sm={6} lg={6} style={{ textAlign: "center", marginTop: "58px" }}>
              <div className="slider-info" id="softness" />
            </GridItem>
            <GridItem xs={3} sm={3} lg={3} style={{ textAlign: "center" }}>
              <CustomInput
                success={this.state.initialShadowSoftnessState === "success"}
                error={this.state.initialShadowSoftnessState === "error"}
                labelText=""
                id="initialShadowSoftness"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  value: this.state.initialShadowSoftness,
                  onChange: (event) => this.change(event, "modelName", "length", 1),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} lg={12} style={{ textAlign: "center" }}>
              <Button
                disabled={this.state.saveButtonDisable}
                style={{ marginBottom: "-40px" }}
                color="rose"
                onClick={this.handleSaveSettings}
              >
                Save current scene Settings for the whole Model
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>All fields are required!</Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  inputDataProp: PropTypes.object,
  nextButtonStatusSetInWizard: PropTypes.func,
  classes: PropTypes.object,
};
Step3.contextType = UserTokenContext;
export default withStyles(style)(Step3);
