import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...customSelectStyle,
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      // Changing the color to the Dropdown Menu on Success
      selectBrandColor: "#4caf50",
      selectCategoryColor: "#4caf50",
      // Handles Value of Name
      modelName: this.props.inputDataProp.modelData.name
        ? this.props.inputDataProp.modelData.name
        : "",
      modelNameState: "success",
      // Handle Value of Category
      modelCategory: this.props.inputDataProp.modelData.category
        ? this.props.inputDataProp.modelData.category
        : "",
      modelCategoryState: "success",
      // Handle Value of Brand
      modelBrand: this.props.inputDataProp.modelData.brand
        ? this.props.inputDataProp.modelData.brand
        : "",
      modelBrandState: "success",

      // Handle error Message
      error: false,
    };
  }
  sendState() {
    return this.state;
  }

  componentDidUpdate(prevProps, prevState) {
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success", error: false });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  handleBrandSimple = (event) => {
    this.setState({
      modelBrand: event.target.value,
      modelBrandState: "success",
      selectBrandColor: "#4caf50",
    });
  };

  handleCategorySimple = (event) => {
    this.setState({
      modelCategory: event.target.value,
      modelCategoryState: "success",
      selectCategoryColor: "#4caf50",
    });
  };

  isValidated() {
    if (
      this.state.modelNameState === "success" &&
      this.state.modelBrandState === "success" &&
      this.state.modelCategoryState === "success"
    ) {
      this.setState({ error: false });
      return true;
    } else {
      if (this.state.modelNameState !== "success") {
        this.setState({ modelNameState: "error", error: true });
      }
      if (this.state.modelBrandState !== "success") {
        this.setState({ modelBrandState: "error", error: true, selectBrandColor: "red" });
      }
      if (this.state.modelCategoryState !== "success") {
        this.setState({ modelCategoryState: "error", error: true, selectCategoryColor: "red" });
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <GridContainer justify="center">
          <GridContainer justify="center">
            <h3>Model Information</h3>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={12} sm={4}>
              <CustomInput
                success={this.state.modelNameState === "success"}
                error={this.state.modelNameState === "error"}
                labelText={<span>MODEL NAME</span>}
                id="modelName"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: this.state.modelName,
                  onChange: (event) => this.change(event, "modelName", "length", 1),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  <span style={{ color: this.state.selectBrandColor, fontSize: "14px" }}>
                    Select Brand
                  </span>
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.modelBrand}
                  onChange={this.handleBrandSimple}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Brand
                  </MenuItem>
                  {this.state.initialData.brandsData
                    ? this.state.initialData.brandsData.map((brand, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={brand.id}
                          >
                            {brand.name}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  <span style={{ color: this.state.selectCategoryColor, fontSize: "14px" }}>
                    Select Category
                  </span>
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.modelCategory}
                  onChange={this.handleCategorySimple}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Category
                  </MenuItem>
                  {this.state.initialData.categoryData
                    ? this.state.initialData.categoryData.map((category, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={category.id}
                          >
                            {category.name}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          {this.state.error ? (
            <GridContainer justify="center">
              <GridItem xs={12} sm={8}>
                <div style={{ textAlign: "center" }} className={classes.typo}>
                  <Danger style={{ textAlign: "center" }}>
                    Model Name field, Model Brand field and Category field are required!
                  </Danger>
                </div>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
        </GridContainer>
      </>
    );
  }
}

Step1.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
