import React from "react";
import axios from "axios";

import { useParams } from "react-router-dom";

import { MainAdminURL } from "../../variables/GlobalVariables";

import { UserTokenContext } from "../../context/AuthContext";
import { UserDataContext } from "../../context/AuthContext";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// Reference
import priceImage1 from "assets/img/card-2.jpeg";
import ArtTrack from "@material-ui/icons/ArtTrack";

import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import SweetAlertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const useStyles = makeStyles(styles);
const useSweetAlertStyles = makeStyles(SweetAlertstyles);

export default function Skus() {
  const classes = useStyles();
  const sweetAlertClasses = useSweetAlertStyles();
  const { userToken } = React.useContext(UserTokenContext);
  const { userData } = React.useContext(UserDataContext);
  const { modelid } = useParams();

  const [modelData, setModelData] = React.useState();
  const [branddeleted, setBrandDeleted] = React.useState();
  const [alert, setAlert] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    //console.log(userToken);
    if (modelid !== "auth") {
      axios
        .get(`${MainAdminURL}/models/${modelid}/`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            //console.log(response.data);
            setModelData(response.data);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
  }, [branddeleted]);

  const warningWithConfirmMessage = (skuSlug, skuName) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Are you sure you want to delete ${skuName}?`}
        onConfirm={() => handleModelDelete(skuSlug, skuName)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
        cancelBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        All of the skus conected with {skuName}, will be also deleted!
      </SweetAlert>
    );
  };
  const handleModelDelete = (skuSlug, skuName) => {
    console.log("Need to delete model" + skuSlug);
    axios
      .delete(`${MainAdminURL}/skus/${skuSlug}`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          hideAlert();
          setBrandDeleted(skuSlug);
        },
        (error) => {
          console.log("Error", error.response.data);
        }
      );
    // Events URL
    const EventsURL = `${MainAdminURL}/events/`;
    // Events Form Data
    let EventFormData = new FormData();
    EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
    EventFormData.append("user_email", `${userData.email}`);
    EventFormData.append("event", "DELETE SKU");
    EventFormData.append("target", `FOR MODEL: ${modelData.name}, SKU: ${skuName}`);
    // // Events Header
    const headersConfig = {
      headers: {
        Authorization: "Token " + userToken,
      },
    };
    // Constructing the data for the post request

    axios.post(EventsURL, EventFormData, headersConfig).then(
      (response) => {
        console.log(response.data);
      },
      (error) => {
        console.log("Error", error.response.data);
      }
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]; //or in English

  const CreateDate = (date) => {
    var date = new Date(date);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    var hours = addZero(date.getHours()); //addZero() function described below
    var minutes = addZero(date.getMinutes());

    return day + " " + monthNames[monthIndex] + " " + year; // + " at " + hours + ":" + minutes;
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }

    return i;
  }

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{
                  position: "relative",
                  float: "left",
                  margin: "10px",
                }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Go Back
              </Button>
              <Button
                style={{ position: "relative", float: "right", margin: "10px" }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  // Events URL
                  const EventsURL = `${MainAdminURL}/events/`;
                  // Events Form Data
                  let EventFormData = new FormData();
                  EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
                  EventFormData.append("user_email", `${userData.email}`);
                  EventFormData.append("event", "ADD NEW SKU START");
                  EventFormData.append("target", `FOR MODEL: ${modelData.name}, SKU: UNKNOWN`);
                  // // Events Header
                  const headersConfig = {
                    headers: {
                      Authorization: "Token " + userToken,
                    },
                  };
                  // Constructing the data for the post request

                  axios.post(EventsURL, EventFormData, headersConfig).then(
                    (response) => {
                      console.log(response.data);
                    },
                    (error) => {
                      console.log("Error", error.response.data);
                    }
                  );
                  history.push(`/admin/add-sku/${modelid}`);
                }}
              >
                + Add Sku
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>

      <React.Fragment>
        {modelData ? (
          <>
            <GridContainer style={{ marginTop: "-30px", marginBottom: "30px" }}>
              <GridItem xs={12}>
                <Card>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <h5 style={{ paddingLeft: "16px" }}>
                        Model: <strong>{modelData.name}</strong>
                      </h5>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <h5>Active Skus</h5>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {modelData.sku_models.map((sku, key) => {
                return (
                  <React.Fragment key={key}>
                    {sku.is_active ? (
                      <GridItem xs={12} sm={6} md={3} key={key}>
                        <Card product className={classes.cardHover}>
                          <CardHeader image className={classes.cardHeaderHover}>
                            <a
                              href={`/admin/quickpreview-sku/${sku.slug}`}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(`/admin/quickpreview-sku/${sku.slug}`);
                              }}
                            >
                              {modelData.sku_models.length > 0 && sku.poster ? (
                                <img
                                  style={{
                                    background: "white",
                                    height: "260px",
                                    objectFit: "scale-down",
                                  }}
                                  src={sku.poster}
                                  alt="..."
                                />
                              ) : (
                                <img src={priceImage1} alt="..." />
                              )}
                            </a>
                          </CardHeader>
                          <CardBody>
                            <div className={classes.cardHoverUnder}>
                              <Tooltip
                                id="tooltip-top"
                                title="Edit SKU"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent"
                                  simple
                                  justIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // Events URL
                                    const EventsURL = `${MainAdminURL}/events/`;
                                    // Events Form Data
                                    let EventFormData = new FormData();
                                    EventFormData.append(
                                      "user",
                                      `${userData.first_name} ${userData.last_name}`
                                    );
                                    EventFormData.append("user_email", `${userData.email}`);
                                    EventFormData.append("event", "EDIT SKU START");
                                    EventFormData.append(
                                      "target",
                                      `FOR MODEL: ${modelData.name}, SKU: ${sku.name}`
                                    );
                                    // // Events Header
                                    const headersConfig = {
                                      headers: {
                                        Authorization: "Token " + userToken,
                                      },
                                    };
                                    // Constructing the data for the post request

                                    axios.post(EventsURL, EventFormData, headersConfig).then(
                                      (response) => {
                                        console.log(response.data);
                                      },
                                      (error) => {
                                        console.log("Error", error.response.data);
                                      }
                                    );
                                    history.push(`/admin/edit-sku/${sku.slug}`);
                                  }}
                                >
                                  <Edit className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                title="Delete"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="danger"
                                  simple
                                  justIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    warningWithConfirmMessage(sku.slug, sku.name);
                                  }}
                                >
                                  <Delete className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>
                            </div>
                            <h4 className={classes.cardProductTitle}>
                              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                SKU Name: <strong>{sku.name}</strong>
                              </a>
                            </h4>
                          </CardBody>
                          <CardFooter product>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                                <span
                                  style={{
                                    marginTop: "12px",
                                    marginLeft: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  SKU Slug ID: <strong>{sku.slug}</strong>
                                </span>
                              </GridItem>
                            </GridContainer>
                          </CardFooter>
                          <CardFooter product>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <span
                                  style={{
                                    marginTop: "12px",
                                    marginLeft: "5px",
                                    float: "right",
                                  }}
                                >
                                  Last Modified: {CreateDate(modelData.modified_at)}
                                </span>
                              </GridItem>
                            </GridContainer>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}
            </GridContainer>
            <GridContainer style={{ marginTop: "-30px", marginBottom: "30px" }}>
              <GridItem xs={12}>
                <Card>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <h5 style={{ paddingLeft: "16px" }}>
                        Model: <strong>{modelData.name}</strong>
                      </h5>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <h5>Not Active Skus</h5>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {modelData.sku_models.map((sku, key) => {
                return (
                  <React.Fragment key={key}>
                    {!sku.is_active ? (
                      <GridItem xs={12} sm={6} md={3} key={key}>
                        <Card product className={classes.cardHover}>
                          <CardHeader image className={classes.cardHeaderHover}>
                            <a
                              href={`/admin/quickpreview-model/${modelData.id}`}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(`/admin/quickpreview-model/${modelData.id}`);
                              }}
                            >
                              {modelData.sku_models.length > 0 && sku.poster ? (
                                <img
                                  style={{
                                    background: "white",
                                    height: "260px",
                                    objectFit: "scale-down",
                                  }}
                                  src={sku.poster}
                                  alt="..."
                                />
                              ) : (
                                <img src={priceImage1} alt="..." />
                              )}
                            </a>
                          </CardHeader>
                          <CardBody>
                            <div className={classes.cardHoverUnder}>
                              <Tooltip
                                id="tooltip-top"
                                title="Edit SKU"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="transparent"
                                  simple
                                  justIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    history.push(`/admin/edit-sku/${sku.slug}`);
                                  }}
                                >
                                  <Edit className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                id="tooltip-top"
                                title="Delete"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  color="danger"
                                  simple
                                  justIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    warningWithConfirmMessage(sku.slug, sku.name);
                                  }}
                                >
                                  <Delete className={classes.underChartIcons} />
                                </Button>
                              </Tooltip>
                            </div>
                            <h4 className={classes.cardProductTitle}>
                              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                {sku.name}
                              </a>
                            </h4>
                          </CardBody>
                          <CardFooter product>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <span
                                  style={{
                                    marginTop: "12px",
                                    marginLeft: "5px",
                                    float: "right",
                                  }}
                                >
                                  Last Modified: {CreateDate(modelData.modified_at)}
                                </span>
                              </GridItem>
                            </GridContainer>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}
            </GridContainer>
          </>
        ) : (
          ""
        )}
      </React.Fragment>
    </div>
  );
}
