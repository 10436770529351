import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TestGlb from "./TestGlb.glb";
import { MainAdminURL, BackgroundColor } from "../../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../../context/AuthContext"; //"../../../context/AuthContext";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Togglestyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import AnnotationPreview from "components/Annotations/AnnotationPreview";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...Togglestyles,
};

class Step7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      checked: true,
      annotationID: "1234567890",
      brandPrimaryColor: "black",
      annotations: [],
      displayAnnotations: false,
      englishID: 0,
    };
  }
  sendState() {
    return this.state;
  }
  static contextType = UserTokenContext;
  componentDidMount() {
    const languageURL = `${MainAdminURL}/languages/`;
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    axios.get(languageURL, headersConfig).then(
      (response) => {
        //console.log(response.data);
        let english = response.data.find((l) => l.language_code === "en");
        console.log(english.id);
        this.setState({ englishID: english.id });
      },
      (error) => {
        this.props.inputDataProp.setShowGeneralErrorMessage(true);
        this.props.inputDataProp.setGeneralErrorMessage(
          "while trying to get the data for the languages"
        );
        //console.log("Error", error.response.data);
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      console.log(this.props.inputDataProp.brandsData);
      console.log(this.props.inputDataProp.modelBrand);
      if (this.props.inputDataProp.brandsData && this.props.inputDataProp.modelBrand) {
        const brandColor = this.props.inputDataProp.brandsData.find(
          (o) => o.id === this.props.inputDataProp.modelBrand
        ).primary_color;
        this.setState({ brandPrimaryColor: brandColor });
      }
      this.setState({
        annotations: this.props.inputDataProp.annotations,
        displayAnnotations: this.props.inputDataProp.displayAnnotations,
      });
      //console.log(this.props.inputDataProp.annotations);
      //console.log(this.props.inputDataProp.displayAnnotations);
    }
    //console.log(this.state.initialData.modelCenter);
    //console.log(this.state.initialData.modelSizes);
    //console.log(this.state.initialData.sizeX);
    //console.log(this.state.initialData.sizeY);
    //console.log(this.state.initialData.sizeZ);
    // console.log(this.state.initialData.displaySizes);

    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      //console.log(this.props.inputDataProp);
      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    //console.log(this.props.inputDataProp);
    //console.log(this.state.posterURLData);
    //console.log(this.state.newSkuData);
    //console.log(this.state.newModelData);
  }
  isValidated() {
    return true;
  }
  handleToggle = (value) => {
    const currentSatus = this.state.checked;
    //console.log(currentSatus);

    if (currentSatus) {
      this.props.inputDataProp.setNextButtonStatusProps(true);
      this.setState({ checked: false });
      // Unpublish Model
      const URL = `${MainAdminURL}/models/${this.state.initialData.newModelID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("is_active", false);
      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.props.inputDataProp.setNextButtonStatusProps(false);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to set this model as unactive"
          );
          //console.log("Error", error.response.message);
        }
      );
    } else {
      this.setState({ checked: true });
      // Publish Model
      const URL = `${MainAdminURL}/models/${this.state.initialData.newModelID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("is_active", true);
      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to set this model as active"
          );
          //console.log("Error", error.response.message);
        }
      );
    }
  };
  openAnnotationFunction = (id) => {
    this.setState({ annotationID: id });
  };
  handleMouseDown = () => {
    this.setState({ annotationID: Math.random() });
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} lg={6}>
          <huemen-ar-player
            style={{
              width: "500px",
              height: "500px",
              margin: "auto",
              backgroundColor: BackgroundColor,
              background: BackgroundColor,
            }}
            // Events
            onMouseDown={this.handleMouseDown}
            // SKU Data
            src={this.state.initialData.glbPreviewUrl}
            //ios-src={usdzLink}
            //alt={initialSKU.sku.name}
            poster={this.state.initialData.posterPreviewURL}
            // Model Data
            camera-orbit={this.state.initialData.cameraOrbitSettings}
            max-camera-orbit={this.state.initialData.maxCameraOrbitSettings}
            min-camera-orbit={this.state.initialData.minCameraOrbitSettings}
            exposure={this.state.initialData.exposureSettings}
            shadow-intensity={this.state.initialData.shadowIntensitySettings}
            shadow-softness={this.state.initialData.shadowSoftnessSettings}
            //General Options
            auto-rotate
            auto-rotate-delay="600"
            rotation-per-second={"10deg"}
            id="webarplayerstep7"
            //reveal="interaction"
            //ar
            //ar-modes="webxr scene-viewer quick-look"
            //ar-scale="fixed"
            data-js-focus-visible
            camera-controls
            interaction-prompt="none"
          >
            {/*  Annotations       */}
            {this.state.displayAnnotations &&
            this.state.annotations &&
            this.state.annotations.length > 0
              ? this.state.annotations.map((annotation) => (
                  <AnnotationPreview
                    key={annotation.id}
                    id={annotation.id}
                    annotationVisible={false}
                    normal={annotation.normal}
                    position={`${annotation.x_coo} ${annotation.y_coo} ${annotation.z_coo}`}
                    annotationContent={annotation.annotation_translations}
                    language={this.state.englishID}
                    color={this.state.brandPrimaryColor}
                    closeAnnotation={this.state.annotationID}
                    openAnnotationFunction={this.openAnnotationFunction}
                  />
                ))
              : ""}
            {this.state.initialData.modelCenter &&
            this.state.initialData.modelSizes &&
            this.state.initialData.sizeX &&
            this.state.initialData.sizeY &&
            this.state.initialData.sizeZ &&
            this.state.initialData.displaySizes ? (
              <>
                <button
                  style={{
                    display: "block",
                    width: "8px",
                    height: "8px",
                    borderRadius: "4px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    padding: "0px",
                  }}
                  data-position="0 0.15 0"
                  slot="hotspot-rigthback"
                  data-position={`${
                    this.state.initialData.modelCenter.x + this.state.initialData.modelSizes.x / 2
                  } ${
                    this.state.initialData.modelCenter.y - this.state.initialData.modelSizes.y / 2
                  } ${
                    this.state.initialData.modelCenter.z - this.state.initialData.modelSizes.z / 2
                  }`}
                  data-normal="1 0 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    width: "8px ",
                    height: "8px",
                    padding: "0px",
                  }}
                  slot="hotspot-rightup"
                  data-position={`${
                    this.state.initialData.modelCenter.x + this.state.initialData.modelSizes.x / 2
                  } ${
                    this.state.initialData.modelCenter.y + this.state.initialData.modelSizes.y / 2
                  } ${
                    this.state.initialData.modelCenter.z - this.state.initialData.modelSizes.z / 2
                  }`}
                  data-normal="0 1 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    width: "8px ",
                    height: "8px",
                    padding: "0px",
                  }}
                  slot="hotspot-rightfront"
                  data-position={`${
                    this.state.initialData.modelCenter.x + this.state.initialData.modelSizes.x / 2
                  } ${
                    this.state.initialData.modelCenter.y - this.state.initialData.modelSizes.y / 2
                  } ${
                    this.state.initialData.modelCenter.z + this.state.initialData.modelSizes.z / 2
                  }`}
                  data-normal="1 0 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#969696",
                    borderStyle: "none",
                    boxSizing: "border-box",
                    textAlign: "left",
                    width: "8px ",
                    height: "8px",
                    padding: "0px",
                  }}
                  slot="hotspot-leftup"
                  data-position={`${
                    this.state.initialData.modelCenter.x - this.state.initialData.modelSizes.x / 2
                  } ${
                    this.state.initialData.modelCenter.y + this.state.initialData.modelSizes.y / 2
                  } ${
                    this.state.initialData.modelCenter.z - this.state.initialData.modelSizes.z / 2
                  }`}
                  data-normal="0 1 0"
                ></button>
                <button
                  style={{
                    display: "block",
                    width: "70px",
                    height: "30px",
                    borderRadius: "32px",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CFD0D0",
                    backgroundColor: "#FFF",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                    transform: "translate(50%, 0px)",
                  }}
                  slot="hotspot-sizeX"
                  data-position={`${this.state.initialData.modelCenter.x} ${
                    this.state.initialData.modelCenter.y + this.state.initialData.modelSizes.y / 2
                  } ${
                    this.state.initialData.modelCenter.z - this.state.initialData.modelSizes.z / 2
                  }`}
                  data-normal="0 1 0"
                >
                  {this.state.initialData.sizeX + " mm"}
                </button>
                <button
                  style={{
                    display: "block",
                    width: "70px",
                    height: "30px",
                    borderRadius: "32px",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CFD0D0",
                    backgroundColor: "#FFF",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                    transform: "translate(50%, 0px)",
                  }}
                  slot="hotspot-sizeY"
                  data-position={`${
                    this.state.initialData.modelCenter.x + this.state.initialData.modelSizes.x / 2
                  } ${this.state.initialData.modelCenter.y} ${
                    this.state.initialData.modelCenter.z - this.state.initialData.modelSizes.z / 2
                  }`}
                  data-normal="1 0 0"
                >
                  {this.state.initialData.sizeY + " mm"}
                </button>
                <button
                  style={{
                    display: "block",
                    width: "70px",
                    height: "30px",
                    borderRadius: "32px",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CFD0D0",
                    backgroundColor: "#FFF",
                    textAlign: "center",
                    fontSize: "12px",
                    padding: "5px",
                    transform: "translate(50%, 0px)",
                  }}
                  slot="hotspot-sizeZ"
                  data-position={`${
                    this.state.initialData.modelCenter.x + this.state.initialData.modelSizes.x / 2
                  } ${
                    this.state.initialData.modelCenter.y - this.state.initialData.modelSizes.y / 2
                  } ${this.state.initialData.modelCenter.z}`}
                  data-normal="1 0 0"
                >
                  {this.state.initialData.sizeZ + " mm"}
                </button>
              </>
            ) : (
              ""
            )}
          </huemen-ar-player>
        </GridItem>
        <GridItem xs={12} sm={12} lg={6} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() => this.handleToggle()}
                checked={this.state.checked ? true : false}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="Do you want to publish this model?"
          />
        </GridItem>

        {this.state.error ? (
          <GridContainer justify="center" style={{ marginTop: "30px" }}>
            <GridItem xs={12} sm={8}>
              <br />
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>Poster is not generated!</Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step7.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};
Step7.contextType = UserTokenContext;
export default withStyles(style)(Step7);
