import React from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { MainUserURL } from "../../variables/GlobalVariables";
import { UserTokenContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const history = useHistory();
  const { setUserToken } = React.useContext(UserTokenContext);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  // Handleling Login
  // State of the input field
  const [loginEmailState, setloginEmailState] = React.useState("");
  const [loginPasswordState, setloginPasswordState] = React.useState("");
  // The Value of the Input fields
  const [emailValueField, setEmailValueField] = React.useState("");
  const [passwordValueField, setPasswordValueField] = React.useState("");
  // Error Message
  const [errorMessage, setErrorMessage] = React.useState("");
  React.useEffect(() => {
    //console.log(emailValueField);
    //console.log(passwordValueField);
  }, [emailValueField, passwordValueField]);

  const classes = useStyles();

  // function that returns true if value is email, false otherwise
  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const Login = () => {
    if (loginEmailState === "error") {
      setErrorMessage("Enter a valid email address!");
    } else if (emailValueField === "") {
      setErrorMessage("Enter a valid email address!");
    } else if (passwordValueField === "") {
      setErrorMessage("Password is required!");
    } else if (loginPasswordState === "error") {
      setErrorMessage("The password is too short!");
    } else {
      axios
        .post(`${MainUserURL}/login/`, {
          email: emailValueField,
          password: passwordValueField,
        })
        .then(
          (response) => {
            //console.log(response.data);
            setErrorMessage("");
            window.localStorage.setItem("user_token", response.data.key);
            setUserToken(response.data.key);
            history.push("/admin/activemodels");
          },
          (error) => {
            setErrorMessage(
              "Unable to log in with provided credentials. Make sure you write your email and password correctly. If you do not have an account yet, please contact todor.todorov@harman.com!"
            );
          }
        );
    }
    //console.log("I am trying to Login");
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={loginEmailState === "success"}
                  error={loginEmailState === "error"}
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      if (verifyEmail(event.target.value)) {
                        setloginEmailState("success");
                      } else {
                        setloginEmailState("error");
                      }
                      setEmailValueField(event.target.value);
                    },
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  success={loginPasswordState === "success"}
                  error={loginPasswordState === "error"}
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      if (verifyLength(event.target.value, 5)) {
                        setloginPasswordState("success");
                      } else {
                        setloginPasswordState("error");
                      }
                      setPasswordValueField(event.target.value);
                    },
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                  }}
                />
                <p style={{ textAlign: "center", color: "red" }}>{errorMessage}</p>
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block onClick={Login}>
                  Let{"'"}s Go
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
