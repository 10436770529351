import React from "react";
import axios from "axios";

import { MainAdminURL, BackgroundColor } from "../../variables/GlobalVariables";
import { UserTokenContext } from "../../context/AuthContext";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

export default function ImageUploadWizard(props) {
  const { userToken } = React.useContext(UserTokenContext);
  const [fileChanged, setFileChanged] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  // File Loader
  const [fileLoader, setFileLoader] = React.useState(0);
  const [fileLoaderIsVisible, setFileLoaderIsVisible] = React.useState(false);
  React.useEffect(() => {
    console.log(props.skuSlug);
  }, [props.skuSlug]);

  React.useEffect(() => {
    if (props.initialDataProps) {
      if (!fileChanged) {
        //console.log(props.initialDataProps);
        setFile(props.initialDataProps);
        setImagePreviewUrl(props.initialDataProps);
      }
    }
  }, [props.initialDataProps]);

  React.useEffect(() => {
    console.log(file);
    if (props.materialID && file) {
      const URL = `${MainAdminURL}/skumaterials/${props.materialID}/`;
      let formData = new FormData();
      formData.append("texture", file);
      const options = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setFileLoader(percent);
          setFileLoaderIsVisible(true);
          props.ButtonStatusSet(true);
        },
      };
      axios.patch(URL, formData, options).then(
        (response) => {
          console.log(response.data);
          setFileLoader(0);
          setFileLoaderIsVisible(false);
          props.ButtonStatusSet(false);
          if (response.data.glb_file_3D) {
            props.setPreviewURL(response.data.glb_file_3D);
          }
        },
        (error) => {
          console.log("Error", error.message);
          props.setShowGeneralErrorMessage(true);
          props.setGeneralErrorMessage("while trying to upload the texture file for this SKU");
        }
      );
    }
  }, [file]);

  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      if (file && file["type"].split("/")[0] === "image") {
        setError(false);
        setFile(file);
        setImagePreviewUrl(reader.result);
        props.setFileProps(file, props.currentIndex);
        if (props.keyProps) {
          //props.setFileProps(file, props.keyProps);
        } else {
          //props.setFileProps(file);
        }
      } else {
        setError(true);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setError(true);
    }
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img
          style={{
            width: "150px",
            height: "150px",
          }}
          src={imagePreviewUrl}
          alt="..."
        />
      </div>
      {error ? <Danger style={{ textAlign: "center" }}>This is not an image file!</Danger> : ""}
      <div>
        {file === null ? (
          <Button
            size="sm"
            {...addButtonProps}
            disabled={props.buttonDisabledState}
            onClick={() => handleClick()}
          >
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button
              size="sm"
              {...changeButtonProps}
              disabled={props.buttonDisabledState}
              onClick={() => handleClick()}
            >
              Change
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUploadWizard.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
