import React from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MainAdminURL, BackgroundColor } from "../../variables/GlobalVariables";

import { UserTokenContext } from "../../context/AuthContext";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Togglestyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { makeStyles } from "@material-ui/core/styles";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...Togglestyles,
};
const useStyles = makeStyles(style);
export default function QuickPreviewSKU() {
  const { skuid } = useParams();
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  const classes = useStyles();
  const [skuData, setSkuData] = React.useState();
  const [modelID, setModelID] = React.useState();
  const [modelData, setModelData] = React.useState();

  React.useEffect(() => {
    console.log(skuid);
    //console.log(modelid);
    if (skuid !== "auth") {
      axios
        .get(`${MainAdminURL}/skus/${skuid}`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            //console.log(response.data);
            setSkuData(response.data);
            setModelID(response.data.model);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
    if (modelID) {
      axios
        .get(`${MainAdminURL}/models/${modelID}`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            // console.log(response.data);
            setModelData(response.data);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
  }, [skuid, modelID]);

  return (
    <div>
      {skuData && modelData ? (
        <>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    <GridItem xs={12} sm={12} md={3}>
                      <Button
                        style={{
                          position: "relative",
                          float: "left",
                          marginTop: "14px",
                          marginBottom: "14px",
                        }}
                        color="rose"
                        onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }}
                      >
                        Go Back
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
                      <h3>
                        SKU Name: <strong>{skuData.name}</strong>
                      </h3>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" style={{ margin: "20px" }}>
                    <GridItem xs={12} sm={12} lg={6}>
                      <huemen-ar-player
                        style={{
                          width: "500px",
                          height: "500px",
                          backgroundColor: BackgroundColor,
                          background: BackgroundColor,
                        }}
                        // Events
                        // SKU Data
                        src={skuData.glb_file_3D}
                        //ios-src={usdzLink}
                        //alt={initialSKU.sku.name}
                        poster={skuData.poster}
                        // Model Data
                        camera-orbit={modelData.model_camera_orbit.settings}
                        max-camera-orbit={modelData.model_max_camera_orbit.settings}
                        min-camera-orbit={modelData.model_min_camera_orbit.settings}
                        exposure={modelData.model_exposure.exposure}
                        shadow-intensity={modelData.model_shadow.shadow_intensity}
                        shadow-softness={modelData.model_shadow.shadow_softness}
                        //General Options
                        auto-rotate
                        auto-rotate-delay="600"
                        rotation-per-second={"10deg"}
                        id="webarplayerstep7"
                        //reveal="interaction"
                        //ar
                        //ar-modes="webxr scene-viewer quick-look"
                        //ar-scale="fixed"
                        data-js-focus-visible
                        camera-controls
                        interaction-prompt="none"
                      ></huemen-ar-player>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
