import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
// Brand related components
import Brands from "views/Brands/Brands.js";
import EditBrand from "views/Brands/EditBrand.js";
import AddBrand from "views/Brands/AddBrand.js";
// Model related components
import ActiveModels from "views/Models/ActiveModels/ActiveModels.js";
import DraftModels from "views/Models/DraftModels/DraftModels.js";
import AddModel from "views/Models/ActiveModels/AddModel.js";
import QuickPreviewModel from "views/Models/ActiveModels/QuickPreviewModel.js";
import EditModel from "views/Models/ActiveModels/EditModel.js";
import EditDraftModel from "views/Models/DraftModels/EditDraftModel.js";
// Skus related components
import Skus from "views/Skus/Skus.js";
import Addsku from "views/Skus/AddSku.js";
import EditSku from "views/Skus/EditSku.js";
import QuickPreviewSKU from "views/Skus/QuickPreviewSKU.js";
// Languages related components
import Languages from "views/Languages/Languages";
// Categories related components
import Categories from "views/Categories/Categories";
//import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Laguage from "@material-ui/icons/Language";
import Category from "@material-ui/icons/Category";

var dashRoutes = [
  {
    path: "/brands",
    name: "Brands",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Brands,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/add-brand",
    name: "Add Brand",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AddBrand,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/brand",
    parameter: "/:brandid",
    name: "Edit Brand",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: EditBrand,
    layout: "/admin",
    visible: false,
  },
  {
    collapse: true,
    initialCollapse: false,
    name: "Model Managment",
    rtlName: "صفحات",
    icon: Image,
    state: "modelCollapse",
    visible: true,
    views: [
      {
        path: "/activemodels",
        name: "Active Models",
        mini: "AM",
        component: ActiveModels,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/add-model",
        name: "Add Model",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: AddModel,
        layout: "/admin",
        visible: false,
      },
      {
        path: "/quickpreview-model",
        parameter: "/:modelid",
        name: "Model Preview",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: QuickPreviewModel,
        layout: "/admin",
        visible: false,
      },
      {
        path: "/edit-model",
        parameter: "/:modelid",
        name: "Edit Model",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: EditModel,
        layout: "/admin",
        visible: false,
      },
      {
        path: "/draft-models",
        name: "Draft Models",
        mini: "DM",
        component: DraftModels,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/edit-draft-model",
        parameter: "/:modelid",
        name: "Edit Model",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: EditDraftModel,
        layout: "/admin",
        visible: false,
      },
    ],
  },
  {
    collapse: true,
    initialCollapse: false,
    name: "Sku Managment",
    rtlName: "صفحات",
    icon: Image,
    state: "skuCollapse",
    visible: false,
    views: [
      {
        path: "/skus",
        name: "Skus",
        parameter: "/:modelid",
        mini: "S",
        component: Skus,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/add-sku",
        name: "Add Sku",
        parameter: "/:modelid",
        mini: "AS",
        component: Addsku,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/edit-sku",
        name: "Edit Sku",
        parameter: "/:skuid",
        mini: "ES",
        component: EditSku,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/quickpreview-sku",
        parameter: "/:skuid",
        name: "Sku Preview",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: QuickPreviewSKU,
        layout: "/admin",
        visible: true,
      },
    ],
  },

  {
    path: "/languages",
    name: "Languages",
    rtlName: "لوحة القيادة",
    icon: Laguage,
    component: Languages,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/categories",
    name: "Categories",
    rtlName: "لوحة القيادة",
    icon: Category,
    component: Categories,
    layout: "/admin",
    visible: true,
  },

  {
    path: "/dashboards",
    name: "Categories",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    visible: false,
  },

  {
    collapse: true,
    initialCollapse: false,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    visible: false,
    views: [
      {
        path: "/pricing-page",
        name: "Pricing Page",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth",
        visible: true,
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "تسجيل",
        mini: "L",
        rtlMini: "صع",
        component: LoginPage,
        layout: "/auth",
        visible: false,
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
        visible: true,
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth",
        visible: true,
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth",
        visible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: Apps,
    state: "componentsCollapse",
    visible: false,
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MC",
        rtlMini: "ر",
        state: "multiCollapse",
        visible: true,
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin",
            visible: true,
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: GridSystem,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "content_paste",
    state: "formsCollapse",
    visible: false,
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: GridOn,
    state: "tablesCollapse",
    visible: false,
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: Place,
    state: "mapsCollapse",
    visible: false,
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: DateRange,
    component: Calendar,
    layout: "/admin",
    visible: false,
  },
];
export default dashRoutes;
