import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      brandName: "",
      brandNameState: "",
    };
  }
  sendState() {
    return this.state;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success", error: false });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (this.state.brandNameState === "success") {
      return true;
    } else {
      if (this.state.brandNameState !== "success") {
        this.setState({ brandNameState: "error", error: true });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Enter a brand name</h4>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.brandNameState === "success"}
            error={this.state.brandNameState === "error"}
            labelText={
              <span>
                Brand Name <small>(required)</small>
              </span>
            }
            id="brandName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.change(event, "brandName", "length", 1),
            }}
          />
        </GridItem>
        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>Brand Name field is required!</Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
