import React from "react";
import axios from "axios";
import PropTypes from "prop-types";

import { MainAdminURL, BackgroundColor } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext"; //"../../../context/AuthContext";

import HuemenARPlayer from "../../../components/HuemenArPlayer/HuemenARPlayer.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FileUpload from "components/CustomUpload/FileUpload.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      // Handle GLB
      glbFile: this.props.inputDataProp.skuData.glb_file_3D
        ? this.props.inputDataProp.skuData.glb_file_3D
        : null,
      glbFileState: this.props.inputDataProp.skuData ? "success" : "",
      previewURL: this.props.inputDataProp.skuData.glb_file_3D
        ? this.props.inputDataProp.skuData.glb_file_3D
        : "",
      glbFileLoader: 0,
      glbFileLoaderVisible: false,
      // Handle USDZ
      usdzFile: this.props.inputDataProp.skuData.usdz_file
        ? this.props.inputDataProp.skuData.usdz_file
        : null,
      usdzFileState: this.props.inputDataProp.skuData.usdz_file ? "success" : "",
      usdzFileLoader: 0,
      usdzFileLoaderVisible: false,
      // Handle on GLB file Load
      skuMaterialsChanged: false,
      skuMaterils: this.props.inputDataProp.skuData.sku_materials
        ? this.props.inputDataProp.skuData.sku_materials
        : "",
      // handle error message
      error: false,
    };
  }
  static contextType = UserTokenContext;
  sendState = () => {
    return this.state;
  };

  componentDidMount() {
    const modelViewer = document.getElementById("webarplayer");
    if (modelViewer) {
      modelViewer.addEventListener("load", this.fileLoaded);
      modelViewer.addEventListener("mouseenter", this.handleMouseOver);
      modelViewer.addEventListener("mouseleave", this.handleMouseLeave);
      //modelViewer.onmouseover
      //modelViewer.onmouseout
    }
    //console.log(this.context.userToken);
    //console.log(this.props.inputDataProp);
  }

  componentWillUnmount() {
    const modelViewer = document.getElementById("webarplayer");
    modelViewer.removeEventListener("load", this.fileLoaded);
    modelViewer.removeEventListener("mouseenter", this.handleMouseOver);
    modelViewer.removeEventListener("mouseleave", this.handleMouseLeave);
  }
  handleMouseOver = (e) => {
    //console.log("Mouse Over");
  };
  handleMouseLeave = () => {
    //console.log("Mouse Leave");
  };

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.props.inputDataProp);
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    if (
      this.state.initialData.newSKUID &&
      this.state.glbFile &&
      this.state.glbFile !== prevState.glbFile
    ) {
      this.props.inputDataProp.setNextButtonStatusProps(true);
      //console.log(this.state.glbFile);
      const URL = `${MainAdminURL}/skus/${this.state.initialData.newSKUSlug}/`;

      let formData = new FormData();
      formData.append("glb_file_3D", this.state.glbFile);

      const options = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          this.setState({ glbFileLoader: percent, glbFileLoaderVisible: true });
        },
      };
      if (this.state.glbFile instanceof File) {
        axios.patch(URL, formData, options).then(
          (response) => {
            //console.log(response.data);
            this.setState({
              previewURL: response.data.glb_file_3D,
              glbFileLoaderVisible: false,
              glbFileLoader: 0,
            });
            this.props.inputDataProp.setNextButtonStatusProps(false);
          },
          (error) => {
            this.props.inputDataProp.setShowGeneralErrorMessage(true);
            this.props.inputDataProp.setGeneralErrorMessage(
              "while trying to upload the 3D file for the SKU"
            );
          }
        );
      }
    }

    if (
      this.state.initialData.newSKUID &&
      this.state.usdzFile &&
      this.state.usdzFile !== prevState.usdzFile
    ) {
      this.props.inputDataProp.setNextButtonStatusProps(true);
      //.log(this.state.usdzFile);
      const URL = `${MainAdminURL}/skus/${this.state.initialData.newSKUSlug}/`;

      let formData = new FormData();
      formData.append("usdz_file", this.state.usdzFile);
      const options = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);

          this.setState({ usdzFileLoader: percent, usdzFileLoaderVisible: true });
        },
      };
      if (this.state.usdzFile instanceof File) {
        axios.patch(URL, formData, options).then(
          (response) => {
            //console.log(response.data);
            this.setState({
              usdzFileLoaderVisible: false,
              usdzFileLoader: 0,
            });
            this.props.inputDataProp.setNextButtonStatusProps(false);
          },
          (error) => {
            this.props.inputDataProp.setShowGeneralErrorMessage(true);
            this.props.inputDataProp.setGeneralErrorMessage(
              "while trying to upload the 3D file for the SKU"
            );
          }
        );
      }
    }

    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      this.setState({ initialData: this.props.inputDataProp });
    }
  }

  fileLoaded = (e) => {
    const modelViewer = document.getElementById("webarplayer");
    const materials = modelViewer.model.materials;
    //console.log(materials);
    if (this.state.skuMaterils) {
      console.log(this.state.skuMaterils);
      //console.log("I need to delete the materials first");
      var m = 0;
      var len = this.state.skuMaterils.length;
      for (; m < len; m++) {
        //console.log(this.state.skuMaterils[m].id);
        const DelURL = `${MainAdminURL}/skumaterials/${this.state.skuMaterils[m].id}/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + this.context.userToken,
            "Content-Type": "multipart/form-data",
          },
        };
        axios.delete(DelURL, headersConfig).then(
          (response) => {
            //console.log(response.data);
          },
          (error) => {
            this.props.inputDataProp.setShowGeneralErrorMessage(true);
            this.props.inputDataProp.setGeneralErrorMessage(
              "while trying to update the materials for the SKU"
            );
          }
        );
      }
    }
    var createdMaterials = [];

    //console.log("the materials are not set up");
    var i = 0;
    var materialLen = materials.length;
    for (; i < materialLen; i++) {
      const currentMaterial = materials[i];
      const GetColor = materials[i].pbrMetallicRoughness.baseColorFactor;
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", materials[i].name);
      formData.append("sku", parseInt(this.state.initialData.newSKUID));
      formData.append("color", `${GetColor[0]},${GetColor[1]},${GetColor[2]},${GetColor[3]}`);

      const URL = `${MainAdminURL}/skumaterials/`;
      axios.post(URL, formData, headersConfig, currentMaterial).then(
        (response) => {
          if (currentMaterial.pbrMetallicRoughness["baseColorTexture"]) {
            response.data.textureRequired = true;
          } else {
            response.data.textureRequired = false;
          }
          //console.log(response.data);
          createdMaterials.push(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the materials for the SKU"
          );
        }
      );
    }

    //console.log(createdMaterials);
    this.setState({ skuMaterils: createdMaterials });
  };

  setGlbFileState = (file) => {
    if (file) {
      this.setState({
        error: false,
        glbFile: file,
        glbFileState: "success",
        fileChanged: true,
      });
    } else {
      this.setState({
        error: true,
        glbFile: null,
        glbFileState: "error",
      });
    }
  };

  setglbFileData = (fileData) => {
    if (fileData) {
      this.setState({ glbFileData: fileData });
    }
  };

  setUsdzFileState = (file) => {
    if (file) {
      this.setState({
        error: false,
        usdzFile: file,
        usdzFileState: "success",
        fileChanged: true,
      });
    } else {
      this.setState({
        error: true,
        usdzFile: null,
        usdzFileState: "error",
      });
    }
  };

  isValidated() {
    if (this.state.usdzFileState === "success" && this.state.usdzFileState === "success") {
      return true;
    } else {
      if (this.state.brandLogoState !== "error") {
        this.setState({ brandLogoState: "error", error: true });
      }
    }
    return false;
  }

  handleButtonDisable = (value) => {
    this.setState({ buttonDisabled: value });
    this.props.nextButtonStatusSetInWizard(value);
  };

  setGLBPreviewURL = (url) => {
    this.setState({ previewURL: url });
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridContainer justify="center">
          <GridItem xs={12} sm={4}>
            <GridContainer justify="center" alignItems="center" style={{ textAlign: "center" }}>
              <GridItem xs={12}>
                <FileUpload
                  setShowGeneralErrorMessage={this.props.inputDataProp.setShowGeneralErrorMessage}
                  setGeneralErrorMessage={this.props.inputDataProp.setGeneralErrorMessage}
                  checkExtension="glb"
                  ButtonStatusSet={this.handleButtonDisable}
                  buttonDisabledState={this.state.buttonDisabled}
                  skuSlug={this.props.inputDataProp.newSKUSlug}
                  setFileProps={this.setGlbFileState}
                  setFileDataProps={this.setglbFileData}
                  setPreviewURL={this.setGLBPreviewURL}
                  addButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: false,
                  }}
                />
              </GridItem>
              <GridItem xs={11}>
                <FileUpload
                  setShowGeneralErrorMessage={this.props.inputDataProp.setShowGeneralErrorMessage}
                  setGeneralErrorMessage={this.props.inputDataProp.setGeneralErrorMessage}
                  checkExtension="usdz"
                  ButtonStatusSet={this.handleButtonDisable}
                  buttonDisabledState={this.state.buttonDisabled}
                  skuSlug={this.props.inputDataProp.newSKUSlug}
                  setFileProps={this.setUsdzFileState}
                  setPreviewURL={this.previewURL ? "" : this.setGLBPreviewURL}
                  addButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: false,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem id="fixed" xs={12} sm={8} style={{ textAlign: "center" }}>
            <huemen-ar-player
              style={{
                width: "500px",
                height: "500px",
                backgroundColor: BackgroundColor,
                background: BackgroundColor,
              }}
              // Events
              // SKU Data
              src={this.state.previewURL}
              //ios-src={usdzLink}
              //alt={initialSKU.sku.name}
              //poster={initialSKU.sku.poster}
              // Model Data
              camera-orbit={
                this.props.inputDataProp.modelData.model_camera_orbit
                  ? this.props.inputDataProp.modelData.model_camera_orbit.settings
                  : ""
              }
              max-camera-orbit={
                this.props.inputDataProp.modelData.model_max_camera_orbit
                  ? this.props.inputDataProp.modelData.model_max_camera_orbit.settings
                  : ""
              }
              min-camera-orbit={
                this.props.inputDataProp.modelData.model_min_camera_orbit
                  ? this.props.inputDataProp.modelData.model_min_camera_orbit.settings
                  : ""
              }
              exposure={
                this.props.inputDataProp.modelData.model_exposure
                  ? this.props.inputDataProp.modelData.model_exposure.exposure
                  : 1
              }
              shadow-intensity={
                this.props.inputDataProp.modelData.model_shadow
                  ? this.props.inputDataProp.modelData.model_shadow.shadow_intensity
                  : 1
              }
              shadow-softness={
                this.props.inputDataProp.modelData.model_shadow
                  ? this.props.inputDataProp.modelData.model_shadow.shadow_softness
                  : 1
              }
              //General Options

              id="webarplayer"
              //reveal="eager"
              //ar
              //ar-modes="webxr scene-viewer quick-look"
              //ar-scale="fixed"
              data-js-focus-visible
              camera-controls
              interaction-prompt="none"
            ></huemen-ar-player>
          </GridItem>
        </GridContainer>

        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={10}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>
                  Both .glb and .usdz files are required! Make sure you upload the right file
                  format!
                </Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};
Step3.contextType = UserTokenContext;
export default withStyles(style)(Step3);
