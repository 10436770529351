import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { MainAdminURL } from "../../variables/GlobalVariables";

import { UserTokenContext } from "../../context/AuthContext";
import { UserDataContext } from "../../context/AuthContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.js";

import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertstyles);

export default function Categories() {
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  const { userData } = React.useContext(UserDataContext);
  const [refresh, setRefresh] = React.useState();
  const [categories, setCategories] = React.useState();

  const [showAddAlert, setshowAddAlert] = React.useState(false);
  const [categoryID, setCategoryID] = React.useState(null);

  const [inputCategory, setInputCategory] = React.useState("");

  React.useEffect(() => {
    //console.log("Need to fetch the languages");

    axios
      .get(`${MainAdminURL}/categories/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          response.data.sort((a, b) => (a.language > b.language ? 1 : -1));
          //console.log(response.data);

          setCategories(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
  }, [refresh]);

  const saveCategory = () => {
    if (!categoryID) {
      //console.log(inputLanguage);
      const URL = `${MainAdminURL}/categories/`;
      const headersConfig = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", inputCategory);
      axios.post(URL, formData, headersConfig).then(
        (response) => {
          // Events URL
          const EventsURL = `${MainAdminURL}/events/`;
          // Events Form Data
          let EventFormData = new FormData();
          EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
          EventFormData.append("user_email", `${userData.email}`);
          EventFormData.append("event", "ADD NEW CATEGORY");
          EventFormData.append("target", `CATEGORY: ${response.data.name}`);
          // // Events Header
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          // Constructing the data for the post request

          axios.post(EventsURL, EventFormData, headersConfig).then(
            (response) => {
              console.log(response.data);
            },
            (error) => {
              console.log("Error", error.response.data);
            }
          );
          //console.log(response.data);
          setshowAddAlert(false);
          setInputCategory("");
          setRefresh(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    } else {
      const URL = `${MainAdminURL}/categories/${categoryID}/`;
      const headersConfig = {
        headers: {
          Authorization: "Token " + userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", inputCategory);
      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          // Events URL
          const EventsURL = `${MainAdminURL}/events/`;
          // Events Form Data
          let EventFormData = new FormData();
          EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
          EventFormData.append("user_email", `${userData.email}`);
          EventFormData.append("event", "EDIT CATEGORY");
          EventFormData.append("target", `CATEGORY: ${response.data.name}`);
          // // Events Header
          const headersConfig = {
            headers: {
              Authorization: "Token " + userToken,
            },
          };
          // Constructing the data for the post request

          axios.post(EventsURL, EventFormData, headersConfig).then(
            (response) => {
              console.log(response.data);
            },
            (error) => {
              console.log("Error", error.response.data);
            }
          );
          //console.log(response.data);
          setCategoryID(null);
          setRefresh(response.data);
          setInputCategory("");
          hideAddAlert();
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    }
  };

  const handleEditButtonClick = (languageIndex) => {
    axios
      .get(`${MainAdminURL}/categories/${languageIndex}`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setCategoryID(response.data.id);
          setInputCategory(response.data.name);
          //setLanguages(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    setshowAddAlert(true);
  };

  const handleDeleteButtonClick = (languageIndex, categoryName) => {
    axios
      .delete(`${MainAdminURL}/categories/${languageIndex}`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          setRefresh(languageIndex);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    // Events URL
    const EventsURL = `${MainAdminURL}/events/`;
    // Events Form Data
    let EventFormData = new FormData();
    EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
    EventFormData.append("user_email", `${userData.email}`);
    EventFormData.append("event", "DELETE CATEGORY");
    EventFormData.append("target", `CATEGORY: ${categoryName}`);
    // // Events Header
    const headersConfig = {
      headers: {
        Authorization: "Token " + userToken,
      },
    };
    // Constructing the data for the post request

    axios.post(EventsURL, EventFormData, headersConfig).then(
      (response) => {
        console.log(response.data);
      },
      (error) => {
        console.log("Error", error.response.data);
      }
    );
  };
  const hideAddAlert = () => {
    setshowAddAlert(false);
    //console.log(response.data);

    setCategoryID(null);
    setInputCategory("");
  };

  const classes = useStyles();
  const alertclasess = useAlertStyles();

  return (
    <>
      {showAddAlert ? (
        <SweetAlert
          showConfirm={false}
          onConfirm={() => hideAddAlert()} //Just because is required
          style={{ display: "block", marginTop: "-100px" }}
          title="Category"
        >
          <GridContainer justify="center">
            <GridItem xs={10} sm={10}>
              <CustomInput
                labelText={<span>Category Name</span>}
                id="categoryName"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: inputCategory,
                  onChange: (event) => {
                    setInputCategory(event.target.value);
                    //console.log(event.target.value);
                  },
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                color="success"
                onClick={() => {
                  saveCategory();
                }}
              >
                Save
              </Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  setInputCategory("");
                  setCategoryID(null);
                  hideAddAlert();
                }}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </SweetAlert>
      ) : (
        ""
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{ position: "relative", float: "right", margin: "10px" }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  setshowAddAlert(true);
                }}
              >
                + Add Category
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ paddingTop: "20px" }}>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Categories</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={["id", "Category", "Actions"]}
                tableData={
                  categories
                    ? categories.map((category, key) => [
                        key + 1,
                        category.name,
                        <>
                          <Button
                            color="success"
                            className={classes.actionButton}
                            onClick={() => handleEditButtonClick(category.id)}
                          >
                            <Edit className={classes.icon} />
                          </Button>
                          <Button
                            color="rose"
                            className={classes.actionButton}
                            onClick={() => handleDeleteButtonClick(category.id, category.name)}
                          >
                            <Close className={classes.icon} />
                          </Button>
                        </>,
                      ])
                    : []
                }
                customCellClasses={[classes.center, classes.center, classes.right]}
                customClassesForCells={[0, 1, 2]}
                customHeadCellClasses={[classes.center, classes.center, classes.right]}
                customHeadClassesForCells={[0, 1, 2]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
