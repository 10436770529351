import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TestGlb from "./TestGlb.glb";
import { MainAdminURL, BackgroundColor } from "../../../../variables/GlobalVariables";

// @material-ui/core components

import { UserTokenContext } from "../../../../context/AuthContext"; //"../../../context/AuthContext";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Togglestyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import Annotation from "components/Annotations/Annotation.js";
import SweetAlert from "react-bootstrap-sweetalert";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...Togglestyles,
  ...tableStyles,
  ...alertstyles,
};

class Step6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      languages: [],

      displayAnnotations: false,
      //
      modelDimentions: null,
      modelCenter: null,
      //
      annotations: [],
      annotationAddStart: false,
      previewPleaseClick: false,
      newAnnotationID: "",
      newAnnotationName: "",
      newAnnotationPositionX: "",
      newAnnotationPositionY: "",
      newAnnotationPositionZ: "",
      newAnnotationNormalX: "",
      newAnnotationNormalY: "",
      newAnnotationNormalZ: "",
      // Edit translations
      translationsToEdit: [],
      previewTranslationWindow: false,
      // To refresh the component on delete
      refresh: "",
    };
  }
  sendState() {
    return this.state;
  }
  static contextType = UserTokenContext;
  componentDidUpdate(prevProps, prevState) {
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    if (this.state.annotations !== this.prevState.annotations) {
      const modelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`; //${this.props.newmodelID}
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      axios.get(modelURL).then(
        (response) => {
          //console.log(response.data.model_annotations);
          this.setState({ annotations: response.data.model_annotations });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to get the annotations of this model"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
  }
  componentDidMount() {
    const languageURL = `${MainAdminURL}/languages/`;
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    axios.get(languageURL, headersConfig).then(
      (response) => {
        //console.log(response.data);
        this.setState({ languages: response.data });
        console.log(response.data);
      },
      (error) => {
        this.props.inputDataProp.setShowGeneralErrorMessage(true);
        this.props.inputDataProp.setGeneralErrorMessage(
          "while trying to get the data for the languages"
        );
        //console.log("Error", error.response.data);
      }
    );
    const modelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`; //${this.props.newmodelID}

    // axios.get(modelURL, headersConfig).then(
    //   (response) => {
    //     //console.log(response.data.model_annotations);
    //     this.setState({ annotations: response.data.model_annotations });
    //   },
    //   (error) => {
    //     this.props.inputDataProp.setShowGeneralErrorMessage(true);
    //     this.props.inputDataProp.setGeneralErrorMessage(
    //       "while trying to get the updated data of this model"
    //     );
    //     //console.log("Error", error.response.data);
    //   }
    // );

    const modelViewer = document.getElementById("webarplayerstep6");
    if (modelViewer) {
      modelViewer.addEventListener("load", this.fileLoaded);
    }
  }

  componentWillUnmount() {
    const modelViewer = document.getElementById("webarplayerstep6");
    modelViewer.removeEventListener("load", this.fileLoaded);
  }

  fileLoaded = (e) => {
    const modelViewer = document.getElementById("webarplayerstep6");

    let modelViewerSizes = modelViewer.getDimensions();
    this.setState({
      modelDimentions: modelViewerSizes,
      modelCenter: modelViewer.getCameraTarget(),
    });
    if (this.state.sizeX === 0) {
      this.setState({ sizeX: (modelViewerSizes.x * 1000).toFixed(0) });
    }
    if (this.state.sizeY === 0) {
      this.setState({ sizeY: (modelViewerSizes.y * 1000).toFixed(0) });
    }
    if (this.state.sizeZ === 0) {
      this.setState({ sizeZ: (modelViewerSizes.z * 1000).toFixed(0) });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.refresh != prevState.refresh) {
      const languageURL = `${MainAdminURL}/languages/`;
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      axios.get(languageURL, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ languages: response.data });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the data for the languages"
          );
          //console.log("Error", error.response.data);
        }
      );
      const modelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`; //${this.props.newmodelID}

      axios.get(modelURL, headersConfig).then(
        (response) => {
          //console.log(response.data.model_annotations);
          this.setState({ annotations: response.data.model_annotations });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the updated data of this model"
          );
          //console.log("Error", error.response.data);
        }
      );
    }
    if (this.state !== prevState) {
      //console.log("Need to changge slot");
      try {
        const modelViewer = document.getElementById("webarplayerstep6");
        const dataPosition = `${this.state.newAnnotationPositionX} ${this.state.newAnnotationPositionY} ${this.state.newAnnotationPositionZ}`;
        const dataNormal = `${this.state.newAnnotationNormalX} ${this.state.newAnnotationNormalY} ${this.state.newAnnotationNormalZ}`;
        modelViewer.updateHotspot({
          name: "hotspot-button-new",
          position: dataPosition,
          normal: dataNormal,
        });
      } catch (error) {
        return;
      }

      //"hotspot-button-new";
    }
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):

      this.setState({
        initialData: this.props.inputDataProp,
      });
    }

    //console.log(this.state.newModelData);
  }
  isValidated() {
    return true;
  }

  handleToggle = (value) => {
    const currentSatus = this.state.displayAnnotations;
    //console.log(currentSatus);

    if (currentSatus) {
      this.setState({ displayAnnotations: false });
      console.log("You need to patch the model and display or not Annotations");

      const ModelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("preview_annotations", false);

      axios.patch(ModelURL, formData, headersConfig).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to hide the annotations for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
    } else {
      this.setState({ displayAnnotations: true });
      console.log("You need to patch the model and display or not Annotations");
      console.log(this.props.inputDataProp.newModelID);

      const ModelURL = `${MainAdminURL}/models/${this.props.inputDataProp.newModelID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("preview_annotations", true);

      axios.patch(ModelURL, formData, headersConfig).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to show the annotations for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
    }
  };
  // WHen you click Add New Annotation Button
  addAnnotation = () => {
    this.props.nextButtonStatusSetInWizard(true);
    //console.log("Adding Annotation");
    this.setState({ annotationAddStart: true, previewPleaseClick: true });
    const modelViewer = document.getElementById("webarplayerstep6");
    modelViewer.addEventListener(
      "click",
      (e) => {
        const modelViewer = document.getElementById("webarplayerstep6");
        const rect = modelViewer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        //console.log(x);
        //console.log(y);
        const positionAndNormal = modelViewer.positionAndNormalFromPoint(x, y);
        if (positionAndNormal) {
          //console.log(positionAndNormal);
          this.setState({
            previewPleaseClick: false,
            newAnnotationPositionX: positionAndNormal.position.x.toFixed(5),
            newAnnotationPositionY: positionAndNormal.position.y.toFixed(5),
            newAnnotationPositionZ: positionAndNormal.position.z.toFixed(5),
            newAnnotationNormalX: positionAndNormal.normal.x.toFixed(5),
            newAnnotationNormalY: positionAndNormal.normal.y.toFixed(5),
            newAnnotationNormalZ: positionAndNormal.normal.z.toFixed(5),
          });
        } else {
          this.addAnnotation();
        }
        //console.log(this.state.newAnnotationPositionX);
      },
      { once: true }
    );
  };
  // On Save Annotation Click
  handleSaveNewAnnotation = () => {
    if (this.state.newAnnotationID) {
      const SkuURL = `${MainAdminURL}/modelannotations/${this.state.newAnnotationID}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", this.state.newAnnotationName);
      formData.append("model", this.props.inputDataProp.newModelID); //Change this
      formData.append("x_coo", this.state.newAnnotationPositionX);
      formData.append("y_coo", this.state.newAnnotationPositionY);
      formData.append("z_coo", this.state.newAnnotationPositionZ);
      formData.append(
        "normal",
        `${this.state.newAnnotationNormalX} ${this.state.newAnnotationNormalY} ${this.state.newAnnotationNormalZ}`
      );
      formData.append("is_visible", true);

      axios.patch(SkuURL, formData, headersConfig).then(
        (response) => {
          this.props.nextButtonStatusSetInWizard(false);
          //(response.data);
          let modelViewer = document.getElementById("webarplayerstep6");
          let name = `hotspot-button-${response.data.id}`;
          let dataPosition = `${response.data.x_coo} ${response.data.y_coo} ${response.data.z_coo}`;
          let dataNormal = response.data.normal;
          modelViewer.updateHotspot({
            name: name,
            position: dataPosition,
            normal: dataNormal,
          });
          this.setState({ refresh: Math.random() });
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the annotation for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
    } else {
      const SkuURL = `${MainAdminURL}/modelannotations/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", this.state.newAnnotationName);
      formData.append("model", this.props.inputDataProp.newModelID); //Change this
      formData.append("x_coo", this.state.newAnnotationPositionX);
      formData.append("y_coo", this.state.newAnnotationPositionY);
      formData.append("z_coo", this.state.newAnnotationPositionZ);
      formData.append(
        "normal",
        `${this.state.newAnnotationNormalX} ${this.state.newAnnotationNormalY} ${this.state.newAnnotationNormalZ}`
      );
      formData.append("is_visible", true);
      this.props.nextButtonStatusSetInWizard(true);
      axios.post(SkuURL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          this.setState({ annotations: [...this.state.annotations, response.data] });
          let newAnnotationID = response.data.id;
          if (response.data) {
            let newTranslationsCreated = [];
            const LanURL = `${MainAdminURL}/modelannotationtranslations/`;
            for (let i = 0; i < this.state.languages.length; i++) {
              let newLang = new FormData();
              newLang.append("model_annotation", response.data.id);
              newLang.append("language_code", this.state.languages[i].id);
              newLang.append("title", "Example Title");
              newLang.append("content", "Example Content");
              //console.log(response.data.id);
              //console.log(this.state.languages[i].id);

              axios.post(LanURL, newLang, headersConfig).then(
                (response) => {
                  //console.log(response.data);
                  // Edit here the button
                  let currentAnnotationState = this.state.annotations;
                  let annotationTranslationState = currentAnnotationState.find(
                    (el) => el.id === newAnnotationID
                  );
                  let index = currentAnnotationState.findIndex((el) => el.id === newAnnotationID);

                  newTranslationsCreated.push(response.data);

                  if (i === this.state.languages.length - 1) {
                    annotationTranslationState.annotation_translations = newTranslationsCreated;
                    currentAnnotationState[index] = annotationTranslationState;
                    this.setState({ annotations: currentAnnotationState });
                    this.props.nextButtonStatusSetInWizard(false);
                  }
                },
                (error) => {
                  this.props.inputDataProp.setShowGeneralErrorMessage(true);
                  this.props.inputDataProp.setGeneralErrorMessage(
                    "while trying to create the annotation language translations for this model"
                  );
                  //console.log("Error", error.response.message);
                }
              );
            }
          }
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to create the annotation for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
    }

    this.setState({
      annotationAddStart: false,
      newAnnotationID: "",
      newAnnotationName: "",
      newAnnotationPositionX: "",
      newAnnotationPositionY: "",
      newAnnotationPositionZ: "",
      newAnnotationNormalX: "",
      newAnnotationNormalY: "",
      newAnnotationNormalZ: "",
    });
    //console.log("Need to save the annotation");
  };
  handleCancelNewAnnotation = () => {
    this.props.nextButtonStatusSetInWizard(false);
    this.setState({
      annotationAddStart: false,
      newAnnotationID: "",
      newAnnotationName: "",
      newAnnotationPositionX: "",
      newAnnotationPositionY: "",
      newAnnotationPositionZ: "",
      newAnnotationNormalX: "",
      newAnnotationNormalY: "",
      newAnnotationNormalZ: "",
    });
  };

  handleChangeAnnotationName = (event) => {
    this.setState({ newAnnotationName: event.target.value });
  };
  handleChangePositionX = (event) => {
    this.setState({ newAnnotationPositionX: event.target.value });
  };
  handleChangePositionY = (event) => {
    this.setState({ newAnnotationPositionY: event.target.value });
  };
  handleChangePositionZ = (event) => {
    this.setState({ newAnnotationPositionZ: event.target.value });
  };
  handleChangeNormalX = (event) => {
    this.setState({ newAnnotationNormalX: event.target.value });
  };
  handleChangeNormalY = (event) => {
    this.setState({ newAnnotationNormalY: event.target.value });
  };
  handleChangeNormalZ = (event) => {
    this.setState({ newAnnotationNormalZ: event.target.value });
  };
  handleDeleteAnnotation = (id) => {
    const DelURL = `${MainAdminURL}/modelannotations/${id}/`;
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    axios.delete(DelURL, headersConfig).then(
      (response) => {
        //console.log(response.data);

        this.setState({ refresh: Math.random() });
      },
      (error) => {
        this.props.inputDataProp.setShowGeneralErrorMessage(true);
        this.props.inputDataProp.setGeneralErrorMessage(
          "while trying to delete the annotation for this model"
        );
        //console.log("Error", error.response.data);
      }
    );
  };
  handleEditAnnotation = (id) => {
    // Frist fetch the annotation and populate the data
    const GetURL = `${MainAdminURL}/modelannotations/${id}/`;
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    axios.get(GetURL, headersConfig).then(
      (response) => {
        //(response.data);
        let normalData = response.data.normal.split(" ");
        //console.log(normalData);
        this.setState({
          newAnnotationID: response.data.id,
          newAnnotationName: response.data.name,
          newAnnotationPositionX: response.data.x_coo,
          newAnnotationPositionY: response.data.y_coo,
          newAnnotationPositionZ: response.data.z_coo,
          newAnnotationNormalX: parseFloat(normalData[0]).toFixed(5),
          newAnnotationNormalY: parseFloat(normalData[1]).toFixed(5),
          newAnnotationNormalZ: parseFloat(normalData[2]).toFixed(5),
          annotationAddStart: true,
        });
      },
      (error) => {
        this.props.inputDataProp.setShowGeneralErrorMessage(true);
        this.props.inputDataProp.setGeneralErrorMessage(
          "while trying to retrieve the data for this annotation"
        );
        //console.log("Error", error.response.data);
      }
    );

    //console.log("Need to edit the annotation");
  };
  handleLanguagesEdit = (id) => {
    //console.log("Need to edit Languages");
    const GetURL = `${MainAdminURL}/modelannotations/${id}/`;
    const headersConfig = {
      headers: {
        Authorization: "Token " + this.context.userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    axios.get(GetURL, headersConfig).then(
      (response) => {
        //console.log(response.data.annotation_translations);
        this.setState({
          translationsToEdit: response.data.annotation_translations,
          previewTranslationWindow: true,
        });
      },
      (error) => {
        this.props.inputDataProp.setShowGeneralErrorMessage(true);
        this.props.inputDataProp.setGeneralErrorMessage(
          "while trying to retrieve the data for the translations"
        );
        //console.log("Error", error.response.data);
      }
    );
  };

  handleAnnotationTitleChange = (value, id) => {
    let tempState = [...this.state.translationsToEdit];
    let objectToChange = tempState.find((el) => el.id === id);
    let tempObject = { ...objectToChange };
    tempObject.title = value;
    let objectID = tempState.indexOf(objectToChange);
    tempState[objectID] = tempObject;
    this.setState({ translationsToEdit: tempState });

    // console.log(value);
    // console.log("The ID is: ", id);
    // console.log("The index of the objecy is: ", objectID);
    // console.log(objectToChange);
    // console.log("New Object: ", tempState);
  };

  handleAnnotationContentChange = (value, id) => {
    let tempState = [...this.state.translationsToEdit];
    let objectToChange = tempState.find((el) => el.id === id);
    let tempObject = { ...objectToChange };
    tempObject.content = value;
    let objectID = tempState.indexOf(objectToChange);
    tempState[objectID] = tempObject;
    this.setState({ translationsToEdit: tempState });
    //this.setState({ translationsToEdit: newState });
    // console.log(value);
    // console.log("The ID is: ", id);
    // console.log("The index of the objecy is: ", objectID);
    // console.log(objectToChange);
    // console.log("New Object: ", tempState);
  };

  getlanguageCode = (id) => {
    //console.log("Language Code is: ", id);
    const text = this.state.languages.find((x) => x.id === id);
    //console.log(text);
    return String(`${text.language}: ${text.language_code}`);
  };
  hideAddAlert = () => {
    this.setState({ previewTranslationWindow: false });
  };
  handleSaveTranslations = () => {
    this.props.nextButtonStatusSetInWizard(true);
    for (let i = 0; i < this.state.translationsToEdit.length; i++) {
      let translation = this.state.translationsToEdit[i];
      //console.log("Saving", translation);

      const translationsURL = `${MainAdminURL}/modelannotationtranslations/${translation.id}/`;
      //console.log(this.context.userToken);
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("title", translation.title);
      formData.append("content", translation.content);

      axios.patch(translationsURL, formData, headersConfig).then(
        (response) => {
          //(response.data);
          this.setState({ refresh: Math.random() });
          if (i === this.state.translationsToEdit.length - 1) {
            this.props.nextButtonStatusSetInWizard(false);
          }
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to update the annotation language translations for this model"
          );
          //console.log("Error", error.response.message);
        }
      );
      this.hideAddAlert();
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.previewTranslationWindow ? (
          <SweetAlert
            showConfirm={false}
            onConfirm={() => this.hideAddAlert()} //Just because is required
            style={{ display: "block", marginTop: "-100px", maxHeight: "80%", overflowY: "scroll" }}
            title="Translations"
          >
            {this.state.translationsToEdit && this.state.translationsToEdit.length > 0
              ? this.state.translationsToEdit.map((translation) => (
                  <GridContainer justify="center" key={translation.id}>
                    <GridItem xs={10} sm={10}>
                      <label
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginTop: "20px",
                          color: "black",
                        }}
                      >
                        Translation for{" "}
                        <strong>{this.getlanguageCode(translation.language_code)}</strong>
                      </label>
                    </GridItem>
                    <GridItem xs={10} sm={10}>
                      <CustomInput
                        labelText={<span>Title</span>}
                        id="languageCode"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: translation.title,
                          onChange: (event) => {
                            let value = event.target.value;
                            //console.log(value);
                            this.handleAnnotationTitleChange(value, translation.id);
                          },
                        }}
                      />
                      <label style={{ display: "block", textAlign: "left", marginTop: "5px" }}>
                        Annotation Content
                      </label>
                      <TextareaAutosize
                        id="arerrormassagetitle"
                        aria-label="maximum height"
                        value={translation.content}
                        onChange={(event) => {
                          let value = event.target.value;
                          //console.log(value);
                          this.handleAnnotationContentChange(value, translation.id);
                        }}
                        style={{
                          maxWidth: "100%",
                          minWidth: "100%",
                          minHeight: "60px",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "350",
                          fontSize: "14px",
                          lineHeight: "1.5em",
                          color: "#495057 !important",
                          borderColor: "#AAAAAA",
                          opacity: "1",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ))
              : ""}
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  color="success"
                  onClick={() => {
                    this.handleSaveTranslations();
                  }}
                >
                  Save
                </Button>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Button
                  color="rose"
                  onClick={(e) => {
                    e.preventDefault();
                    this.hideAddAlert();
                  }}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </SweetAlert>
        ) : (
          ""
        )}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} lg={6}>
            <huemen-ar-player
              style={{
                width: "500px",
                height: "500px",
                margin: "auto",
                backgroundColor: BackgroundColor,
                background: BackgroundColor,
              }}
              // Events
              // SKU Data
              src={this.state.initialData.glbPreviewUrl}
              //src={TestGlb}
              //ios-src={usdzLink}
              //alt={initialSKU.sku.name}
              poster={this.state.initialData.posterPreviewURL}
              // Model Data
              camera-orbit={this.state.initialData.cameraOrbitSettings}
              max-camera-orbit={this.state.initialData.maxCameraOrbitSettings}
              min-camera-orbit={this.state.initialData.minCameraOrbitSettings}
              exposure={this.state.initialData.exposureSettings}
              shadow-intensity={this.state.initialData.shadowIntensitySettings}
              shadow-softness={this.state.initialData.shadowSoftnessSettings}
              //General Options
              // auto-rotate
              // auto-rotate-delay="600"
              // rotation-per-second={"10deg"}
              id="webarplayerstep6"
              //reveal="interaction"
              //ar
              //ar-modes="webxr scene-viewer quick-look"
              //ar-scale="fixed"
              data-js-focus-visible
              camera-controls
              interaction-prompt="none"
            >
              {this.state.annotations && this.state.annotations.length > 0
                ? this.state.annotations.map((annotation) => (
                    <Annotation
                      key={annotation.id}
                      id={annotation.id}
                      annotationVisible={false}
                      normal={annotation.normal}
                      position={`${annotation.x_coo} ${annotation.y_coo} ${annotation.z_coo}`}
                      annotationTitle={
                        annotation.annotation_translations[0]
                          ? annotation.annotation_translations[0].title
                          : "TItle"
                      }
                      annotationContent={
                        annotation.annotation_translations[0]
                          ? annotation.annotation_translations[0].content
                          : "Content"
                      }
                    />
                  ))
                : ""}
              {this.state.annotationAddStart &&
              this.state.newAnnotationPositionX &&
              this.state.newAnnotationPositionY &&
              this.state.newAnnotationPositionZ &&
              this.state.newAnnotationNormalX &&
              this.state.newAnnotationNormalY &&
              this.state.newAnnotationNormalZ ? (
                <>
                  <button
                    id="newannotationbutton"
                    style={{
                      display: "block",
                      width: "15px",
                      height: "15px",
                      borderRadius: "8px",
                      border: "none",
                      backgroundColor: "#df2c01",
                      boxSizing: "border-box",
                    }}
                    slot={"hotspot-button-new"}
                    data-position={`${this.state.newAnnotationPositionX} ${this.state.newAnnotationPositionY} ${this.state.newAnnotationPositionZ}`}
                    data-normal={`${this.state.newAnnotationNormalX} ${this.state.newAnnotationNormalY} ${this.state.newAnnotationNormalZ}`}
                  />
                </>
              ) : (
                ""
              )}
            </huemen-ar-player>
          </GridItem>
          <GridItem xs={12} sm={12} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleToggle()}
                      checked={this.state.displayAnnotations ? true : false}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Do you want to preview the Annotations for this model?"
                />
              </GridItem>
            </GridContainer>
            {this.state.modelCenter && !this.state.annotationAddStart ? (
              <GridContainer style={{ marginTop: "30px" }}>
                <GridItem md={12}>
                  <Button
                    style={{
                      position: "relative",
                      float: "left",
                      margin: "10px",
                    }}
                    color="rose"
                    onClick={() => {
                      this.addAnnotation();
                    }}
                  >
                    + Add New Annotaion
                  </Button>
                </GridItem>
                <GridItem md={12}>
                  {this.state.annotations && this.state.annotations.length > 0 ? (
                    <Table
                      tableHead={["id", "Name", "Translations", "Actions"]}
                      tableData={
                        this.state.annotations && this.state.annotations.length > 0
                          ? this.state.annotations.map((annotation, key) => [
                              key + 1,
                              annotation.name,
                              <>
                                <Button
                                  color="success"
                                  className={classes.actionButton}
                                  onClick={() => this.handleLanguagesEdit(annotation.id)}
                                >
                                  <List className={classes.icon} />
                                </Button>
                              </>,
                              <>
                                <Button
                                  color="success"
                                  className={classes.actionButton}
                                  onClick={() => this.handleEditAnnotation(annotation.id)}
                                >
                                  <Edit className={classes.icon} />
                                </Button>
                                <Button
                                  color="rose"
                                  className={classes.actionButton}
                                  onClick={() => this.handleDeleteAnnotation(annotation.id)}
                                >
                                  <Close className={classes.icon} />
                                </Button>
                              </>,
                            ])
                          : []
                      }
                      customCellClasses={[classes.center, classes.center, classes.right]}
                      customClassesForCells={[0, 1, 2]}
                      customHeadCellClasses={[classes.center, classes.center, classes.right]}
                      customHeadClassesForCells={[0, 1, 2]}
                    />
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
            ) : (
              ""
            )}
            {this.state.modelCenter && this.state.annotationAddStart ? (
              <GridContainer style={{ marginTop: "30px" }}>
                {this.state.previewPleaseClick ? (
                  <GridItem md={12}>
                    Please click on top of the model to add a new annotation
                  </GridItem>
                ) : (
                  <>
                    <GridContainer style={{ marginTop: "30px" }}>
                      <GridContainer style={{ marginBottom: "30px", marginLeft: "0px" }}>
                        <GridItem xs={3} sm={3} lg={3}>
                          <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                            <strong>Annotation Name</strong>
                          </legend>
                          <CustomInput
                            success={true}
                            error={false}
                            labelText={<span>Name</span>}
                            id="annotationname"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.newAnnotationName, //
                              onChange: (event) => this.handleChangeAnnotationName(event),
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridItem xs={3} sm={3} lg={3}>
                        <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                          <strong>Position X</strong>
                        </legend>
                        <CustomInput
                          success={true}
                          error={false}
                          labelText={<span>X</span>}
                          id="apx"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.newAnnotationPositionX, //
                            onChange: (event) => this.handleChangePositionX(event),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} lg={3}>
                        <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                          <strong>Position Y</strong>
                        </legend>
                        <CustomInput
                          success={true}
                          error={false}
                          labelText={<span>Y</span>}
                          id="apy"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.newAnnotationPositionY, //
                            onChange: (event) => this.handleChangePositionY(event),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} lg={3}>
                        <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                          <strong>Position Z</strong>
                        </legend>
                        <CustomInput
                          success={true}
                          error={false}
                          labelText={<span>Z</span>}
                          id="apz"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.newAnnotationPositionZ, //
                            onChange: (event) => this.handleChangePositionZ(event),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginTop: "30px" }}>
                      <GridItem xs={3} sm={3} lg={3}>
                        <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                          <strong>Normal X</strong>
                        </legend>
                        <CustomInput
                          success={true}
                          error={false}
                          labelText={<span>X</span>}
                          id="nx"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.newAnnotationNormalX, //
                            onChange: (event) => this.handleChangeNormalX(event),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} lg={3}>
                        <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                          <strong>Normal Y</strong>
                        </legend>
                        <CustomInput
                          success={true}
                          error={false}
                          labelText={<span>Y</span>}
                          id="ny"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.newAnnotationNormalY, //
                            onChange: (event) => this.handleChangeNormalY(event),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} lg={3}>
                        <legend style={{ fontSize: "14px", marginBottom: "-10px" }}>
                          <strong>Normal Z</strong>
                        </legend>
                        <CustomInput
                          success={true}
                          error={false}
                          labelText={<span>Z</span>}
                          id="nz"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.newAnnotationNormalZ, //
                            onChange: (event) => this.handleChangeNormalZ(event),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem md={12}>
                      <Button
                        style={{
                          position: "relative",
                          float: "left",
                          margin: "10px",
                        }}
                        color="rose"
                        onClick={(e) => {
                          this.addAnnotation(e);
                        }}
                      >
                        Click Again
                      </Button>
                    </GridItem>
                  </>
                )}
                {!this.state.previewPleaseClick ? (
                  <GridItem md={12}>
                    <Button
                      style={{
                        position: "relative",
                        float: "left",
                        margin: "10px",
                      }}
                      color="success"
                      onClick={(e) => {
                        this.handleSaveNewAnnotation();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        position: "relative",
                        float: "left",
                        margin: "10px",
                      }}
                      color="rose"
                      onClick={(e) => this.handleCancelNewAnnotation()}
                    >
                      cancel
                    </Button>
                  </GridItem>
                ) : (
                  ""
                )}
              </GridContainer>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

Step6.propTypes = {
  inputDataProp: PropTypes.object,
  nextButtonStatusSetInWizard: PropTypes.func,
  classes: PropTypes.object,
};
Step6.contextType = UserTokenContext;
export default withStyles(style)(Step6);
