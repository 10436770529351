export const BackgroundColor = "#ffffff";
// export const MainUserURL = "http://172.25.224.1:8000/accounts/auth";
// export const MainAdminURL = "http://172.25.224.1:8000/api/dashboard";

// DEVELOPMENT ENVIRONMENT //

//export const MainUserURL = "https://api.huemenar.de/accounts/auth";
//export const MainAdminURL = "https://api.huemenar.de/api/dashboard";

// PRODUCTION ENVIRONMENT //

export const MainUserURL = "https://api.huemenar.com/accounts/auth";
export const MainAdminURL = "https://api.huemenar.com/api/dashboard";
