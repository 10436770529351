import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MainAdminURL } from "../../variables/GlobalVariables";

import { UserTokenContext } from "../../context/AuthContext";
import { UserDataContext } from "../../context/AuthContext";

// core components

import BrandWizard from "components/Wizard/BrandWizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import Step1 from "./EditBrandWizardSteps/Step1.js";
import Step2 from "./EditBrandWizardSteps/Step2.js";
import Step3 from "./EditBrandWizardSteps/Step3.js";

export default function EditBrand() {
  const { brandid } = useParams();
  const history = useHistory();
  const { userToken } = React.useContext(UserTokenContext);
  const { userData } = React.useContext(UserDataContext);

  const [initialBrandData, setInitialBrandData] = React.useState();
  const [brandName, setBrandName] = React.useState();
  const [brandColor, setBrandColor] = React.useState();
  const [brandlogo, setBrandLogo] = React.useState();
  React.useEffect(() => {
    if (brandid) {
      axios
        .get(`${MainAdminURL}/brands/${brandid}/`, {
          headers: {
            Authorization: "Token " + userToken,
          },
        })
        .then(
          (response) => {
            //console.log(response.data);
            setInitialBrandData(response.data);
            setBrandName(response.data.name);
            setBrandColor(response.data.primary_color);
            //setBrandLogo(response.data.logo);
          },
          (error) => {
            console.log("Error", error.message);
          }
        );
    }
  }, []);

  const handleEditBrand = (e) => {
    if (e.color) {
      setBrandColor(e.color.brandColor);
    } else {
      console.log(e);
    }
    const URL = `${MainAdminURL}/brands/${brandid}/`;
    const headersConfig = {
      headers: {
        Authorization: "Token " + userToken,
        "Content-Type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    if (initialBrandData) {
      if (brandName !== initialBrandData.name) {
        formData.append("name", brandName);
      }
      if (brandColor !== e.color.brandColor) {
        formData.append("primary_color", e.color.brandColor);
      }
      if (brandlogo) {
        formData.append("logo", brandlogo);
      }

      axios.patch(URL, formData, headersConfig).then(
        (response) => {
          //console.log(response.data);
          history.push("/admin/brands");
        },
        (error) => {
          console.log("Error", error.message);
        }
      );

      // Events URL
      const EventsURL = `${MainAdminURL}/events/`;
      // Events Form Data
      let EventFormData = new FormData();
      EventFormData.append("user", `${userData.first_name} ${userData.last_name}`);
      EventFormData.append("user_email", `${userData.email}`);
      EventFormData.append("event", "EDIT BRAND FINISH");
      EventFormData.append("target", `EDIT BRAND: ${brandName}`);
      // // Events Header
      // const headersConfig = {
      //   headers: {
      //     Authorization: "Token " + userToken,
      //   },
      // };
      // Constructing the data for the post request

      axios.post(EventsURL, EventFormData, headersConfig).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          console.log("Error", error.response.data);
        }
      );
    }
  };
  const handleNextButtonClick = (e) => {
    if (e.name) {
      setBrandName(e.name.brandName);
    }
    if (e.logo) {
      setBrandLogo(e.logo.brandLogo);
    }
    if (e.color) {
      setBrandColor(e.color.brandColor);
    } else {
      return;
    }
  };
  return (
    <div>
      {initialBrandData ? (
        <>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    style={{ position: "relative", float: "left", margin: "10px" }}
                    color="rose"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    Go Back
                  </Button>
                </GridItem>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <BrandWizard
                inputData={initialBrandData}
                validate
                steps={[
                  { stepName: "brand name", stepComponent: Step1, stepId: "name" },
                  { stepName: "brand logo", stepComponent: Step2, stepId: "logo" },
                  { stepName: "brand color", stepComponent: Step3, stepId: "color" },
                ]}
                title="Edit Brand"
                subtitle=""
                finishButtonClick={handleEditBrand}
                nextButtonClick={handleNextButtonClick}
                finishButtonText="Save"
              />
            </GridItem>
          </GridContainer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
