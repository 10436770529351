import React from "react";
import axios from "axios";

import { MainAdminURL } from "../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../context/AuthContext";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// Reference
import priceImage1 from "assets/img/card-2.jpeg";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Place from "@material-ui/icons/Place";

import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import SweetAlertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const useStyles = makeStyles(styles);
const useSweetAlertStyles = makeStyles(SweetAlertstyles);

export default function DraftModels() {
  const classes = useStyles();
  const sweetAlertClasses = useSweetAlertStyles();
  const { userToken } = React.useContext(UserTokenContext);
  const [brandsData, setBrandsData] = React.useState();
  const [activeModelsData, setActiveModelsData] = React.useState();
  const [branddeleted, setBrandDeleted] = React.useState();
  const [alert, setAlert] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    //console.log(userToken);
    axios
      .get(`${MainAdminURL}/brands/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setBrandsData(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
    //console.log(userToken);
    axios
      .get(`${MainAdminURL}/models/`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          //console.log(response.data);
          setActiveModelsData(response.data);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
  }, [branddeleted]);

  const warningWithConfirmMessage = (modelID, modelName) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Are you sure you want to delete ${modelName}?`}
        onConfirm={() => handleModelDelete(modelID)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.success}
        cancelBtnCssClass={sweetAlertClasses.button + " " + sweetAlertClasses.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        All of the skus conected with {modelName}, will be also deleted!
      </SweetAlert>
    );
  };
  const handleModelDelete = (modelID) => {
    console.log("Need to delete model" + modelID);
    axios
      .delete(`${MainAdminURL}/models/${modelID}`, {
        headers: {
          Authorization: "Token " + userToken,
        },
      })
      .then(
        (response) => {
          hideAlert();
          setBrandDeleted(modelID);
        },
        (error) => {
          console.log("Error", error.message);
        }
      );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]; //or in English

  const CreateDate = (date) => {
    var date = new Date(date);
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    var hours = addZero(date.getHours()); //addZero() function described below
    var minutes = addZero(date.getMinutes());

    return day + " " + monthNames[monthIndex] + " " + year; // + " at " + hours + ":" + minutes;
  };

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }

    return i;
  }

  return (
    <div>
      {alert}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                style={{ position: "relative", float: "right", margin: "10px" }}
                color="rose"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/admin/add-model/");
                }}
              >
                + Add Model
              </Button>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginTop: "-50px" }}>
        <GridItem xs={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12}>
              <h5>Placeholder for filters</h5>
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer>
      {brandsData && activeModelsData
        ? brandsData.map((brand, index) => {
            return (
              <React.Fragment key={index}>
                {brand.model_brand.length > 0 ? (
                  <>
                    <GridContainer style={{ marginTop: "-30px", marginBottom: "30px" }}>
                      <GridItem xs={12}>
                        <Card>
                          <GridContainer>
                            <GridItem xs={2} sm={2} md={2}>
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  margin: "7px",
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                src={brand.logo}
                                alt={brand.name}
                              ></img>
                            </GridItem>
                            <GridItem xs={10} sm={10} md={10}>
                              <h5>{brand.name}</h5>
                            </GridItem>
                          </GridContainer>
                        </Card>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <>
                        {brand.model_brand.map((activeModel, key) => {
                          return (
                            <React.Fragment key={String(key + index)}>
                              {!activeModel.is_active ? (
                                <GridItem xs={12} sm={6} md={3} key={1000 + key * index}>
                                  <Card product className={classes.cardHover}>
                                    <CardHeader image className={classes.cardHeaderHover}>
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        {activeModel.sku_models.length > 0 &&
                                        activeModel.sku_models[0].poster ? (
                                          <img
                                            style={{
                                              background: "white",
                                              height: "260px",
                                              objectFit: "scale-down",
                                            }}
                                            src={activeModel.sku_models[0].poster}
                                            alt="..."
                                          />
                                        ) : (
                                          <img src={priceImage1} alt="..." />
                                        )}
                                      </a>
                                    </CardHeader>
                                    <CardBody>
                                      <div className={classes.cardHoverUnder}>
                                        <Tooltip
                                          id="tooltip-top"
                                          title="Edit Model SKUs"
                                          placement="bottom"
                                          classes={{ tooltip: classes.tooltip }}
                                        >
                                          <Button
                                            color="transparent"
                                            simple
                                            justIcon
                                            onClick={(e) => {
                                              e.preventDefault();
                                              history.push(`/admin/skus/${activeModel.id}`);
                                            }}
                                          >
                                            <ArtTrack className={classes.underChartIcons} />
                                          </Button>
                                        </Tooltip>
                                        <Tooltip
                                          id="tooltip-top"
                                          title="Edit Model Settings"
                                          placement="bottom"
                                          classes={{ tooltip: classes.tooltip }}
                                        >
                                          <Button
                                            color="transparent"
                                            simple
                                            justIcon
                                            onClick={(e) => {
                                              e.preventDefault();
                                              history.push(`/admin/edit-model/${activeModel.id}`);
                                            }}
                                          >
                                            <Edit className={classes.underChartIcons} />
                                          </Button>
                                        </Tooltip>
                                        <Tooltip
                                          id="tooltip-top"
                                          title="Delete"
                                          placement="bottom"
                                          classes={{ tooltip: classes.tooltip }}
                                        >
                                          <Button
                                            color="danger"
                                            simple
                                            justIcon
                                            onClick={(e) => {
                                              e.preventDefault();
                                              warningWithConfirmMessage(
                                                activeModel.id,
                                                activeModel.name
                                              );
                                            }}
                                          >
                                            <Delete className={classes.underChartIcons} />
                                          </Button>
                                        </Tooltip>
                                      </div>
                                      <h4 className={classes.cardProductTitle}>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                          {activeModel.name}
                                        </a>
                                      </h4>
                                    </CardBody>
                                    <CardFooter product>
                                      <GridContainer>
                                        <GridItem xs={2} sm={2} md={2}>
                                          <div className={classes.price}>
                                            <img
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                margin: "7px",
                                                display: "block",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                              }}
                                              src={brand.logo}
                                              alt={brand.name}
                                            ></img>
                                          </div>
                                        </GridItem>
                                        <GridItem xs={10} sm={10} md={10}>
                                          <span
                                            style={{
                                              marginTop: "12px",
                                              marginLeft: "5px",
                                              float: "right",
                                            }}
                                          >
                                            Last Modified: {CreateDate(activeModel.modified_at)}
                                          </span>
                                        </GridItem>
                                      </GridContainer>
                                    </CardFooter>
                                  </Card>
                                </GridItem>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    </GridContainer>
                  </>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })
        : ""}
    </div>
  );
}
