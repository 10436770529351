import React from "react";
import axios from "axios";
import PropTypes from "prop-types";

import { MainAdminURL, BackgroundColor } from "../../../../variables/GlobalVariables";

import { UserTokenContext } from "../../../../context/AuthContext"; //"../../../context/AuthContext";

import HuemenARPlayer from "../../../../components/HuemenArPlayer/HuemenARPlayer.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FileUpload from "components/CustomUpload/FileUpload.js";
import ImageUploadWizard from "components/CustomUpload/ImageUploadWizard.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel

      initialData: this.props.inputDataProp, //isMaterialChangeable: "",
      // Handle GLB
      glbFile: null,
      glbFileState: "",
      previewURL: "",
      // Handle USDZ
      usdzFile: null,
      usdzFileState: "",
      // Handle on GLB file Load
      //skuMaterials: [],
      skuMaterialsWithoutTexture: [],
      skuMaterialsWithTexture: [],
      skuMaterialsWithTextureState: [],
      // handle error message
      error: false,
      buttonDisabled: false,
    };
  }
  static contextType = UserTokenContext;
  sendState = () => {
    return this.state;
  };

  componentDidMount() {
    const modelViewer = document.getElementById("webarplayer");
    if (modelViewer) {
      modelViewer.addEventListener("load", this.fileLoaded);
      modelViewer.addEventListener("mouseenter", this.handleMouseOver);
      modelViewer.addEventListener("mouseleave", this.handleMouseLeave);
      //modelViewer.onmouseover
      //modelViewer.onmouseout
    }
    //console.log(this.context.userToken);
    //console.log(this.props.inputDataProp);
  }

  componentWillUnmount() {
    const modelViewer = document.getElementById("webarplayer");
    modelViewer.removeEventListener("load", this.fileLoaded);
    modelViewer.removeEventListener("mouseenter", this.handleMouseOver);
    modelViewer.removeEventListener("mouseleave", this.handleMouseLeave);
  }
  handleMouseOver = (e) => {
    //console.log("Mouse Over");
  };
  handleMouseLeave = () => {
    //console.log("Mouse Leave");
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    //console.log("texuresState");
    //console.log(this.state.skuMaterialsWithTextureState);
    //console.log(this.state.glbFileState);
    //console.log(this.state.usdzFileState);
    if (this.state.skuMaterialsWithTexture !== prevState.skuMaterialsWithTexture) {
      //console.log("Updated with texture");
      // Typical usage (don't forget to compare props):
      //console.log(this.state.skuMaterialsWithTexture);
      //console.log(this.state.skuMaterialsWithTexture.length);
    }
    if (this.state.skuMaterialsWithoutTexture !== prevState.skuMaterialsWithoutTexture) {
      //console.log("Updated without texture");
      // Typical usage (don't forget to compare props):
      //console.log(this.state.skuMaterialsWithoutTexture);
      //console.log(this.state.skuMaterialsWithoutTexture.length);
    }
  }

  handleButtonDisable = (value) => {
    this.setState({ buttonDisabled: value });
    this.props.nextButtonStatusSetInWizard(value);
  };

  fileLoaded = (e) => {
    const modelViewer = document.getElementById("webarplayer");
    const materials = modelViewer.model.materials;
    //console.log(materials);
    //Tova tuk Posle triabva da se preraboti
    if (this.state.skuMaterialsWithTexture.length > 0) {
      //console.log("I need to delete the materials first");
      var m = 0;
      var len = this.state.skuMaterialsWithTexture.length;
      for (; m < len; m++) {
        //console.log(this.state.skuMaterials[m].id);
        const DelURL = `${MainAdminURL}/skumaterials/${this.state.skuMaterialsWithTexture[m].id}/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + this.context.userToken,
            "Content-Type": "multipart/form-data",
          },
        };
        axios.delete(DelURL, headersConfig).then(
          (response) => {
            //console.log(response.data);
          },
          (error) => {
            this.props.inputDataProp.setShowGeneralErrorMessage(true);
            this.props.inputDataProp.setGeneralErrorMessage(
              "while trying to delete the materials with texture for this SKU"
            );
            //console.log("Error", error.response.data);
          }
        );
      }

      this.setState({ skuMaterialsWithTexture: [] });
    }
    if (this.state.skuMaterialsWithoutTexture.length > 0) {
      //console.log("I need to delete the materials first");
      var n = 0;
      var len = this.state.skuMaterialsWithoutTexture.length;
      for (; n < len; n++) {
        //console.log(this.state.skuMaterials[m].id);
        const DelURL = `${MainAdminURL}/skumaterials/${this.state.skuMaterialsWithoutTexture[n].id}/`;
        const headersConfig = {
          headers: {
            Authorization: "Token " + this.context.userToken,
            "Content-Type": "multipart/form-data",
          },
        };
        axios.delete(DelURL, headersConfig).then(
          (response) => {
            //console.log(response.data);
          },
          (error) => {
            this.props.inputDataProp.setShowGeneralErrorMessage(true);
            this.props.inputDataProp.setGeneralErrorMessage(
              "while trying to delete the materials without texture for this SKU"
            );
            //console.log("Error", error.response.data);
          }
        );
      }
      this.setState({
        skuMaterialsWithoutTexture: [],
        skuMaterialsWithTextureState: [],
      });
    }

    //console.log("the materials are not set up");
    var i = 0;
    var materialLen = materials.length;
    for (; i < materialLen; i++) {
      const currentMaterial = materials[i];
      const GetColor = materials[i].pbrMetallicRoughness.baseColorFactor;
      const headersConfig = {
        headers: {
          Authorization: "Token " + this.context.userToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", materials[i].name);
      formData.append("sku", parseInt(this.props.inputDataProp.newSKUID));
      formData.append("color", `${GetColor[0]},${GetColor[1]},${GetColor[2]},${GetColor[3]}`);

      const URL = `${MainAdminURL}/skumaterials/`;
      axios.post(URL, formData, headersConfig, currentMaterial).then(
        (response) => {
          if (currentMaterial.pbrMetallicRoughness["baseColorTexture"]) {
            //console.log("Need to add to with texture");

            response.data.textureRequired = true;
            //console.log(response.data);
            this.setState((previousState) => ({
              skuMaterialsWithTexture: [...previousState.skuMaterialsWithTexture, response.data],
              skuMaterialsWithTextureState: [...previousState.skuMaterialsWithTextureState, false],
            }));
          } else {
            response.data.textureRequired = false;
            this.setState((previousState) => ({
              skuMaterialsWithoutTexture: [
                ...previousState.skuMaterialsWithoutTexture,
                response.data,
              ],
            }));
          }
          //console.log(response.data);
          //createdMaterials.push(response.data);
        },
        (error) => {
          this.props.inputDataProp.setShowGeneralErrorMessage(true);
          this.props.inputDataProp.setGeneralErrorMessage(
            "while trying to create the materials for this SKU"
          );
          //console.log("Error", error.response.data);
        }
      );
    }

    //console.log(createdMaterials);
    //this.setState({ skuMaterials: createdMaterials });
  };

  setGlbFileState = (file) => {
    if (file) {
      this.setState({
        error: false,
        glbFile: file,
        glbFileState: "success",
      });
    } else {
      this.setState({
        error: true,
        glbFile: null,
        glbFileState: "error",
      });
    }
  };

  setglbFileData = (fileData) => {
    if (fileData) {
      this.setState({ glbFileData: fileData });
    }
  };
  setGLBPreviewURL = (url) => {
    this.setState({ previewURL: url });
  };
  setUsdzFileState = (file) => {
    if (file) {
      this.setState({
        error: false,
        usdzFile: file,
        usdzFileState: "success",
      });
    } else {
      this.setState({
        error: true,
        usdzFile: null,
        usdzFileState: "error",
      });
    }
  };

  setMaterialTextureState = (file, index) => {
    if (file) {
      let currentTextureState = this.state.skuMaterialsWithTextureState;
      currentTextureState[index] = true;
      this.setState({
        error: false,
        skuMaterialsWithTextureState: currentTextureState,
      });
    } else {
      let currentTextureState = this.state.skuMaterialsWithTextureState;
      currentTextureState[index] = false;
      this.setState({
        error: true,
        skuMaterialsWithTextureState: currentTextureState,
      });
    }
  };

  isValidated() {
    let currentTextureState = this.state.skuMaterialsWithTextureState;
    if (
      this.state.glbFileState === "success" &&
      this.state.usdzFileState === "success" &&
      currentTextureState.every((value) => !!value)
    ) {
      //console.log("Now it should work");
      return true;
    } else if (
      this.state.glbFileState === "success" &&
      this.state.usdzFileState === "success" &&
      !this.props.inputDataProp.isMaterialChangeable
    ) {
      //console.log("Now it should work");
      return true;
    } else {
      if (this.state.glbFileState !== "error") {
        this.setState({ error: true });
      }
      if (this.state.usdzFileState !== "error") {
        this.setState({ error: true });
      }
      if (currentTextureState.some((value) => !value)) {
        this.setState({ error: true });
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridContainer justify="center">
          <GridItem xs={12} sm={4}>
            <GridContainer justify="center" alignItems="center" style={{ textAlign: "center" }}>
              <GridContainer justify="center">
                <h3>3D Assets</h3>
              </GridContainer>
              <GridItem xs={6}>
                <FileUpload
                  setShowGeneralErrorMessage={this.props.inputDataProp.setShowGeneralErrorMessage}
                  setGeneralErrorMessage={this.props.inputDataProp.setGeneralErrorMessage}
                  checkExtension="glb"
                  ButtonStatusSet={this.handleButtonDisable}
                  buttonDisabledState={this.state.buttonDisabled}
                  skuSlug={this.props.inputDataProp.newSKUSlug}
                  setFileProps={this.setGlbFileState}
                  setFileDataProps={this.setglbFileData}
                  setPreviewURL={this.setGLBPreviewURL}
                  addButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: false,
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <FileUpload
                  setShowGeneralErrorMessage={this.props.inputDataProp.setShowGeneralErrorMessage}
                  setGeneralErrorMessage={this.props.inputDataProp.setGeneralErrorMessage}
                  checkExtension="usdz"
                  ButtonStatusSet={this.handleButtonDisable}
                  buttonDisabledState={this.state.buttonDisabled}
                  skuSlug={this.props.inputDataProp.newSKUSlug}
                  setFileProps={this.setUsdzFileState}
                  setPreviewURL={this.previewURL ? "" : this.setGLBPreviewURL}
                  addButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: false,
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: false,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" alignItems="center" style={{ textAlign: "center" }}>
              {this.state.skuMaterialsWithTexture.length > 0 &&
              this.props.inputDataProp.isMaterialChangeable ? (
                <React.Fragment>
                  <GridContainer justify="center">
                    <h3>Required Textures</h3>
                  </GridContainer>

                  {this.state.skuMaterialsWithTexture.map((texture, index) => {
                    return (
                      <GridItem xs={6} style={{ maxWidth: "230px" }} key={index}>
                        <span>
                          Upload texture for <strong>{texture.name}</strong> material
                        </span>
                        <ImageUploadWizard
                          setShowGeneralErrorMessage={
                            this.props.inputDataProp.setShowGeneralErrorMessage
                          }
                          setGeneralErrorMessage={this.props.inputDataProp.setGeneralErrorMessage}
                          skuSlug={this.props.inputDataProp.newSKUSlug}
                          currentIndex={index}
                          setFileProps={this.setMaterialTextureState}
                          ButtonStatusSet={this.handleButtonDisable}
                          buttonDisabledState={this.state.buttonDisabled}
                          materialID={texture.id}
                          addButtonProps={{
                            color: "rose",
                            round: false,
                          }}
                          changeButtonProps={{
                            color: "rose",
                            round: false,
                          }}
                          removeButtonProps={{
                            color: "danger",
                            round: false,
                          }}
                        />
                      </GridItem>
                    );
                  })}
                </React.Fragment>
              ) : (
                ""
              )}
            </GridContainer>
          </GridItem>
          <GridItem id="fixed" xs={12} sm={8} style={{ textAlign: "center" }}>
            <huemen-ar-player
              style={{
                width: "500px",
                height: "500px",
                backgroundColor: BackgroundColor,
                background: BackgroundColor,
              }}
              // Events
              // SKU Data
              src={this.state.previewURL}
              //ios-src={usdzLink}
              //alt={initialSKU.sku.name}
              //poster={initialSKU.sku.poster}
              // Model Data
              camera-orbit="45deg 65deg auto"
              //max-camera-orbit="auto auto 200%"
              //min-camera-orbit="auto auto 100%"
              //exposure="0.1"
              //shadow-intensity="2"
              //shadow-softness="1"
              //General Options

              id="webarplayer"
              //reveal="eager"
              //ar
              //ar-modes="webxr scene-viewer quick-look"
              //ar-scale="fixed"
              data-js-focus-visible
              camera-controls
              interaction-prompt="none"
            ></huemen-ar-player>
          </GridItem>
        </GridContainer>

        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={10}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>
                  Both .glb and .usdz files are required! Make sure you upload the right file
                  format!
                </Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  inputDataProp: PropTypes.object,
  nextButtonStatusSetInWizard: PropTypes.func,
  classes: PropTypes.object,
};
Step2.contextType = UserTokenContext;
export default withStyles(style)(Step2);
