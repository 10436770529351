import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: this.props.inputDataProp,
      error: false,
      brandLogo: null,
      brandLogoState: "",
    };
  }
  componentDidMount() {
    if (this.state.initialData) {
      this.setState({ brandLogoState: "success", brandLogo: null, error: false });
    }
  }
  sendState = (file) => {
    return this.state;
  };

  setBrandLogoState = (file) => {
    if (file) {
      this.setState({
        error: false,
        brandLogo: file,
        brandLogoState: "success",
      });
    } else {
      this.setState({
        error: true,
        brandLogo: null,
        brandLogoState: "error",
      });
    }
  };

  isValidated() {
    if (this.state.brandLogoState === "success") {
      return true;
    } else {
      if (this.state.brandLogoState !== "success") {
        this.setState({ brandLogoState: "error", error: true });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Upload a new photo that will be used in the QR Code. The file size must be 64px by 64px.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <ImageUpload
            setFileProps={this.setBrandLogoState}
            initialDataProps={this.state.initialData}
            addButtonProps={{
              color: "rose",
              round: false,
            }}
            changeButtonProps={{
              color: "rose",
              round: false,
            }}
            removeButtonProps={{
              color: "danger",
              round: false,
            }}
          />
        </GridItem>
        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>
                  Brand Logo field is required! Make sure you upload the right file size!
                </Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
