import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...customSelectStyle,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initial Data coming from AddModel
      initialData: this.props.inputDataProp,
      nameChanged: false,
      // Changing the color to the Dropdown Menu on Success
      selectisMaterialChangeableColor: "",
      // Handle SKU Name
      skuName: this.props.inputDataProp.skuData ? this.props.inputDataProp.skuData.name : "",
      skuNameState: this.props.inputDataProp.skuData ? "success" : "",
      // Handle SKU Type
      isMaterialChangeable: this.props.inputDataProp.skuData
        ? this.props.inputDataProp.skuData.is_material_changable
        : "",
      isMateralChangableState: "success",
      isMaterialChanged: false,
      // Handle Error Message
      error: false,
    };
  }
  sendState() {
    return this.state;
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    //console.log(this.state.isMateralChangableState);
    //console.log(this.state.skuNameState);
    //this.props.inputDataProp.setNextButtonStatusProps(false);
    if (this.props.inputDataProp !== prevProps.inputDataProp) {
      // Typical usage (don't forget to compare props):
      this.setState({
        initialData: this.props.inputDataProp,
      });
    }
    //console.log(this.props.inputDataProp);
    //console.log(this.state.posterURLData);
    //console.log(this.state.newSkuData);
    //console.log(this.state.newModelData);
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success", error: false });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }

  handleIsMaterialChangeabledSimple = (event) => {
    this.setState({
      isMaterialChangeable: event.target.value,
      isMateralChangableState: "success",
      selectisMaterialChangeableColor: "#4caf50",
    });
  };

  isValidated() {
    if (this.state.skuNameState === "success" && this.state.isMateralChangableState === "success") {
      this.setState({ error: false });
      return true;
    } else {
      if (this.state.skuNameState !== "success") {
        this.setState({ skuNameState: "error", error: true });
      }
      if (this.state.isMateralChangableState !== "success") {
        this.setState({ isMateralChangableState: "error", error: true });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridContainer justify="center">
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.skuNameState === "success"}
              error={this.state.skuNameState === "error"}
              labelText={<span>MAIN SKU NAME</span>}
              id="skuName"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.skuName,
                onChange: (event) => this.change(event, "skuName", "length", 1),
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                <span
                  style={{ color: this.state.selectisMaterialChangeableColor, fontSize: "14px" }}
                >
                  SKU Type
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.isMaterialChangeable}
                onChange={this.handleIsMaterialChangeabledSimple}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Category
                </MenuItem>

                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={true}
                >
                  Code Based Model
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={false}
                >
                  Texture Embedded Model
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>
                  The Name of the Main SKU and the Type of the Main SKU for this model are required!
                </Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  inputDataProp: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
