import React from "react";
import PropTypes from "prop-types";

import { SketchPicker } from "react-color";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Danger from "components/Typography/Danger.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      brandColor: "",
      brandColorState: "",
    };
  }
  sendState() {
    return this.state;
  }

  handleChangeComplete = (color, event) => {
    this.setState({
      error: false,
      brandColor: color.hex,
      brandColorState: "success",
    });
    //console.log(color.hex);
  };

  isValidated() {
    if (this.state.brandColorState === "success") {
      return true;
    } else {
      if (this.state.brandColorState !== "success") {
        this.setState({ brandColorState: "error", error: true });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Upload a photo that will be used in the QR Code. The file size must be 64px by 64px.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={5}>
          <SketchPicker
            disableAlpha={true}
            onChange={this.handleChangeComplete}
            color={this.state.brandColor}
            width="100%"
            presetColors={[
              "#FF5901",
              "#4D4D4D",
              "#999999",
              "#FFFFFF",
              "#F44E3B",
              "#FE9200",
              "#FCDC00",
              "#DBDF00",
              "#A4DD00",
              "#68CCCA",
              "#73D8FF",
              "#AEA1FF",
              "#FDA1FF",
              "#333333",
              "#808080",
              "#cccccc",
              "#D33115",
              "#E27300",
              "#FCC400",
              "#B0BC00",
              "#68BC00",
              "#16A5A5",
              "#009CE0",
              "#7B64FF",
              "#FA28FF",
              "#000000",
              "#666666",
              "#B3B3B3",
              "#9F0500",
              "#C45100",
              "#FB9E00",
              "#808900",
              "#194D33",
              "#0C797D",
              "#0062B1",
              "#653294",
              "#AB149E",
            ]}
          />
        </GridItem>
        {this.state.error ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={8}>
              <br />
              <div style={{ textAlign: "center" }} className={classes.typo}>
                <Danger style={{ textAlign: "center" }}>
                  Brand Logo field is required! Make sure you upload the right file size!
                </Danger>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step3);
