import React from "react";

function Annotation(props) {
  const [annotation, setAnnotation] = React.useState();
  const [showAnnotation, setShowAnnotation] = React.useState(false);

  React.useEffect(() => {
    const modelViewer = document.getElementById("webarplayerstep6");
    const dataPosition = props.position;
    const dataNormal = props.normal;
    modelViewer.updateHotspot({
      name: `hotspot-button-${props.id}`,
      position: dataPosition,
      normal: dataNormal,
    });
  }, [props]);

  //Anotation Related Functions
  const DisplayMessage = (cameraPosition, fieldOfView, name, title, message) => {
    if (showAnnotation === false) {
      setShowAnnotation(true);
    } else {
      setShowAnnotation(false);
    }
    // const modelViewer = document.getElementById("webarplayer");
    // modelViewer.removeAttribute("auto-rotate");
    // modelViewer.cameraOrbit = cameraPosition;
    // modelViewer.fieldOfView = fieldOfView;
    // setAnnotation({ name: name, title: title, message: message });
  };

  return (
    <button
      style={{
        display: "block",
        width: "15px",
        height: "15px",
        borderRadius: "8px",
        border: "none",
        backgroundColor: props.color ? `${props.color}` : "#df2c01",
        boxSizing: "border-box",
      }}
      slot={`hotspot-button-${props.id}`}
      data-position={props.position}
      data-normal={props.normal}
      onClick={() =>
        DisplayMessage(
          "0deg 0deg 0.6m",
          "25deg",
          "buttons",
          "Instant control panel",
          "Manage key product and playback settings on a fly"
        )
      }
    >
      {showAnnotation ? (
        <div
          style={{
            backgroundColor: "#000",
            minWidth: "200px",
            position: "absolute",
            transform: "translate(20px, -40%)",
            borderRadius: "6px",
            padding: "10px",
            opacity: "0.8",
            color: "white",
            fontSize: "12px",
          }}
          id="buttons"
        >
          <div id="tittle">
            <strong>{props.annotationTitle}</strong>
          </div>
          <hr style={{ margin: "5px" }}></hr>
          <div id="info">{props.annotationContent}</div>
        </div>
      ) : (
        ""
      )}
    </button>
  );
}

export default Annotation;
